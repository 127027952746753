import React from "react";
import { IncidentByHour } from "../../models/IncidentByHour";
import {
  Chart,
  ChartTitle,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartTooltip,
  ChartArea,
  ChartSeriesItemTooltip,
  ChartLegendInactiveItems,
  ChartValueAxis,
  ChartValueAxisItem,
} from "@progress/kendo-react-charts";
import {
  CHART_COLOR,
  FALL_COLOR,
  HAZARD_COLOR,
  NEAR_MISS_COLOR,
  TEXT_COLOR,
  HR_ALERT_COLOR,
} from "../../constants/constants";

interface Props {
  data: IncidentByHour[];
}

const IncidentByHourChart: React.FC<Props> = ({ data }) => {
  return (
    <Chart>
      <ChartArea margin={30} background={CHART_COLOR} />
      <ChartTitle text="STF By Time of Day" color={TEXT_COLOR} />
      <ChartLegend
        position="bottom"
        orientation="horizontal"
        labels={{ color: TEXT_COLOR }}
      >
        <ChartLegendInactiveItems labels={{ color: TEXT_COLOR }} />
      </ChartLegend>
      <ChartTooltip />
      <ChartCategoryAxis>
        <ChartCategoryAxisItem
          categories={data.map((x) => x.hour)}
          color={TEXT_COLOR}
        ></ChartCategoryAxisItem>
      </ChartCategoryAxis>
      <ChartValueAxis>
        <ChartValueAxisItem color={TEXT_COLOR} />
      </ChartValueAxis>
      <ChartSeries>
        <ChartSeriesItem
          name={"Near Miss"}
          type="column"
          stack={true}
          color={NEAR_MISS_COLOR}
          data={data.map((x) => x.nearmiss_count)}
        >
          <ChartSeriesItemTooltip format="Near Misses: {0}" />
        </ChartSeriesItem>
        <ChartSeriesItem
          name={"Fall"}
          type="column"
          stack={true}
          color={FALL_COLOR}
          data={data.map((x) => x.fall_count)}
        >
          <ChartSeriesItemTooltip format="Fall Warnings: {0}" />
        </ChartSeriesItem>
        <ChartSeriesItem
          name={"Hazard"}
          type="column"
          stack={true}
          color={HAZARD_COLOR}
          data={data.map((x) => x.hazard_count)}
        >
          <ChartSeriesItemTooltip format="Hazards: {0}" />
        </ChartSeriesItem>
        <ChartSeriesItem
          name={"HR Alerts"}
          type="column"
          stack={true}
          color={HR_ALERT_COLOR}
          data={data.map((x) => x.hr_alert_count)}
        >
          <ChartSeriesItemTooltip format="HR Alerts: {0}" />
        </ChartSeriesItem>
      </ChartSeries>
    </Chart>
  );
};
export default IncidentByHourChart;
