import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./TimePeriods.scss";

const TimePeriods = ({ buildingId , date}: { buildingId: number, date: number }) => {
  const [selectedPeriod, setSelectedPeriod] = useState<string>();

  useEffect(() => {
    if (window.location.pathname.toString().includes("monthly")) {
      setSelectedPeriod("monthly");
    } else if (window.location.pathname.toString().includes("yearly")) {
      setSelectedPeriod("yearly");
    } else if (window.location.pathname.toString() === "/daily-dashboard") {
      setSelectedPeriod("daily");
    } else {
      setSelectedPeriod("none");
    }
    //eslint-disable-next-line
  }, [window.location.pathname]);

  return (
    <div className="TimePeriods">
      <Link
        to={`/daily-dashboard?location=`+buildingId + `&date=` + date}
        className={`period ${selectedPeriod === "daily" ? "active" : ""}`}
        onClick={() => {
          setSelectedPeriod("daily");
        }}
      >
        Daily
      </Link>
      <Link
        to={`/monthly-dashboard?location=`+buildingId+ `&date=` + date}
        className={`period ${selectedPeriod === "monthly" ? "active" : ""}`}
        onClick={() => {
          setSelectedPeriod("monthly");
        }}
      >
        Monthly
      </Link>
      <Link
        to={`/yearly-dashboard?location=`+buildingId+ `&date=` + date}
        className={`period ${selectedPeriod === "yearly" ? "active" : ""}`}
        onClick={() => {
          setSelectedPeriod("yearly");
        }}
      >
        Yearly
      </Link>
    </div>
  );
};

export default TimePeriods;
