import React, { useState, useEffect } from "react";
import "./Camera.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Grid,
  GridColumn as Column,
  GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import { Button, DialogContentText } from "@material-ui/core";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
// import { Form, FormElement } from "@progress/kendo-react-form";
import { toast } from "react-toastify";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import axios from "axios";
// import LevelForm from "../../components/Onboarding/LevelOnboarding/LevelForm";
// import FormPageTwo from "../../components/Onboarding/FormPageTwo";
// import { BuildingModel } from "../../models/BuildingModel";
import { LevelModel } from "../../models/LevelModel";
import { BOX_COLOR, TEXT_COLOR } from "../../constants/constants";
import { BuildingModel } from "../../models/BuildingModel";
import LocationForm from "../../components/Forms/LocationForm/LocationForm";
import LocationSecondForm from "../../components/Forms/LocationSecondForm/LocationSecondForm";
import { TeamModel } from "../../models/TeamModel";
import { UnitModel } from "../../models/UnitModel";
import { RoomModel } from "../../models/RoomModel";
import { CameraModel } from "../../models/CameraModel";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";

const initialSort: Array<SortDescriptor> = [];

export default function Camera() {
  const [open, setOpen] = useState<boolean>(false);

  const [cameras, setCameras] = useState<CameraModel[]>([]);
  const [units, setUnits] = useState<UnitModel[]>([]);
  const [levels, setLevels] = useState<LevelModel[]>([]);
  const [rooms, setRooms] = useState<RoomModel[]>([]);
  const [buildings, setBuildings] = useState<BuildingModel[]>([]);
  const [teams, setTeams] = useState<TeamModel[]>([]);

  const [step, setStep] = useState(1);

  const [buildingId, setBuildingId] = useState<number>(0);
  const [levelId, setLevelId] = useState<number>(0);
  const [unitId, setUnitId] = useState<number>(0);
  const [roomId, setRoomId] = useState<number>(0);
  const [rtspURL, setRtspURL] = useState<string[]>([]);
  const [cameraCount, setCameraCount] = useState<number>(0);

  const [cameraName, setCameraName] = useState<string[]>([]);
  const [cameraDescription, setCameraDescription] = useState<string[]>([]);
  const [teamId, setTeamId] = useState<number[]>([]);

  const [sort, setSort] = useState(initialSort);

  const countArr: number[] = [];
  for (var i: number = 0; i < cameraCount; i++) {
    countArr.push(i);
  }

  useEffect(() => {
    axios
      .get("/api/v2.0/camera")
      .then((res) => {
        setCameras(res && res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [open]);

  useEffect(() => {
    (async () => {
      let results = await Promise.all([
        axios.get("/api/v2.0/building"),
        axios.get("/api/v2.0/data/team"),
        axios.get("api/v2.0/level"),
        axios.get("api/v2.0/unit"),
        axios.get("api/v2.0/room"),
      ]);
      setBuildings(results && results[0] && results[0].data);
      setTeams(results && results[1] && results[1].data);
      setLevels(results && results[2] && results[2].data);
      setUnits(results && results[3] && results[3].data);
      setRooms(results && results[4] && results[4].data);
    })();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const CommandCell = (props: any) => (
    <td className="k-command-cell">
      <button
        className="k-button k-grid-remove-command"
        onClick={() =>
          window.confirm("Confirm deleting: " + props.dataItem.camera_name) &&
          remove(props.dataItem)
        }
      >
        <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
      </button>
    </td>
  );

  const remove = (dataItem: CameraModel) => {
    axios
      .delete("/api/v2.0/camera", {
        data: {
          camera_id: dataItem.camera_id,
        },
      })
      .then((res) => {
        console.log(res.data);
        toast["success"]("Camera Deleted");
        const filtered = cameras.filter(
          (record) => record.camera_id !== dataItem.camera_id
        );
        setCameras(filtered);
      })
      .catch((err) => {
        console.error(err);
        toast["error"]("Error deleting Camera:" + err?.response?.data);
      });
  };

  const handleClose = () => {
    setBuildingId(0);
    setLevelId(0);
    setUnitId(0);
    setRoomId(0);
    setCameraCount(0);
    setCameraName([]);
    setRtspURL([]);
    setCameraDescription([]);
    setTeamId([]);
    setStep(1);
    setOpen(false);
  };

  const handleNext = () => {
    if (buildingId === 0) {
      toast["warning"]("Select Building");
      return;
    } else if (levelId === 0) {
      toast["warning"]("Select Level");
      return;
    } else if (unitId === 0) {
      toast["warning"]("Select Unit");
      return;
    } else if (roomId === 0) {
      toast["warning"]("Select Room");
      return;
    } else if (cameraCount < 1) {
      toast["warning"]("Select no. of cameras");
      return;
    }
    setStep(2);
  };

  const handleRtspURLs = (url: string, key: number) => {
    var rtspURLs = [...rtspURL];
    rtspURLs[key] = url;
    setRtspURL(rtspURLs);
  };

  const handleCameraName = (name: string, key: number) => {
    var cameraNames = [...cameraName];
    cameraNames[key] = name;
    setCameraName(cameraNames);
  };

  const handleCameraDesc = (desc: string, key: number) => {
    var cameraDesc = [...cameraDescription];
    cameraDesc[key] = desc;
    setCameraDescription(cameraDesc);
  };

  const handleTeamId = (team: number, key: number) => {
    var teams = [...teamId];
    teams[key] = team;
    setTeamId(teams);
  };

  const handleSubmit = () => {
    if (cameraName.filter((name) => !!name).length < cameraCount) {
      toast["warning"]("Enter Camera Name");
      return;
    } else if (rtspURL.filter((url) => !!url).length < cameraCount) {
      toast["warning"]("Enter RTSP URL");
      return;
    }
    var camerasData: any = [];

    for (var i = 0; i < cameraCount; i++) {
      const newCamera = {
        camera_name: cameraName[i],
        description: cameraDescription[i],
        url: rtspURL[i],
        team_id: teamId[i],
      };
      camerasData.push(newCamera);
    }

    // console.log(camerasData);
    axios
      .post("/api/v2.0/camera", {
        building_id: buildingId,
        level_id: levelId,
        unit_id: unitId,
        room_id: roomId,
        cameras: camerasData,
      })
      .then((_res) => {
        handleClose();
        toast["success"]("Cameras Added!");
      })
      .catch((err) => {
        console.error(err);
        toast["error"]("Error adding new Camera(s): " + err?.response?.data);
      });
  };

  return (
    <div className="Camera">
      <div className="firstrow">
        <h3>Add Cameras</h3>
        <FontAwesomeIcon
          title="Add Camera"
          icon={faPlus}
          size={"lg"}
          cursor="pointer"
          onClick={handleOpen}
        ></FontAwesomeIcon>
      </div>
      <div className="cameras">
        <Grid
          data={orderBy(cameras, sort)}
          sort={sort}
          sortable={true}
          onSortChange={(e: GridSortChangeEvent) => {
            setSort(e.sort);
          }}
        >
          <Column className="fixed" field="camera_id" title="ID" width="75px" />
          <Column className="fixed" field="building_name" title="Location" />
          <Column className="fixed" field="level_name" title="Level" />
          <Column className="fixed" field="unit_name" title="Unit" />
          <Column className="fixed" field="room_name" title="Room" />
          <Column className="fixed" field="camera_name" title="Camera name" />
          <Column className="fixed" field="description" title="Description" />
          <Column className="fixed" field="team_name" title="Team" />
          <Column cell={CommandCell} />
        </Grid>
      </div>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogTitle style={{ color: TEXT_COLOR, backgroundColor: BOX_COLOR }}>
          Add New Cameras
        </DialogTitle>
        <DialogContent
          style={{ color: TEXT_COLOR, backgroundColor: BOX_COLOR }}
          dividers
        >
          <DialogContentText style={{ color: TEXT_COLOR }}>
            {step === 1 ? (
              <h4>Step {step}: Select Location</h4>
            ) : (
              <h4>Step {step}: Enter Camera Details </h4>
            )}
          </DialogContentText>

          {step === 1 ? (
            <LocationForm
              origin={"Cameras"}
              buildings={buildings}
              buildingId={buildingId}
              setBuildingId={setBuildingId}
              levels={levels}
              levelId={levelId}
              setLevelId={setLevelId}
              units={units}
              unitId={unitId}
              setUnitId={setUnitId}
              rooms={rooms}
              roomId={roomId}
              setRoomId={setRoomId}
              count={cameraCount}
              setCount={setCameraCount}
            ></LocationForm>
          ) : (
            <ul>
              {countArr.map((i) => (
                <LocationSecondForm
                  origin={"Camera"}
                  key={i.toString()}
                  teams={teams}
                  index={i}
                  handleRtspURLs={handleRtspURLs}
                  handleName={handleCameraName}
                  handleDescription={handleCameraDesc}
                  handleTeamId={handleTeamId}
                />
              ))}
            </ul>
          )}
        </DialogContent>
        <DialogActions
          style={{ color: TEXT_COLOR, backgroundColor: BOX_COLOR }}
        >
          <Button style={{ color: TEXT_COLOR }} onClick={handleClose}>
            Cancel
          </Button>
          {step === 1 ? (
            <Button style={{ color: TEXT_COLOR }} onClick={handleNext}>
              Next
            </Button>
          ) : (
            <Button style={{ color: TEXT_COLOR }} onClick={handleSubmit}>
              Submit
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
