import React, { useEffect, useState } from "react";
import axios from "axios";
import socketIOClient from "socket.io-client";
import {
  Grid,
  GridColumn as Column,
  GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { dateToString } from "../../services/commonServices";
import { DateTimeCell } from "../../components/DateTimeCell/DateTimeCell";
import { LiveTeamModel } from "../../models/LiveTeamModel";
//import { UserActivitySocketMessageModel } from "../../models/UserActivitySocketMessageModel";
import { Incident } from "../../models/Incident";
//import { OutOfAreaAlertModel } from "../../models/OutOfAreaAlertModel";
import { REACT_TOKEN_AUTH } from "../../constants/constants";
import { UserHeartrateModel } from "../../models/UserHeartrateModel";
import { PercentageCell } from "../../components/PercentageCell/PercentageCell";
import { UserStepsModel } from "../../models/UserStepsModel";
import { DurationCell } from "../../components/DurationCell/DurationCell";
import { UserIntensityModel } from "../../models/UserIntensityModel";
import { AvgIntensityCell } from "../../components/AvgIntensityCell/AvgIntensityCell";
import MessageIcon from "../../resources/icons/dailyOps/message.svg";
import SendUserMessagePopUp from "../../components/SendUserMessagePopUp/SendUserMessagePopUp";
import "./TeamView.scss";
import DailyOpsSiteSelection from "../../components/DailyOpsSiteSelection/DailyOpsSiteSelection";
import { UserActivityModel } from "../../models/UserActivityModel";
import { ActivityCell } from "../../components/ActivityCell/ActivityCell";
import { AlertCell } from "../../components/Cells/AlertCell/AlertCell";
import { PositiveValueCell } from "../../components/PositiveValueCell/PositiveValueCell";
import { APP_SETTINGS } from "../../helpers/Utils";
import ProfileIcon from "../../resources/icons/dailyOps/profile.svg";
const initialSort: Array<SortDescriptor> = [];

const timeDivider = APP_SETTINGS.displayHourly ? 3600 : 60;

export default function TeamView() {
  const [sort, setSort] = useState(initialSort);
  const [teamData, setTeamData] = useState<Map<String, any>>(new Map());
  const [userIds, setUserIds] = useState<string[]>([]);
  const [showUserMessage, setShowUserMessage] = useState<boolean>(false);
  const [selectedUserId, setSelectedUser] = useState<string>("");
  const [activityTypes, setActivityTypes] = useState<any[]>([]);
  const [distinctActivityTypes, setDistinctActivityTypes] = useState<any[]>([]);
  const [selectedSiteId, setSelectedSiteId] = useState<number>(-1);
  const [selectedTeamId, setSelectedTeamId] = useState<number>(-1);
  const [employeeSearchText, setEmployeeSearchText] = useState<string>("");

  let date = dateToString(new Date());

  useEffect(() => {
    const search = window.location.search,
      params = new URLSearchParams(search),
      siteIdFromUrl = params.get("location"),
      teamIdFromUrl = params.get("team");

    if (siteIdFromUrl && siteIdFromUrl.length > 0)
      setSelectedSiteId(parseInt(siteIdFromUrl?.toString() ?? "-1"));
    if (teamIdFromUrl && teamIdFromUrl.length > 0)
      setSelectedTeamId(parseInt(teamIdFromUrl.toString() ?? "-1"));
  }, []);
  useEffect(() => {
    axios
      .get("/api/v2.0/user/user-ids")
      .then((res) => {
        let user_ids: string[] = [];
        let users = res.data;
        users.forEach((element: any) => {
          user_ids.push(element.user_id);
        });
        setUserIds(user_ids);
      })
      .catch();
  }, []);

  useEffect(() => {
    if (selectedTeamId > 0 && selectedTeamId > 0) {
      axios
        .get(
          `/api/v2.0/user/user-ids?` +
            `buildingId=${selectedSiteId}&employeeName=${employeeSearchText}&teamId=${selectedTeamId}`
        )
        .then((res) => {
          let user_ids: string[] = [];
          let users = res.data;
          users.forEach((element: any) => {
            user_ids.push(element.user_id);
          });
          setUserIds(user_ids);
        })
        .catch();
    } else {
      setTeamData(new Map<String, Object>());
    }
  }, [employeeSearchText, selectedTeamId]);

  useEffect(() => {
    let dataMap = new Map<String, Object>();
    if (userIds.length === 0 || selectedTeamId <= 0 || selectedTeamId <= 0) {
      setTeamData(dataMap);
    } else {
      (async () => {
        const startTime = "00:00";
        const endTime = "23:59:59";
        let results = await Promise.all([
          axios.get(
            `/api/v2.0/ws/user_team_view?date=${date}&userIds=${userIds}&startTime=${startTime}&endTime=${endTime}`
          ),
          axios.get("/api/v2.0/data/activity-types"),
          axios.get(
            `/api/v2.0/ws/user_activity_cumulative?date=${date}&userIds=${userIds}&startTime=${startTime}&endTime=${endTime}`
          ),
          axios.get(
            `/api/v2.0/ws/user_heartrate_cumulative?date=${date}&userIds=${userIds}&startTime=${startTime}&endTime=${endTime}`
          ),
          axios.get(
            `/api/v2.0/ws/user_info_latest?date=${date}&userIds=${userIds}&startTime=${startTime}&endTime=${endTime}`
          ),
          axios.get(
            `/api/v2.0/ws/users_steps?date=${date}&userIds=${userIds}&startTime=${startTime}&endTime=${endTime}`
          ),
          axios.get(
            `/api/v2.0/ws/user_intensity_cumulative?date=${date}&userIds=${userIds}&startTime=${startTime}&endTime=${endTime}`
          ),
        ]);
        const userTeamResults = results && results[0] && results[0].data;
        const allActivityTypes = results && results[1] && results[1].data;
        setActivityTypes(allActivityTypes);

        const userCumulativeData = results && results[2] && results[2].data;
        const userCumulativeHeartRateData =
          results && results[3] && results[3].data;
        const userInfoLatest = results && results[4] && results[4].data;
        const userSteps = results && results[5] && results[5].data;
        const userCumulativeIntensityData =
          results && results[6] && results[6].data;
        userTeamResults &&
          userTeamResults.forEach((element: LiveTeamModel) => {
            element.activities = {};
            element.total_activity_duration = 0;
            element.cumulative_intensities = {
              count_intensity_type_id_1: 0,
              count_intensity_type_id_2: 0,
              count_intensity_type_id_3: 0,
              count_intensity_type_id_4: 0,
              user_id: "",
              count: 0,
            };
            dataMap.set(element.user_id, element);
          });

        // userWorkGoals &&
        //   userWorkGoals.forEach((element: WorkGoalModel) => {
        //     if (dataMap.get(element.user_id)) {
        //       let userTeam: any = dataMap.get(element.user_id);
        //       userTeam.activities[element.activity_type_id] = {
        //         hours_per_day: element.hours_per_day,
        //         activity_duration: 0,
        //       };
        //       dataMap.set(userTeam.user_id, userTeam);
        //     }
        //   });
        const distinctActivity = [
          ...new Set(
            Object.values(userCumulativeData).map(
              (x: any) => x.activity_type_id
            )
          ),
        ];
        setDistinctActivityTypes(distinctActivity);

        dataMap.forEach((x: any) => {
          if (dataMap.get(x.user_id)) {
            let userRow: any = dataMap.get(x.user_id);
            distinctActivity.forEach((y: any) => {
              const userActivity = Object.values<UserActivityModel>(
                userCumulativeData
              ).filter(
                (z: any) => z.user_id == x.user_id && z.activity_type_id == y
              )[0];
              // console.log(userActivity);
              if (userActivity) {
                userRow.activities[y] = {
                  activity_duration:
                    userActivity.activity_duration / timeDivider ?? 0,
                };
                userRow.total_activity_duration +=
                  userActivity.activity_duration / timeDivider;
              } else {
                userRow.activities[y] = {
                  activity_duration: 0,
                };
              }
            });
            dataMap.set(userRow.user_id, userRow);
          }
        });
        userCumulativeHeartRateData &&
          userCumulativeHeartRateData.forEach((element: UserHeartrateModel) => {
            if (dataMap.get(element.user_id)) {
              let userTeam: any = dataMap.get(element.user_id);
              userTeam.max_heartrate = element.max_heartrate;
              userTeam.min_heartrate = element.min_heartrate;
              dataMap.set(userTeam.user_id, userTeam);
            }
          });
        userInfoLatest &&
          userInfoLatest.forEach((element: UserHeartrateModel) => {
            if (dataMap.get(element.user_id)) {
              let userTeam: any = dataMap.get(element.user_id);
              userTeam.heartrate = element.heartrate;
              userTeam.battery_level = element.battery_level;
              dataMap.set(userTeam.user_id, userTeam);
            }
          });
        userSteps &&
          userSteps.forEach((element: UserStepsModel) => {
            if (dataMap.get(element.user_id)) {
              let userTeam: any = dataMap.get(element.user_id);
              userTeam.steps = element.steps;
              dataMap.set(userTeam.user_id, userTeam);
            }
          });

        userCumulativeIntensityData &&
          userCumulativeIntensityData.forEach((element: UserIntensityModel) => {
            if (dataMap.get(element.user_id)) {
              let userTeam: any = dataMap.get(element.user_id);
              userTeam.cumulative_intensities = element;
              dataMap.set(userTeam.user_id, userTeam);
            }
          });
      })().then(() => {
        if (userIds.length === 0) {
          setTeamData(new Map<String, Object>());
        } else {
          setTeamData(dataMap);
        }
      });
    }
  }, [userIds]);

  // Start listening to socket
  useEffect(() => {
    const socket = socketIOClient("/", {
      query: {
        token: JSON.parse(localStorage.getItem(REACT_TOKEN_AUTH) ?? "")
          .accessToken,
      },
    });
    socket.on("user_activity", (userActivity: any) => {
      console.log(userActivity);
      console.log(teamData);
      if (
        userActivity &&
        userActivity.user_id &&
        teamData.get(userActivity.user_id.toUpperCase()) &&
        new Date(userActivity.last_activity.timestamp).toDateString() ===
          new Date().toDateString()
      ) {
        console.log(
          new Date(userActivity.last_activity.timestamp).toDateString()
        );
        console.log(new Date().toDateString());
        console.log(userActivity);
        setTeamData((prevState: Map<String, Object>) => {
          let teamDataCopy = new Map(prevState);
          let userDataCopy: any = teamDataCopy.get(
            userActivity.user_id.toUpperCase()
          );
          Object.keys(userActivity.cumulative_activity).forEach(
            (key: string) => {
              const activity = userActivity.cumulative_activity[key];
              if (userDataCopy.activities[activity.activity_type_id]) {
                userDataCopy.activities[
                  activity.activity_type_id
                ].activity_duration +=
                  Number(activity.activity_duration) / timeDivider ?? 0;
              } else {
                userDataCopy.activities[activity.activity_type_id] = {
                  activity_duration:
                    Number(activity.activity_duration) / timeDivider ?? 0,
                };
              }
              userDataCopy.total_activity_duration +=
                activity.activity_duration / timeDivider ?? 0;
              // userDataCopy.last_seen = activity?.last_activity?.timestamp;

              // if (!userDataCopy.first_seen) {
              //   userDataCopy.first_seen = activity?.last_activity?.timestamp;
              // }
            }
          );
          userDataCopy.heartrate = userActivity?.last_activity?.heartrate;
          userDataCopy.battery_level =
            userActivity?.last_activity?.battery_level;
          userDataCopy.last_seen = userActivity?.last_activity?.timestamp;

          if (!userDataCopy.first_seen) {
            userDataCopy.first_seen = userActivity?.last_activity?.timestamp;
          }
          userDataCopy.steps = userActivity?.last_activity?.steps;
          if (userDataCopy.heartrate) {
            if (userDataCopy.heartrate > userDataCopy.max_heartrate)
              userDataCopy.max_heartrate = userDataCopy.heartrate;
            if (userDataCopy.heartrate < userDataCopy.min_heartrate)
              userDataCopy.min_heartrate = userDataCopy.heartrate;
          }
          Object.keys(userActivity.cumulative_intensity).forEach(
            (intensity_type: string) => {
              switch (intensity_type) {
                case "1":
                  userDataCopy.cumulative_intensities.count_intensity_type_id_1 +=
                    userActivity.cumulative_intensity[intensity_type];
                  break;

                case "2":
                  userDataCopy.cumulative_intensities.count_intensity_type_id_2 +=
                    userActivity.cumulative_intensity[intensity_type];
                  break;

                case "3":
                  userDataCopy.cumulative_intensities.count_intensity_type_id_3 +=
                    userActivity.cumulative_intensity[intensity_type];
                  break;

                default:
                  break;
              }
            }
          );
          teamDataCopy.set(userDataCopy.user_id, userDataCopy);
          return teamDataCopy;
        });
      }
    });
    socket.on("user_safety_incidents", (incident: Incident) => {
      console.log(incident);
      if (
        incident &&
        incident.user_id &&
        incident.incident_dt &&
        teamData.get(incident.user_id.toUpperCase()) &&
        new Date().toDateString() ===
          new Date(incident.incident_dt).toDateString()
      ) {
        setTeamData((prevState: Map<String, Object>) => {
          let teamDataCopy = new Map(prevState);
          let userDataCopy: any = teamDataCopy.get(
            incident.user_id.toUpperCase()
          );
          if (userDataCopy.incidents) {
            userDataCopy.incidents += 1;
          } else {
            userDataCopy.incidents = 1;
          }
          teamDataCopy.set(userDataCopy.user_id, userDataCopy);
          return teamDataCopy;
        });
      }
    });
    // socket.on("user_out_of_area", (outOfAreaAlert: OutOfAreaAlertModel) => {
    //   if (
    //     outOfAreaAlert &&
    //     teamData.get(outOfAreaAlert.user_id.toUpperCase()) &&
    //     teamData.get(outOfAreaAlert.user_id.toUpperCase())
    //       .out_of_area_alert_id !== outOfAreaAlert.out_of_area_alert_id
    //   ) {
    //     setTeamData((prevState: Map<String, Object>) => {
    //       let teamDataCopy = new Map(prevState);
    //       let userDataCopy: any = teamDataCopy.get(
    //         outOfAreaAlert.user_id.toUpperCase()
    //       );

    //       if (userDataCopy.out_of_area_alerts) {
    //         userDataCopy.out_of_area_alerts += 1;
    //       } else {
    //         userDataCopy.out_of_area_alerts = 1;
    //       }
    //       teamDataCopy.set(userDataCopy.user_id, userDataCopy);
    //       return teamDataCopy;
    //     });
    //   }
    // });
    return () => {
      socket.disconnect();
    };
  }, [teamData]);

  return (
    <div className="LiveTeamView">
      <DailyOpsSiteSelection
        selectedSiteId={selectedSiteId}
        setSelectedSiteId={setSelectedSiteId}
        selectedTeamId={selectedTeamId}
        setSelectedTeamId={setSelectedTeamId}
        employeeSearchText={employeeSearchText}
        setEmployeeSearchText={setEmployeeSearchText}
      ></DailyOpsSiteSelection>
      <div className="grid">
        <Grid
          data={
            userIds.length > 0
              ? orderBy(Array.from(teamData.values()), sort)
              : []
          }
          sort={sort}
          sortable={true}
          onSortChange={(e: GridSortChangeEvent) => {
            setSort(e.sort);
          }}
        >
          <Column className="fixed" field="first_name" title="First Name" />
          <Column className="fixed" field="last_name" title="Last Name" />
          <Column className="fixed" field="team_name" title="Team" />
          <Column
            className="fixed"
            field="incidents"
            title="Safety Alerts"
            cell={(props: any) => (
              <AlertCell alerts={props.dataItem.incidents}></AlertCell>
            )}
          />
          <Column
            className="fixed"
            field="out_of_area_alerts"
            title="Out of Area"
            cell={(props: any) => (
              <AlertCell alerts={props.dataItem.out_of_area_alerts}></AlertCell>
            )}
          />
          <Column
            className="fixed"
            field="first_seen"
            title="First Seen"
            cell={(props: any) => <DateTimeCell {...props}></DateTimeCell>}
          />
          <Column
            className="fixed"
            field="last_seen"
            title="Last Seen"
            cell={(props: any) => <DateTimeCell {...props}></DateTimeCell>}
          />

          <Column
            className="fixed"
            field="battery_level"
            title="Battery Level"
            cell={(props: any) => <PercentageCell {...props}></PercentageCell>}
          />
          <Column
            className="fixed"
            field="heartrate"
            title="Heart Rate"
            cell={(props: any) => (
              <PositiveValueCell {...props}></PositiveValueCell>
            )}
          />
          {/* <Column className="fixed" field="max_heartrate" title="Max HR" />
          <Column className="fixed" field="min_heartrate" title="Min HR" /> */}
          <Column
            className="fixed"
            field="steps"
            title="Steps"
            cell={(props: any) => (
              <PositiveValueCell {...props}></PositiveValueCell>
            )}
          />
          <Column
            className="fixed"
            field="total_activity_duration"
            title="Total Activity Hours"
            cell={(props: any) => <DurationCell {...props}> </DurationCell>}
          />
          {distinctActivityTypes &&
            distinctActivityTypes.map((x, i) => {
              return (
                <Column
                  key={i}
                  className="fixed"
                  field={x.toString()}
                  title={
                    Object.values(activityTypes).filter(
                      (y) => y.activity_type_id === x
                    )[0]?.activity_type ?? "Other"
                  }
                  sortable={false}
                  cell={(props: any) => (
                    <ActivityCell {...props}></ActivityCell>
                  )}
                />
              );
            })}
          <Column
            className="fixed"
            field="cumulative_intensities"
            title="Avg Intensity"
            cell={(props: any) => (
              <AvgIntensityCell {...props}> </AvgIntensityCell>
            )}
          />
          <Column
            className="fixed"
            field="user_id"
            title="Message"
            cell={(props: any) => (
              <div>
                <img
                  className="table-icon"
                  onClick={() => {
                    setShowUserMessage(true);
                    setSelectedUser(props.dataItem["user_id"]);
                  }}
                  src={MessageIcon}
                ></img>
              </div>
            )}
          />
          <Column
            className="fixed"
            field="user_id"
            title="Profile"
            cell={(props: any) => (
              <a
                style={{ display: "table-cell", textDecoration: "none" }}
                href={`/user/${props.dataItem["first_name"]} ${props.dataItem["last_name"]}?userid=${props.dataItem["user_id"]}`}
              >
                <img className="table-icon" src={ProfileIcon}></img>
              </a>
            )}
          />
        </Grid>
      </div>
      {showUserMessage && (
        <SendUserMessagePopUp
          isOpen={showUserMessage}
          handleVisibility={setShowUserMessage}
          selectedUserId={selectedUserId}
          selectedUser={teamData.get(selectedUserId)}
          teamUserIds={userIds.join(",") ?? ""}
        ></SendUserMessagePopUp>
      )}
    </div>
  );
}
