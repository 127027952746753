import React from "react";
import "./BatteryIndicator.scss";
interface Props {
  percentage: number;
}

const BatteryIndicator: React.FC<Props> = ({ percentage = 0 }) => {
  return (
    <div className="BatteryContainer">
      <div className="batteryOuter">
        <div id="batteryLevel" style={{ width: (percentage / 100) * 23 }}></div>
      </div>
      <div className="batteryBump"></div>
    </div>
  );
};
export default BatteryIndicator;
