import React, { useState } from 'react';
import { Incident } from '../../models/Incident';
import IncidentItem from '../../components/IncidentItem/IncidentItem';
import {
  FILTER_AWAIT_RESPONSE,
  FILTER_RESOLVED,
  FILTER_UNRESOLVED,
  SORT_LATEST_TO_OLDEST,
  SORT_OLDEST_TO_LATEST,
  STATUS_AWAIT_RESPONSE,
  STATUS_RESOLVED_FALSE_ALARM,
  STATUS_RESOLVED_REPORTED,
  STATUS_UNRESOLVED_NEED_TIME,
  STATUS_UNRESOLVED_WILL_ACT,
} from '../../constants/constants';
import './IncidentListMobile.scss';
interface Props {
  incidents: Incident[];
  onSelectInident: (incident_id: number) => void;
}

const IncidentListMobile: React.FC<Props> = ({ incidents, onSelectInident }) => {
  const [selectedSortOption, setselectedSortOption] = useState(1);

  const sortFilterOptionsMobile = [
    { value: SORT_LATEST_TO_OLDEST, text: 'Sort: Latest to Oldest' },
    { value: SORT_OLDEST_TO_LATEST, text: 'Sort: Oldest to Latest' },
    { value: FILTER_AWAIT_RESPONSE, text: 'View: All Await Response' },
    { value: FILTER_UNRESOLVED, text: 'View: All Unresolved' },
    { value: FILTER_RESOLVED, text: 'View: All Resolved' },
  ];
  return (
    <div className='IncidentListMobile'>
      <select
        value={selectedSortOption}
        onChange={(e) => setselectedSortOption(Number(e.target.value))}
      >
        {sortFilterOptionsMobile.map((x) => (
          <option key={x.value} value={x.value}>
            {x.text}
          </option>
        ))}
      </select>
      {incidents
        .filter((x) => {
          if (selectedSortOption === FILTER_AWAIT_RESPONSE) {
            return x.status_id === STATUS_AWAIT_RESPONSE;
          } else if (selectedSortOption === FILTER_RESOLVED) {
            return (
              x.status_id === STATUS_RESOLVED_FALSE_ALARM ||
              x.status_id === STATUS_RESOLVED_REPORTED
            );
          } else if (selectedSortOption === FILTER_UNRESOLVED) {
            return (
              x.status_id === STATUS_UNRESOLVED_NEED_TIME ||
              x.status_id === STATUS_UNRESOLVED_WILL_ACT
            );
          } else return true;
        })
        .sort((a, b) => {
          if (selectedSortOption === SORT_OLDEST_TO_LATEST) {
            return (
              Number(new Date(a.incident_dt)) - Number(new Date(b.incident_dt))
            );
          } else {
            return (
              Number(new Date(b.incident_dt)) - Number(new Date(a.incident_dt))
            );
          }
        })
        .map((x, i) => (
          <IncidentItem
            incident={x}
            key={i}
            onSelectIndcident={onSelectInident}
          ></IncidentItem>
        ))}
    </div>
  );
};
export default IncidentListMobile;
