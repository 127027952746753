import React from "react";
import "./IncidentCell.scss";
interface IncidentCellProps {
  dataItem: any;
  field: string;
}

export class IncidentCell extends React.Component<IncidentCellProps> {
  render() {
    let value: string = this.props.dataItem[this.props.field];
    if (!value) value = "";
    let className = "";
    switch (value.toLowerCase()) {
      case "near miss slip":
        className = "near-miss";
        break;
      case "near miss trip":
        className = "near-miss";
        break;
      case "hazard":
        className = "hazard";
        break;
      case "fall":
        className = "fall";
        break;
      case "low hr alert":
        className = "hr-alert";
        break;
      case "high hr alert":
        className = "hr-alert";
        break;
      case "false alarm":
        className = "false-alarm";
        break;
      case "unverified":
      default:
        className = "unverified";
        break;
    }
    return (
      <td className={`IncidentCell ${className}`}>
        {value.split("-").join(" ").toLocaleString()}
      </td>
    );
  }
}
