import React from "react";
import "./DateCell.scss";
interface DateCellProps {
  dataItem: any;
  field: string;
}
function formattedDate(d = new Date) {
  let month = String(d.getMonth() + 1);
  let day = String(d.getDate());
  const year = String(d.getFullYear());

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return `${day}-${month}-${year}`;
}

export class DateCell extends React.Component<DateCellProps> {
  render() {
    const value = this.props.dataItem[this.props.field];
    return (
      <td className={`DateCell`}>
        {value && formattedDate(new Date(value.replace("Z", "")))}
      </td>
    );
  }
}
