import React from "react";
import {
  faMapMarker,
  faUser,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Incident } from "../../models/Incident";
import { dateTimeToStringddMMMyyhhmm12 } from "../../services/commonServices";
import "./IncidentItem.scss";
interface Props {
  incident: Incident;
  onSelectIndcident: (incident_id: number) => void;
}

const IncidentItem: React.FC<Props> = ({ incident, onSelectIndcident }) => {
  return (
    <div
      className="IncidentItem"
      onClick={() => onSelectIndcident(incident.incident_id)}
    >
      <div className="title_row">
        <div className="title">{incident.incident_type}</div>
        <div className="id">ID: {incident.incident_id}</div>
      </div>
      <div className="status">{incident.status}</div>
      {incident.victim_name ? (
        <div className="incident_row">
          <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>{" "}
          {incident.victim_name}
        </div>
      ) : null}
      {incident.location ? (
        <div className="incident_row">
          <FontAwesomeIcon icon={faMapMarker}></FontAwesomeIcon>{" "}
          {incident.location}
        </div>
      ) : null}

      <div className="incident_row">
        <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>{" "}
        {dateTimeToStringddMMMyyhhmm12(
          new Date(incident.incident_dt.replace("z", ""))
        )}
      </div>

      {incident.updated_by ? (
        <div className="incident_row">
          Last updated by: {incident.updated_by}
        </div>
      ) : null}
      <div
        className={`top_border ${
          incident.status_id === 2 || incident.status_id === 3
            ? "pending"
            : incident.status_id === 4 || incident.status_id === 5
            ? "resolved"
            : ""
        }`}
      ></div>
    </div>
  );
};

export default IncidentItem;
