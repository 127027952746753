import React from "react";
import TextField from "@material-ui/core/TextField";

import "./SupervisingTeamItem.scss";

import { TeamModel } from "../../models/TeamModel";
import { UserModel } from "../../models/UserModel";
import { Chip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

interface Props {
  users: UserModel[];
  team: TeamModel;
  handleEdit: (team: TeamModel) => void;
}

export default function SupervisingTeamItem(props: Props) {
  return (
    <div className="SupervisingTeamItem">
      <div className="first_row">
        <h4>Team Name:</h4>
        <FontAwesomeIcon
          title="Edit"
          icon={faEdit}
          size={"lg"}
          cursor="pointer"
          onClick={() => {
            props.handleEdit(props.team);
          }}
        ></FontAwesomeIcon>
      </div>

      <div className="teamName">
        <TextField
          size="medium"
          placeholder="Team Name"
          value={props.team.team_name}
          InputProps={{
            readOnly: true,
          }}
        />
      </div>
      <div className="firstResponders">
        <h5>First Responders</h5>
        <div className="users">
          {props.team.first_responders.map((user) => (
            <div key={user.user_id} className="user">
              <Chip label={user.email}></Chip>
            </div>
          ))}
        </div>
      </div>
      <div className="escalationPoints">
        <h5>Escalation Points</h5>
        <div className="users">
          {props.team.escalation_points.map((user) => (
            <div key={user.user_id} className="user">
              <Chip label={user.email}></Chip>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
