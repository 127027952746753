import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import IncidentNumberSummary from "../../components/IncidentNumberSummary/IncidentNumberSummary";
import SaftyIncedentsIcon from "./../../resources/icons/dailyOps/safty-incedents.svg";
import OutOfZoneAlertsIcon from "./../../resources/icons/dailyOps/out-of-zone-alerts.svg";
import WorkersOfflineIcon from "./../../resources/icons/dailyOps/workers-offline.svg";
import HeartRateAlertsIcon from "./../../resources/icons/dailyOps/high-heart-rate-alerts.svg";
//import PhysicalExcertionAlertsIcon from "./../../resources/icons/dailyOps/high-physical-exertion-alerts.svg";
import InformationIcon from "./../../resources/icons/dailyOps/information.svg";
import DailyOpsHoursChart from "../../components/DailyOpsHoursChart/DailyOpsHoursChart";
import { DailyOpsActivitySummary } from "../../models/DailyOpsActivitySummary";
import { DailyOpsActivityChart } from "../../models/DailyOpsActivityChart";
import "./DailyOpsOverview.scss";
import DailyOpsSiteSelection from "../../components/DailyOpsSiteSelection/DailyOpsSiteSelection";
import { formatDate, getUnixTimestamp } from "../../helpers/DateHelper";
import Loading from "../../components/Loading/Loading";
import { Button } from "@material-ui/core";
import { APP_SETTINGS, roundUp } from "../../helpers/Utils";

export default function DailyOpsOverview() {
  const [selectedSiteId, setSelectedSiteId] = useState<number>(-1);
  const [activitySummaryData, setActivitySummaryData] =
    useState<DailyOpsActivitySummary>();
  const [activityChartData, setActivirtChartData] =
    useState<DailyOpsActivityChart>();
  const [date, setDate] = useState<number>(getUnixTimestamp());
  const [selectedTeamId, setSelectedTeamId] = useState<number>(-1);
  const [loading, setChartLoading] = useState(false);
  const [selectedSiteName, setSelectedSiteName] = useState<string>("");

  useEffect(() => {
    const search = window.location.search,
      params = new URLSearchParams(search),
      siteIdFromUrl = params.get("location"),
      dateFromUrl = params.get("date");
    if (siteIdFromUrl && siteIdFromUrl.length > 0)
      setSelectedSiteId(parseInt(siteIdFromUrl?.toString() ?? "-1"));
      if (dateFromUrl) setDate(parseInt(dateFromUrl));
  }, []);

  useEffect(() => {
    const formattedDate = formatDate(date);
    setChartLoading(true);
    Promise.all([
      axios.get(
        `/api/v2.0/daily-ops/summary-breakdown?startDate=${formattedDate}&endDate=${formattedDate}&buildingId=${selectedSiteId}&teamId=${selectedTeamId}`
      ),
      axios.get(
        `/api/v2.0/daily-ops/summary?startDate=${formattedDate}&endDate=${formattedDate}&buildingId=${selectedSiteId}&teamId=${selectedTeamId}`
      ),
    ])
      .then((results) => {
        setActivirtChartData(results[0].data);
        setActivitySummaryData(results[1].data);
      })
      .finally(() => setChartLoading(false));
  }, [date, selectedSiteId, selectedTeamId]);

  return (
    <div className="DilyOpsOverview">
      <div>
        <DailyOpsSiteSelection
          selectedSiteId={selectedSiteId}
          date={date}
          setDate={setDate}
          setSelectedSiteId={setSelectedSiteId}
          selectedTeamId={selectedTeamId}
          setSelectedTeamId={setSelectedTeamId}
          setSelectedSiteName={setSelectedSiteName}
        ></DailyOpsSiteSelection>
      </div>
      <div className="card summary-row">
        <IncidentNumberSummary
          icon={SaftyIncedentsIcon}
          incedentType="Safety Incidents"
          numberOfIncedents={activitySummaryData?.numberOfSaftyIncedents ?? 0}
          loading={loading}
          url={`/daily-dashboard?date=${date}&location=${selectedSiteId}&team=${selectedTeamId}`}
        ></IncidentNumberSummary>
        <IncidentNumberSummary
          icon={OutOfZoneAlertsIcon}
          incedentType="Out of Zone Alerts"
          numberOfIncedents={activitySummaryData?.outOfAreaAlertsCount ?? 0}
          loading={loading}
          url={`/live/${selectedSiteName}/workshift?date=${date}&location=${selectedSiteId}&team=${selectedTeamId}`}
        ></IncidentNumberSummary>
        <IncidentNumberSummary
          icon={WorkersOfflineIcon}
          incedentType="Workers Offline"
          numberOfIncedents={activitySummaryData?.offlineWorkersCount ?? 0}
          loading={loading}
          url={`/daily-ops/overview/employee-status/out-zone?date=${date}&location=${selectedSiteId}&hour=8`}
        ></IncidentNumberSummary>
        <IncidentNumberSummary
          icon={HeartRateAlertsIcon}
          incedentType="High Heart Rate Alerts"
          numberOfIncedents={activitySummaryData?.numberOfHeartRateAlerts ?? 0}
          loading={loading}
          url={`/daily-dashboard?date=${date}&location=${selectedSiteId}&team=${selectedTeamId}`}
        ></IncidentNumberSummary>
        {/* <IncidentNumberSummary
          icon={PhysicalExcertionAlertsIcon}
          incedentType="High Physical Exertion Alert"
          numberOfIncedents={
            activitySummaryData?.numberOfHighPhysicalExertionAlerts ?? 0
          }
          loading={loading}
        ></IncidentNumberSummary> */}
      </div>

      {loading ? (
        <Loading></Loading>
      ) : (
        <div className="chart-row">
          <div className="card">
            <div className="chart-title">
              <div>
                <strong> Work Hours</strong>
                <img
                  src={InformationIcon}
                  className="information-icon"
                  alt="information"
                ></img>
              </div>
            </div>
            <div className="chart-container">
              <DailyOpsHoursChart
                centerText={roundUp(
                  activityChartData?.workHours?.reduce(
                    (x, y) => x + (y["value"] || 0),
                    0
                  ) ?? 0,
                  1
                )}
                centerSubText={`Total ${
                  APP_SETTINGS.displayHourly ? "Hours" : "Minutes"
                }`}
                chartData={activityChartData?.workHours ?? []}
              ></DailyOpsHoursChart>
            </div>
            <Link
              className="link"
              to={`/daily-ops/overview/employee-status?location=${selectedSiteId}&date=${date}&team=${selectedTeamId}`}
            >
              <Button className="card-button">View More</Button>
            </Link>
          </div>
          <div className="card">
            <div className="chart-title">
              <div>
                <strong> Activity Hours</strong>
                <img
                  src={InformationIcon}
                  className="information-icon"
                  alt="information"
                ></img>
              </div>
            </div>
            <div className="chart-container">
              <DailyOpsHoursChart
                centerText={roundUp(
                  activityChartData?.activityHours?.reduce(
                    (x, y) => x + (y["value"] || 0),
                    0
                  ) ?? 0,
                  1
                )}
                centerSubText={`Total ${
                  APP_SETTINGS.displayHourly ? "Hours" : "Minutes"
                }`}
                chartData={activityChartData?.activityHours ?? []}
              ></DailyOpsHoursChart>
            </div>
            <Link
              className="link"
              to={`/daily-ops/overview/activity-hours?location=${selectedSiteId}&date=${date}&team=${selectedTeamId}`}
            >
              <Button className="card-button">View More</Button>
            </Link>
          </div>
          {/*         
          <div className="card">
            <div className="chartTitle">
              <div>
                <strong>Heart Rate</strong>
                <img
                  src={InformationIcon}
                  className="information-icon"
                  alt="information"
                ></img>
              </div>
            </div>
            <div className="chartContainer">
              <DailyOpsHoursChart
                centerText={
                  activityChartData?.heartRateIntensity.reduce(
                    (x, y) => x + (y["value"] || 0),
                    0
                  ) ?? 0
                }
                centerSubText="Total Hours"
                chartData={activityChartData?.heartRateIntensity ?? []}
              ></DailyOpsHoursChart>
            </div>
            <Link
              className="link"
              to={`/daily-ops/heart-rate-hours?location=${selectedSiteId}&date=${date}&team=${selectedTeamId}`}
            >
              <Button className="card-button">View More</Button>
            </Link>
          </div>
         */}
          <div className="card">
            <div className="chart-title">
              <div>
                <strong> Physical Exertion</strong>
                <img
                  src={InformationIcon}
                  className="information-icon"
                  alt="information"
                ></img>
              </div>
            </div>
            <div className="chart-container">
              <DailyOpsHoursChart
                centerText={roundUp(
                  activityChartData?.physicalExertion?.reduce(
                    (x, y) => x + (y["value"] || 0),
                    0
                  ) ?? 0,
                  1
                )}
                centerSubText={`Total ${
                  APP_SETTINGS.displayHourly ? "Hours" : "Minutes"
                }`}
                chartData={activityChartData?.physicalExertion ?? []}
              ></DailyOpsHoursChart>
            </div>
            <Link
              className="link"
              to={`/daily-ops/overview/exertion-hours?location=${selectedSiteId}&date=${date}&team=${selectedTeamId}`}
            >
              <Button className="card-button">View More</Button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}
