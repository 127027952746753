import React from "react";
import "@progress/kendo-theme-default/dist/all.css";
import "./App.scss";
import { Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import Layout from "./components/Layout/Layout";
import { PrivateRoute } from "./components/PrivateRoute/PrivateRoute";
import Login from "./pages/Login/Login";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DailyDashboard from "./pages/DailyDashboard/DailyDashboard";
import {
  configAuthHeaders,
  configRefreshToken,
} from "./services/axiosConfigService";
import { AdminRoute } from "./components/AdminRoute/AdminRoute";
import User from "./pages/User/User";
import IncidentReportPage from "./pages/IncidentReportPage/IncidentReportPage";
import MonthlyDashboard from "./pages/MonthlyDashboard/MonthlyDashboard";
import YearlyDashboard from "./pages/YearlyDashboard/YearlyDashboard";
import Settings from "./pages/Settings/Settings";
import LivePage from "./pages/LivePage/LivePage";
import DailyOpsHeatmapView from "./pages/DailyOpsHeatmapView/DailyOpsHeatmapView";
import Home from "./pages/Home/Home";
import WorkShift from "./pages/WorkShift/WorkShift";
import DailyOpsSummary from "./pages/DailyOpsOverview/DailyOpsOverview";
import DailyOpsEmployeeStatus from "./pages/DailyOpsEmployeeStatus/DailyOpsEmployeeStatus";
import DailyOpsEmployeeStatusTableView from "./pages/DailyOpsEmployeeStatusTableView/DailyOpsEmployeeStatusTableView";
import DialyOpsMap from "./pages/DialyOpsMap/DialyOpsMap";
import DailyOpsEmployeeActivityTableView from "./pages/DailyOpsEmployeeActivityTableView/DailyOpsEmployeeActivityTableView";
import DailyOpsEmployeeExertionTableView from "./pages/DailyOpsEmployeeExertionTableView/DailyOpsEmployeeExertionTableView";
import DailyOpsEmployeeHeartRateTableView from "./pages/DailyOpsEmployeeHeartRateTableView/DailyOpsEmployeeHeartRateTableView";
import LiveView from "./pages/LiveView/LiveView";
import TeamView from "./subpages/TeamView/TeamView";
import VulcanStore from "./pages/VulcanStore/VulcanStore";
import UserProfile from "./pages/UserProfile/UserProfile";

function App() {
  configAuthHeaders();
  configRefreshToken();
  return (
    <div className="App">
      <Router history={createBrowserHistory()}>
        <Switch>
          <Route path="/login">
            <Login></Login>
          </Route>
          <PrivateRoute path="/" exact={true}>
            <Layout>
              <Home></Home>
            </Layout>
          </PrivateRoute>
          <PrivateRoute path="/download" exact={true}>
            <Layout>
              <VulcanStore></VulcanStore>
            </Layout>
          </PrivateRoute>
          <PrivateRoute path="/daily-dashboard" exact={true}>
            <Layout>
              <DailyDashboard></DailyDashboard>
            </Layout>
          </PrivateRoute>
          <PrivateRoute path="/monthly-dashboard" exact={true}>
            <Layout>
              <MonthlyDashboard></MonthlyDashboard>
            </Layout>
          </PrivateRoute>
          <PrivateRoute path="/yearly-dashboard" exact={true}>
            <Layout>
              <YearlyDashboard></YearlyDashboard>
            </Layout>
          </PrivateRoute>
          <PrivateRoute path="/incident-report/:id" exact={true}>
            <Layout>
              <IncidentReportPage></IncidentReportPage>
            </Layout>
          </PrivateRoute>
          <PrivateRoute path="/live" exact={true}>
            <Layout>
              <LiveView></LiveView>
            </Layout>
          </PrivateRoute>
          <PrivateRoute path="/live/:site" exact={true}>
            <Layout>
              <LivePage></LivePage>
            </Layout>
          </PrivateRoute>
          <PrivateRoute path="/live/:site/team" exact={true}>
            <Layout>
              <TeamView></TeamView>
            </Layout>
          </PrivateRoute>
          <PrivateRoute path="/live/:site/workshift" exact={true}>
            <Layout>
              <WorkShift></WorkShift>
            </Layout>
          </PrivateRoute>
          <PrivateRoute
            path="/person-view/:user_id"
            exact={true}
          ></PrivateRoute>
          <PrivateRoute path="/daily-ops" exact={true}>
            <Layout>
              <DialyOpsMap></DialyOpsMap>
            </Layout>
          </PrivateRoute>
          <PrivateRoute path="/daily-ops/overview" exact={true}>
            <Layout>
              <DailyOpsSummary></DailyOpsSummary>
            </Layout>
          </PrivateRoute>
          <PrivateRoute path="/daily-ops/overview/employee-status" exact={true}>
            <Layout>
              <DailyOpsEmployeeStatus></DailyOpsEmployeeStatus>
            </Layout>
          </PrivateRoute>
          <PrivateRoute path="/daily-ops/overview/heatmap" exact={true}>
            <Layout>
              <DailyOpsHeatmapView></DailyOpsHeatmapView>
            </Layout>
          </PrivateRoute>
          <PrivateRoute path="/daily-ops/overview/activity-hours" exact={true}>
            <Layout>
              <DailyOpsEmployeeActivityTableView></DailyOpsEmployeeActivityTableView>
            </Layout>
          </PrivateRoute>
          <PrivateRoute path="/daily-ops/overview/exertion-hours" exact={true}>
            <Layout>
              <DailyOpsEmployeeExertionTableView></DailyOpsEmployeeExertionTableView>
            </Layout>
          </PrivateRoute>
          <PrivateRoute
            path="/daily-ops/overview/heart-rate-hours"
            exact={true}
          >
            <Layout>
              <DailyOpsEmployeeHeartRateTableView></DailyOpsEmployeeHeartRateTableView>
            </Layout>
          </PrivateRoute>
          <PrivateRoute
            path="/daily-ops/overview/employee-status/:zone"
            exact={true}
          >
            <Layout>
              <DailyOpsEmployeeStatusTableView></DailyOpsEmployeeStatusTableView>
            </Layout>
          </PrivateRoute>
          {/* <PrivateRoute path="/person-view/:user_id" exact={true}>
            <Layout>
              <PersonView></PersonView>
            </Layout>
          </PrivateRoute> */}
          <PrivateRoute path="/user" exact={true}>
            <Layout>
              <UserProfile></UserProfile>
            </Layout>
          </PrivateRoute>
          <PrivateRoute path="/user/:fullName" exact={true}>
            <Layout>
              <UserProfile></UserProfile>
            </Layout>
          </PrivateRoute>

          <AdminRoute path="/settings">
            <Layout>
              <Settings></Settings>
            </Layout>
          </AdminRoute>
          <AdminRoute path="/register-user">
            <Layout>
              <User toast={toast}></User>
            </Layout>
          </AdminRoute>
          <Route path="*">
            <Layout>
              <h1 style={{ marginTop: "2rem" }}>404: Page not found</h1>
            </Layout>
          </Route>
        </Switch>
      </Router>
      <ToastContainer hideProgressBar />
    </div>
  );
}

export default App;
