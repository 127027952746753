import React, { useState } from "react";
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import "./Settings.scss";
import UserList from "../UserList/UserList";
import { toast } from "react-toastify";
import SupervisingTeams from "../../subpages/SupervisingTeams/SupervisingTeams";
import Wearable from "../../subpages/Wearable/Wearable";
import WearableAssignment from "../../subpages/WearableAssignment/WearableAssignment";
import Building from "../../subpages/Building/Building";
import Level from "../../subpages/Level/Level";
import Unit from "../../subpages/Unit/Unit";
import Room from "../../subpages/Room/Room";
import Camera from "../../subpages/Camera/Camera";
import { AuthService } from "../../services/authService";
import TeamAssignment from "../../subpages/TeamAssignment/TeamAssignment";

export default function Settings() {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabSelected = (e: TabStripSelectEventArguments) => {
    setSelectedTab(e.selected);
  };
  if (!AuthService.hasSettingsAccess()) {
    return (
      <div className="Settings">
        <p className="forbidden">
          Forbidden: Please request access from an Admin Account and re-login.
        </p>
      </div>
    );
  }

  return (
    <div className="Settings">
      <TabStrip selected={selectedTab} onSelect={handleTabSelected}>
        <TabStripTab title="Users">
          <UserList toast={toast}></UserList>
        </TabStripTab>
        <TabStripTab title="Supervising Teams">
          <SupervisingTeams></SupervisingTeams>
        </TabStripTab>
        <TabStripTab title="Staff Team Assignment">
          <TeamAssignment></TeamAssignment>
        </TabStripTab>
        <TabStripTab title="Location">
          <Building></Building>
        </TabStripTab>
        <TabStripTab title="Level">
          <Level></Level>
        </TabStripTab>
        <TabStripTab title="Unit">
          <Unit></Unit>
        </TabStripTab>
        <TabStripTab title="Room">
          <Room></Room>
        </TabStripTab>
        <TabStripTab title="Camera Assignment">
          <Camera></Camera>
        </TabStripTab>
        <TabStripTab title="Wearable">
          <Wearable></Wearable>
        </TabStripTab>
        <TabStripTab title="Wearable Assignment">
          <WearableAssignment />
        </TabStripTab>
      </TabStrip>
    </div>
  );
}
