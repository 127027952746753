import moment from "moment";

export const formatDate = (unix: number): string => {
  return moment(unix * 1000).format("YYYY-MM-DD");
};
export const getDateObj = (unix: number): Date => {
  return moment(unix * 1000).toDate();
};
export const formatUnix = (date: string): number => {
  return moment(date, "YYYY-MM-DD").unix();
};
export const formatDateObjUnix = (date: Date): number => {
  return moment(date).unix();
};
export const getUnixTimestamp = (): number => {
  return moment().unix();
};

export const hhMMZZFormat = (date: string): string => {
  let hours = date.split('T')[1].split(':')[0];
  let minutes = date.split('T')[1].split(':')[1];
  let ampm = parseInt(hours) >= 12 ? "pm" : "am";
  let hoursNum = parseInt(hours) % 12;
  hoursNum = hoursNum ? hoursNum : 12;
  let minutesStr = minutes.toString().padStart(2, "0");
  let strTime = hoursNum + ":" + minutesStr + " " + ampm;
  return strTime;
};
