import { Button } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import LiveViewSiteSelection from "../../components/LiveViewSiteSelection/LiveViewSiteSelection";
import Loading from "../../components/Loading/Loading";
import { BuildingModelWithLocation } from "../../models/BuildingModel";
import { UnitModel } from "../../models/UnitModel";
import LiveMapView from "../../subpages/LiveMapView/LiveMapView";
import "./LivePage.scss";

export default function LivePage() {
  const [selectedSiteId, setSelectedSiteId] = useState<number>(-1);
  const [selectedZoneId, setSelectedZoneId] = useState<number>(-1);
  const [sites, setSites] = useState<BuildingModelWithLocation[]>([]);
  const [zones, setZones] = useState<UnitModel[]>([]);
  const [loading, setChartLoading] = useState(false);
  const [locationSearchCoordinates, setLocationSearchCoordinates] = useState({
    lat: 0,
    long: 0,
  });
  useEffect(() => {
    const search = window.location.search,
      params = new URLSearchParams(search),
      siteIdFromUrl = params.get("location");
    setChartLoading(true);
    let results = Promise.all([
      axios.get("/api/v2.0/building/location"),
      axios.get("/api/v2.0/unit"),
    ]);
    results
      .then((res) => {
        setSites(res[0].data);
        if (parseInt(siteIdFromUrl?.toString() ?? "") <= 0) {
          setSelectedSiteId(res[0].data[0]?.building_id ?? -1);
        } else {
          setSelectedSiteId(parseInt(siteIdFromUrl?.toString() ?? ""));
        }
        setZones(res[1].data);
      })
      .finally(() => setChartLoading(false));
  }, []);
  return (
    <div className="LivePage">
      {loading ? (
        <Loading></Loading>
      ) : (
        <>
          <div>
            <LiveViewSiteSelection
              setSelectedSiteId={setSelectedSiteId}
              selectedSiteId={selectedSiteId}
              sites={sites}
              selectedZoneId={selectedZoneId}
              setSelectedZoneId={setSelectedZoneId}
              zones={zones}
              setLocationSearchCoordinates={setLocationSearchCoordinates}
            ></LiveViewSiteSelection>
            <Link
              className="link"
              to={
                `/live/` +
                `${
                  sites.filter((x) => x.building_id === selectedSiteId).length >
                  0
                    ? sites.filter((x) => x.building_id === selectedSiteId)[0]
                        .name
                    : ""
                }` +
                `/team?location=${selectedSiteId}`
              }
            >
              <Button className="cardButton">Go to Team View</Button>
            </Link>
          </div>
          <LiveMapView
            locationSearchCoordinates={locationSearchCoordinates}
            selectedZone={selectedZoneId}
            selectedSite={selectedSiteId}
          />
        </>
      )}{" "}
    </div>
  );
}
