import React from "react";
import "./AlertCell.scss";

interface AlertCellProps {
  alerts: number;
}

export class AlertCell extends React.Component<AlertCellProps> {
  render() {
    return (
      <td>
        <div className="cell">
          <span
            className={`${this.props.alerts > 0 ? "red-dot" : "green-dot"}`}
          ></span>
          <p>{this.props.alerts}</p>
        </div>
      </td>
    );
  }
}
