import React, { useState } from "react";
import "./SendUserMessagePopUp.scss";
import Popup from "../Popup/Popup";
import { Button, TextField } from "@material-ui/core";
import axios from "axios";
import Loading from "../Loading/Loading";
import { toast } from "react-toastify";

const msg_character_length = 140;
export default function SendUserMessagePopUp({
  isOpen,
  handleVisibility,
  selectedUserId,
  selectedUser,
  teamUserIds,
}: {
  isOpen: boolean;
  handleVisibility: (status: boolean) => void;
  selectedUserId: string;
  selectedUser: { first_name: string; last_name: string };
  teamUserIds: string;
}) {
  const [message, setMessage] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [sendToTeam, setSendToTeam] = useState(false);
  const sendMessage = () => {
    if (selectedUserId && message) {
      setLoading(true);
      axios
        .get(
          `/api/v2.0/daily-ops/push-message?userIds=${
            sendToTeam ? teamUserIds : selectedUserId
          }&message=${message}`
        )
        .then((result) => {
          console.log(result.data);
          if (result.status !== 200) {
            toast["error"]("Error occured while sending message");
          } else {
            toast["success"]("Message has been sent.");
            handleVisibility(false);
          }
        })
        .catch(() => {
          toast["error"]("Error occured while sending message");
        })
        .finally(() => setLoading(false));
    }
  };
  return (
    <Popup
      isOpen={isOpen}
      handleVisibility={handleVisibility}
      title="Message"
      subTitle="Send messages to your employees."
      children={
        loading ? (
          <Loading></Loading>
        ) : (
          <div
            style={{
              width: "100%",
              height: "auto",
              display: "grid",
              rowGap: 15,
            }}
          >
            <span style={{ fontSize: 14 }}>
              Employee Name: {selectedUser.first_name ?? ""}{" "}
              {selectedUser.last_name ?? ""}
            </span>
            <div style={{ display: "inline-block" }}>
              {" "}
              <input
                type="checkbox"
                style={{ display: "inline-block" }}
                value={sendToTeam ? 1 : 0}
                onChange={() => setSendToTeam(!sendToTeam)}
              />{" "}
              Send the message to the Team
            </div>
            <TextField
              label="Message"
              multiline
              rows={4}
              value={message}
              onChange={(e) => {
                if (message.length < msg_character_length)
                  setMessage(e.target.value);
              }}
              helperText={`Maximum ${
                msg_character_length - message.length ?? 0
              } characters`}
            />
            <div style={{ textAlign: "right" }}>
              <Button
                className="popup-button-cancel"
                onClick={() => handleVisibility(false)}
                variant="text"
              >
                Cancel
              </Button>
              <Button
                className="popup-button"
                variant="contained"
                disabled={message.length === 0}
                onClick={sendMessage}
              >
                Submit
              </Button>
            </div>
          </div>
        )
      }
    />
  );
}
