import React, { useEffect, useState } from "react";
import { Map as LeafletMap, Marker, TileLayer } from "react-leaflet";
import "./LastSeenLocationPopup.scss";
import Popup from "../Popup/Popup";
import axios from "axios";
import { defaultLat, defaultLong } from "../../helpers/LocationHelper";
import Loading from "../Loading/Loading";
import { divIcon } from "leaflet";

export default function LastSeenLocationPopup({
  isOpen,
  handleVisibility,
  selectedUser,
}: {
  isOpen: boolean;
  handleVisibility: (status: boolean) => void;
  selectedUser: string;
}) {
  const [userLocation, setUserLocation] = useState({
    lat: 0,
    long: 0,
    timestamp: "",
  });
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (selectedUser) {
      setLoading(true);
      axios
        .get(`/api/v2.0/daily-ops/user-last-seen?userId=${selectedUser}`)
        .then((result) => {
          console.log(result.data);
          setUserLocation(result.data);
        })
        .finally(() => setLoading(false));
    }
  }, []);

  return (
    <Popup
      isOpen={isOpen}
      handleVisibility={handleVisibility}
      title="Location"
      subTitle="Employee's last seen loaction"
      children={
        loading ? (
          <Loading></Loading>
        ) : (
          <LeafletMap
            center={[
              userLocation.lat ?? defaultLat,
              userLocation.long ?? defaultLong,
            ]}
            zoom={19}
            zoomControl={false}
            style={{ width: "100%", height: 300 }}
            className="LocationPopup"
          >
            <TileLayer
              maxNativeZoom={19}
              maxZoom={23}
              attribution='New OneMap | Map data &copy; contributors, <a href="http://SLA.gov.sg">Singapore Land Authority</a>'
              url="https://maps-{s}.onemap.sg/v3/Default/{z}/{x}/{y}.png"
            />
            <Marker
              icon={divIcon({
                className: "map-marker-pin",
              })}
              position={[
                userLocation.lat ?? defaultLat,
                userLocation.long ?? defaultLong,
              ]}
            ></Marker>
          </LeafletMap>
        )
      }
    />
  );
}
