import axios from "axios";

export interface OneMapSearchResults {
    found: number
    totalNumPages: number
    pageNum: number
    results: OneMapSearchResultItem[]
}

export interface OneMapSearchResultItem {
    SEARCHVAL: string
    BLK_NO: string
    ROAD_NAME: string
    BUILDING: string
    ADDRESS: string
    POSTAL: string
    X: string
    Y: string
    LATITUDE: string
    LONGITUDE: string
}

export async function search(
    searchTerm: string,
    geom: boolean,
    addressDetails: boolean,
): Promise<OneMapSearchResults> {
    return axios.get<OneMapSearchResults>(
        "/api/v2.0/oneMap/search", {
            params: {
                searchVal: searchTerm,
                returnGeom: geom ? 'Y' : 'N',
                getAddrDetails: addressDetails ? 'Y' : 'N'
            }
        }
    ).then(it => it.data)
}
