import React from 'react';
import './NameCell.scss';
interface NameCellProps {
  dataItem: any;
  field: string;
}

export class NameCell extends React.Component<NameCellProps> {
  render() {
    const value = this.props.dataItem[this.props.field];
    const status = this.props.dataItem['status'];

    return (
      <td
        className={`NameCell ${
          (!status || status) === 'unverified' ? 'unverified' : ''
        }`}
      >
        {(!status || status) === 'unverified'
          ? 'unverified'
          : value
          ? value.toLocaleString()
          : '-'}
      </td>
    );
  }
}
