import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table, TableHead, TableBody } from "@material-ui/core";
import {
  faSortAmountDown,
  faSortAmountDownAlt,
} from "@fortawesome/free-solid-svg-icons";
import { formatDate, getUnixTimestamp } from "../../helpers/DateHelper";
import Loading from "../../components/Loading/Loading";
import DailyOpsSiteSelection from "../../components/DailyOpsSiteSelection/DailyOpsSiteSelection";
import NoData from "../../components/NoData/NoData";
import ProfileIcon from "../../resources/icons/dailyOps/profile.svg";
import "./DailyOpsEmployeeExertionTableView.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  StyledTableCell,
  StyledTableRow,
  StyledTableHeaderRow,
  defaultPageSize,
} from "../../helpers/tableDesignHelper";
import { APP_SETTINGS, roundUp } from "../../helpers/Utils";

export default function DailyOpsEmployeeExertionTableView() {
  const [selectedSiteId, setSelectedSiteId] = useState<number>(-1);
  const [date, setDate] = useState<number>(getUnixTimestamp());
  const [selectedTeamId, setSelectedTeamId] = useState<number>(-1);
  const [currentPage] = useState<number>(1);
  const [loading, setLoading] = useState(false);
  const [employeeSearchText, setEmployeeSearchText] = useState<string>("");
  const [tableRows, setData] = useState<any>({});
  const [sortOrderAsc, setSortOrderAsc] = useState<boolean>(true);
  const [sortKey, setSortKey] = useState<string>("");

  useEffect(() => {
    const search = window.location.search,
      params = new URLSearchParams(search),
      siteIdFromUrl = params.get("location"),
      teamIdFromUrl = params.get("team"),
      dateFromUrl = params.get("date");
    if (siteIdFromUrl) setSelectedSiteId(parseInt(siteIdFromUrl));
    if (dateFromUrl) setDate(parseInt(dateFromUrl));
    if (teamIdFromUrl) setSelectedTeamId(parseInt(teamIdFromUrl));
  }, []);

  useEffect(() => {
    const formattedDate = formatDate(date);
    if (formattedDate && selectedSiteId > 0) {
      setLoading(true);
      axios
        .get(
          `/api/v2.0/daily-ops/user-exertion-list?startDate=${formattedDate}&endDate=${formattedDate}` +
            `&buildingId=${selectedSiteId}&employeeName=${employeeSearchText}&teamId=${selectedTeamId}` +
            `&sortKey=${sortKey}&sortOrder=${
              sortOrderAsc ? "asc" : "desc"
            }&pageSize=${defaultPageSize}&page=${currentPage}`
        )
        .then((result) => {
          setData(result.data ?? {});
        })
        .finally(() => setLoading(false));
    }
  }, [
    date,
    selectedSiteId,
    employeeSearchText,
    selectedTeamId,
    sortKey,
    sortOrderAsc,
  ]);
  return (
    <div className="DailyOpsEmployeeExertionTableView">
      <div>
        <DailyOpsSiteSelection
          selectedSiteId={selectedSiteId}
          date={date}
          setDate={setDate}
          setSelectedSiteId={setSelectedSiteId}
          selectedTeamId={selectedTeamId}
          setSelectedTeamId={setSelectedTeamId}
          employeeSearchText={employeeSearchText}
          setEmployeeSearchText={setEmployeeSearchText}
        ></DailyOpsSiteSelection>
      </div>
      <div>
        <Table>
          <TableHead>
            <StyledTableHeaderRow>
              <StyledTableCell align="left">ID</StyledTableCell>
              <StyledTableCell align="left">Name</StyledTableCell>
              <StyledTableCell
                align="center"
                onClick={() => {
                  setSortKey("");
                  setSortOrderAsc(!sortOrderAsc);
                }}
                className="table-sort-cell"
              >
                Total Hours
                {sortKey === "" &&
                  (sortOrderAsc ? (
                    <FontAwesomeIcon
                      className="table-sort-icon"
                      icon={faSortAmountDownAlt}
                    ></FontAwesomeIcon>
                  ) : (
                    <FontAwesomeIcon
                      className="table-sort-icon"
                      icon={faSortAmountDown}
                    ></FontAwesomeIcon>
                  ))}
              </StyledTableCell>
              {tableRows?.exertionTypeList &&
                tableRows.exertionTypeList.map((x: any) => {
                  return (
                    <StyledTableCell
                      align="center"
                      onClick={() => {
                        setSortKey(x.intensity_type_name);
                        setSortOrderAsc(!sortOrderAsc);
                      }}
                      className="table-sort-cell"
                    >
                      {x.intensity_type_name}
                      {sortKey === x.intensity_type_name &&
                        (sortOrderAsc ? (
                          <FontAwesomeIcon
                            className="table-sort-icon"
                            icon={faSortAmountDown}
                          ></FontAwesomeIcon>
                        ) : (
                          <FontAwesomeIcon
                            className="table-sort-icon"
                            icon={faSortAmountDownAlt}
                          ></FontAwesomeIcon>
                        ))}
                    </StyledTableCell>
                  );
                })}
              <StyledTableCell align="center">Profile</StyledTableCell>
            </StyledTableHeaderRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <StyledTableRow>
                <StyledTableCell
                  colSpan={
                    (tableRows?.exertionTypeList
                      ? tableRows?.exertionTypeList?.length
                      : 0) + 4
                  }
                >
                  <Loading></Loading>
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              tableRows?.userExertionHours &&
              tableRows.userExertionHours.map((row: any, i: number) => (
                <StyledTableRow key={i}>
                  <StyledTableCell component="th" align="left" scope="row">
                    {`${row.userId.split("-")[0]}`}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {`${row.userName}`}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {roundUp(
                      row.exertionTypes.reduce(
                        (acc: any, curr: any) => acc + curr.duration,
                        0
                      ) ?? 0,
                      1
                    )}{" "}
                    {APP_SETTINGS.displayHourly ? " hr" : " min"}
                  </StyledTableCell>
                  {tableRows?.exertionTypeList &&
                    tableRows.exertionTypeList.map((x: any, j: number) => {
                      return (
                        <StyledTableCell align="center" key={j}>
                          {row.exertionTypes.filter(
                            (y: any) =>
                              y.intensity_type_id === x.intensity_type_id
                          )[0]?.duration ?? 0}{" "}
                          {APP_SETTINGS.displayHourly ? " hr" : " min"}
                        </StyledTableCell>
                      );
                    })}
                  <StyledTableCell align="center">
                    <a
                      href={`/user/${row.first_name} ${row.last_name}?userid=${row.user_id}`}
                    >
                      <img className="table-icon-large" src={ProfileIcon}></img>
                    </a>
                  </StyledTableCell>
                </StyledTableRow>
              ))
            )}
            {!tableRows.exertionTypeList ? (
              <StyledTableRow key={0}>
                <StyledTableCell
                  colSpan={
                    (tableRows?.exertionTypeList
                      ? tableRows?.exertionTypeList?.length
                      : 0) + 4
                  }
                >
                  <NoData></NoData>
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
