import React, { useState, useEffect } from "react";
import { IncidentByMonth } from "../../models/IncidentByMonth";
import {
  Chart,
  ChartTitle,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartArea,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartLegendInactiveItems,
} from "@progress/kendo-react-charts";
import { monthNames } from "../../services/commonServices";
import { CHART_COLOR, TEXT_COLOR } from "../../constants/constants";

interface Props {
  data: IncidentByMonth[];
}
interface YearChartData {
  year: number;
  data: number[];
}

const YOYChart: React.FC<Props> = ({ data }) => {
  const [years, setYears] = useState<YearChartData[]>([]);
  useEffect(() => {
    if (data.length) {
      //let init:number[]=[]
      let yearData: YearChartData[] = [];

      setYears(
        data.reduce((prev, current) => {
          let item = prev.find((x) => x.year === current.year);
          if (item) {
            //prev.push(current.year)
            item.data[current.month - 1] = current.total;
          } else {
            let data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            data[current.month - 1] = current.total;
            prev.push({
              year: current.year,
              data,
            });
          }
          return prev;
        }, yearData)
      );
    }
  }, [data]);
  return (
    <Chart>
      <ChartTitle text="Year on Year" color={TEXT_COLOR} />
      <ChartArea margin={30} background={CHART_COLOR} />

      <ChartLegend
        position="bottom"
        orientation="horizontal"
        labels={{ color: TEXT_COLOR }}
      >
        <ChartLegendInactiveItems labels={{ color: TEXT_COLOR }} />
      </ChartLegend>
      <ChartCategoryAxis>
        <ChartCategoryAxisItem categories={monthNames} color={TEXT_COLOR} />
      </ChartCategoryAxis>
      <ChartValueAxis>
        <ChartValueAxisItem color={TEXT_COLOR} />
      </ChartValueAxis>
      <ChartSeries>
        {years.map((year) => (
          <ChartSeriesItem
            key={year.year}
            type="line"
            data={year.data}
            name={year.year.toString()}
            tooltip={{ visible: true, format: "Incidents: {0}" }}
          />
        ))}
      </ChartSeries>
    </Chart>
  );
};
export default YOYChart;
