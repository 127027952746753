import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import React from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "./Popup.scss";

export default function Popup({
  isOpen,
  handleVisibility,
  title = "",
  children = <></>,
  subTitle = "",
}: {
  isOpen: boolean;
  handleVisibility: (status: boolean) => void;
  children?: any;
  title?: string;
  subTitle?: string;
}) {
  return (
    <Dialog
      open={isOpen}
      onClose={handleVisibility}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="dialog"
    >
      <DialogTitle id="alert-dialog-title">
        {title} <small style={{ marginLeft: 10, fontSize:12 }}>{subTitle}</small>
        <IconButton
          onClick={() => {
            handleVisibility(false);
          }}
          className="dialog-close-icon"
        >
          <FontAwesomeIcon icon={faTimes} />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ width: "100%", padding: 10 }}>
        {children}
      </DialogContent>
    </Dialog>
  );
}
