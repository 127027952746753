export const SORT_LATEST_TO_OLDEST = 1;
export const SORT_OLDEST_TO_LATEST = 2;
export const FILTER_AWAIT_RESPONSE = 3;
export const FILTER_UNRESOLVED = 4;
export const FILTER_RESOLVED = 5;

export const STATUS_AWAIT_RESPONSE = 1;
export const STATUS_UNRESOLVED_WILL_ACT = 2;
export const STATUS_UNRESOLVED_NEED_TIME = 3;
export const STATUS_RESOLVED_FALSE_ALARM = 4;
export const STATUS_RESOLVED_REPORTED = 5;

export const BACKGROUND_COLOR = "#2E394E";

export const BOX_COLOR = "#2a3a5a";

export const TITLE_COLOR = "#26374D";
export const TEXT_COLOR = "#FFFFFF";

export const CHART_COLOR = "#2a3a5a";

export const FALL_COLOR = "#FF106E";
export const NEAR_MISS_COLOR = "#6296F8";
export const HAZARD_COLOR = "#FFBD2F";
export const HR_ALERT_COLOR = "#03fcb1";

export const LINE_COLOR = "#C657FA";

export const RED_COLOR = "#FF106E";
export const YELLOW_COLOR = "#FFBD2F";
export const GREEN_COLOR = "#1ED9C3";
export const REACT_TOKEN_AUTH = "REACT_TOKEN_AUTH";
