import { Box, CircularProgress } from "@material-ui/core";
import React from "react";

export default function Loading() {
  return (
    <div
      style={{
        display: "grid",
        justifyContent: "center",
        marginTop: 50,
        marginBottom: 50,
        verticalAlign: "middle",
      }}
    >
      <Box sx={{ display: "flex" }}>
        <CircularProgress color="secondary" />
      </Box>
    </div>
  );
}
