import React from "react";

interface CellProps {
  dataItem: any;
  field: string;
}

export class PercentageCell extends React.Component<CellProps> {
  render() {
    const value = this.props.dataItem[this.props.field];
    const displayValue = isNaN(value) ? "NA" : `${value.toFixed(0)}%`;
    return <td className={`PercentageCell`}>{displayValue}</td>;
  }
}
