import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { HazardType } from "../../models/HazardType";
import { IncidentType } from "../../models/IncidentType";
import { Status } from "../../models/Status";
import IncidentReport from "../../components/IncidentReport/IncidentReport";
import { UserModel } from "../../models/UserModel";
import { useMemo } from "react";

export default function IncidentReportPage() {
  const params = useParams<{ id: string }>();
  const id = useMemo(() => Number.parseInt(params.id), [params])

  const [hazardTypes, setHazardTypes] = useState<HazardType[]>([]);
  const [incidentTypes, setIncidentTypes] = useState<IncidentType[]>([]);
  const [statusTypes, setStatusTypes] = useState<Status[]>([]);
  const [users, setUsers] = useState<UserModel[]>([]);
  useEffect(() => {
    (async () => {
      let results = await Promise.all([
        axios.get("api/v2.0/data/hazard-types"),
        axios.get("api/v2.0/data/status"),
        axios.get("api/v2.0/data/incident-types"),
        axios.get("api/v2.0/user"),
      ]);

      setHazardTypes(results && results[0] && results[0].data);
      setStatusTypes(results && results[1] && results[1].data);
      setIncidentTypes(results && results[2] && results[2].data);
      setUsers(results && results[3] && results[3].data);
    })();
  }, [id]);
  return (
    <IncidentReport
      mode="view"
      hazardTypes={hazardTypes}
      statusTypes={statusTypes}
      incidentTypes={incidentTypes}
      id={id}
      users={users}
    ></IncidentReport>
  );
}
