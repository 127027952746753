import React, { useState, useEffect } from "react";
import "./Building.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import axios from "axios";
import { toast } from "react-toastify";
import { BuildingModel } from "../../models/BuildingModel";
import { TeamModel } from "../../models/TeamModel";
import { TEXT_COLOR, BOX_COLOR } from "../../constants/constants";
import BuildingForm from "../../components/Forms/BuildingForm/BuildingForm";

export default function Building() {
  const [open, setOpen] = useState<boolean>(false);
  const [buildingName, setBuildingName] = useState<string>("");
  const [buildingDescription, setBuildingDescription] = useState<string>("");
  const [zipcode, setZipcode] = useState<string>("");
  const [teamId, setTeamId] = useState<number>(0);
  const [buildings, setBuildings] = useState<BuildingModel[]>([]);

  const [teams, setTeams] = useState<TeamModel[]>([]);

  useEffect(() => {
    axios
      .get("/api/v2.0/building")
      .then((res) => setBuildings(res.data))
      .catch((err) => {
        console.error(err);
      });
  }, [open]);

  useEffect(() => {
    axios
      .get("/api/v2.0/data/team")
      .then((res) => setTeams(res.data))
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const CommandCell = (props: any) => (
    <td className="k-command-cell">
      <button
        className="k-button k-grid-remove-command"
        onClick={() =>
          window.confirm("Confirm deleting: " + props.dataItem.name) &&
          remove(props.dataItem)
        }
      >
        <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
      </button>
    </td>
  );

  const remove = (dataItem: BuildingModel) => {
    axios
      .delete("/api/v2.0/building", {
        data: {
          building_id: dataItem.building_id,
        },
      })
      .then((res) => {
        console.log(res.data);
        toast["success"]("Building Deleted");
        const filtered = buildings.filter(
          (record) => record.building_id !== dataItem.building_id
        );
        setBuildings(filtered);
      })
      .catch((err) => {
        console.error(err);
        toast["error"]("Error deleting location: " + err?.response?.data);
      });
  };

  const handleClose = () => {
    setBuildingName("");
    setBuildingDescription("");
    setZipcode("");
    setTeamId(0);
    setOpen(false);
  };

  const handleSubmit = () => {
    if (buildingName.length < 1) {
      toast["warning"]("Enter Building Name");
      return;
    } else if (teamId === 0) {
      toast["warning"]("Select Team");
      return;
    }
    axios
      .post("/api/v2.0/building/", {
        name: buildingName,
        team_id: teamId,
        description: buildingDescription,
        zipcode: zipcode,
      })
      .then((_res) => {
        handleClose();
        toast["success"]("Building Added!");
      })
      .catch((err) => {
        console.error(err);
        toast["error"]("Error adding new building(s): " + err?.response?.data);
      });
  };

  return (
    <div className="Building">
      <div className="firstrow">
        <h3>Add Locations</h3>
        <FontAwesomeIcon
          title="Add Locations"
          icon={faPlus}
          size={"lg"}
          cursor="pointer"
          onClick={handleOpen}
        ></FontAwesomeIcon>
      </div>
      <div className="buildings">
        <Grid data={[...buildings]}>
          <Column
            field="building_id"
            title="ID"
            width="75px"
            editable={false}
          />
          <Column className="fixed" field="name" title="Location" />
          <Column className="fixed" field="description" title="Description" />
          <Column className="fixed" field="team_name" title="Team" />
          <Column cell={CommandCell} />
        </Grid>
      </div>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogTitle style={{ color: TEXT_COLOR, backgroundColor: BOX_COLOR }}>
          Add Location
        </DialogTitle>
        <DialogContent
          style={{ color: TEXT_COLOR, backgroundColor: BOX_COLOR }}
          dividers
        >
          <DialogContentText style={{ color: TEXT_COLOR }}>
            Onboard Locations or Buildings
          </DialogContentText>
          <BuildingForm
            teams={teams}
            buildingName={buildingName}
            buildingDescription={buildingDescription}
            teamId={teamId}
            zipcode={zipcode}
            setBuildingName={setBuildingName}
            setBuildingDescription={setBuildingDescription}
            setTeamId={setTeamId}
            setZipcode={setZipcode}
          ></BuildingForm>
        </DialogContent>
        <DialogActions
          style={{ color: TEXT_COLOR, backgroundColor: BOX_COLOR }}
        >
          <Button style={{ color: TEXT_COLOR }} onClick={handleClose}>
            Cancel
          </Button>
          <Button style={{ color: TEXT_COLOR }} onClick={handleSubmit}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
