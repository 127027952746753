export function JSONToCSV(json) {
  const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
  const header = Object.keys(json[0]);
  let csv = json.map((row) =>
    header
      .map((fieldName) => JSON.stringify(row[fieldName], replacer))
      .join(",")
  );
  csv.unshift(header.join(","));
  csv = csv.join("\r\n");
  return csv;
}

export function downLoadFile(text, fileName) {
  var blob = new Blob([text], { type: "text/csv;charset=utf-8;" });

  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, fileName);
  } else {
    var link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export function dateTimeToString(d) {
  return (
    [d.getMonth() + 1, d.getDate(), d.getFullYear()].join("/") +
    " " +
    [d.getHours(), d.getMinutes(), d.getSeconds()].join(":")
  );
}

export function dateToString(d) {
  return `${d.getFullYear()}-${
    d.getMonth() + 1 > 9 ? d.getMonth() + 1 : "0" + (d.getMonth() + 1)
  }-${d.getDate() > 9 ? d.getDate() : "0" + d.getDate()}`;
}

export const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export function dateTimeToStringddMMMyyhhmm12(d) {
  let min = d.getMinutes();
  let hrs = d.getHours() % 12;
  return (
    [d.getDate(), monthNames[d.getMonth()], d.getFullYear()].join(" ") +
    " " +
    [hrs < 10 ? "0" + hrs : hrs, min < 10 ? "0" + min : min].join(":") +
    " " +
    (d.getHours() > 12 ? "PM" : "AM")
  );
}

export function normaliseHearRate(arr) {
  function calculate(array) {
    var i = 0,
      sum = 0,
      len = array.length;
    while (i < len) {
      sum = sum + array[i++];
    }
    return sum / len;
  }

  function removeOutliers(arr) {
    for (var i = 0; i < arr.length; i++) {
      if (i > 0) {
        if (arr[i] === 200) {
          if (arr[i - 1] < 200) {
            arr[i] = arr[i - 1];
          } else if (arr[i + 1] < 200) {
            arr[i] = arr[i + 1];
          }
        }
      } else if (i === 0) {
        arr[i] = arr[i + 1];
      } else if (i === arr.length - 1) {
        arr[i] = arr[i - 1];
      }
    }

    return arr;
  }

  arr = removeOutliers(arr);
  const data_len = arr.length;
  const sampling_rate = 25;
  const n_sample = Math.round(data_len / sampling_rate);
  let arr_new = [];
  for (var i = 0; i < n_sample; i++) {
    let arr_slice = arr.slice(i * sampling_rate, (i + 1) * sampling_rate);
    let estimated_hr = Math.round(calculate(arr_slice));
    arr_new.push(estimated_hr);
  }
  return arr_new;
}

export function getShiftTime(time) {
  const shiftDict = new Map();
  shiftDict.set(0, "00:00 AM");
  shiftDict.set(1, "01:00 AM");
  shiftDict.set(2, "02:00 AM");
  shiftDict.set(3, "03:00 AM");
  shiftDict.set(4, "04:00 AM");
  shiftDict.set(5, "05:00 AM");
  shiftDict.set(6, "06:00 AM");
  shiftDict.set(7, "07:00 AM");
  shiftDict.set(8, "08:00 AM");
  shiftDict.set(9, "09:00 AM");
  shiftDict.set(10, "10:00 AM");
  shiftDict.set(11, "11:00 AM");
  shiftDict.set(12, "12:00 PM");
  shiftDict.set(13, "13:00 PM");
  shiftDict.set(14, "14:00 PM");
  shiftDict.set(15, "15:00 PM");
  shiftDict.set(16, "16:00 PM");
  shiftDict.set(17, "17:00 PM");
  shiftDict.set(18, "18:00 PM");
  shiftDict.set(19, "19:00 PM");
  shiftDict.set(20, "20:00 PM");
  shiftDict.set(21, "21:00 PM");
  shiftDict.set(22, "22:00 PM");
  shiftDict.set(23, "23:00 PM");
  shiftDict.set(24, "00:00 AM");

  return shiftDict.get(time);
}
