import React, { useState, useEffect } from "react";
import "./Room.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Button, DialogContentText } from "@material-ui/core";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
// import { Form, FormElement } from "@progress/kendo-react-form";
import { toast } from "react-toastify";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import axios from "axios";
// import LevelForm from "../../components/Onboarding/LevelOnboarding/LevelForm";
// import FormPageTwo from "../../components/Onboarding/FormPageTwo";
// import { BuildingModel } from "../../models/BuildingModel";
import { LevelModel } from "../../models/LevelModel";
import { BOX_COLOR, TEXT_COLOR } from "../../constants/constants";
import { BuildingModel } from "../../models/BuildingModel";
import LocationForm from "../../components/Forms/LocationForm/LocationForm";
import LocationSecondForm from "../../components/Forms/LocationSecondForm/LocationSecondForm";
import { TeamModel } from "../../models/TeamModel";
import { UnitModel } from "../../models/UnitModel";
import { RoomModel } from "../../models/RoomModel";

export default function Room() {
  const [open, setOpen] = useState<boolean>(false);

  const [rooms, setRooms] = useState<RoomModel[]>([]);
  const [units, setUnits] = useState<UnitModel[]>([]);
  const [levels, setLevels] = useState<LevelModel[]>([]);
  const [buildings, setBuildings] = useState<BuildingModel[]>([]);
  const [teams, setTeams] = useState<TeamModel[]>([]);

  const [step, setStep] = useState(1);

  const [buildingId, setBuildingId] = useState<number>(0);
  const [levelId, setLevelId] = useState<number>(0);
  const [unitId, setUnitId] = useState<number>(0);
  const [roomCount, setRoomCount] = useState<number>(0);

  const [roomName, setRoomName] = useState<string[]>([]);
  const [roomDescription, setRoomDescription] = useState<string[]>([]);
  const [teamId, setTeamId] = useState<number[]>([]);

  const countArr: number[] = [];
  for (var i: number = 0; i < roomCount; i++) {
    countArr.push(i);
  }

  useEffect(() => {
    axios
      .get("/api/v2.0/room")
      .then((res) => {
        setRooms(res && res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [open]);

  useEffect(() => {
    (async () => {
      let results = await Promise.all([
        axios.get("/api/v2.0/building"),
        axios.get("/api/v2.0/data/team"),
        axios.get("api/v2.0/level"),
        axios.get("api/v2.0/unit"),
      ]);
      setBuildings(results && results[0] && results[0].data);
      setTeams(results && results[1] && results[1].data);
      setLevels(results && results[2] && results[2].data);
      setUnits(results && results[3] && results[3].data);
    })();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const CommandCell = (props: any) => (
    <td className="k-command-cell">
      <button
        className="k-button k-grid-remove-command"
        onClick={() =>
          window.confirm("Confirm deleting: " + props.dataItem.name) &&
          remove(props.dataItem)
        }
      >
        <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
      </button>
    </td>
  );

  const remove = (dataItem: RoomModel) => {
    axios
      .delete("/api/v2.0/room", {
        data: {
          room_id: dataItem.room_id,
        },
      })
      .then((res) => {
        console.log(res.data);
        toast["success"]("Room Deleted");
        const filtered = rooms.filter(
          (record) => record.room_id !== dataItem.room_id
        );
        setRooms(filtered);
      })
      .catch((err) => {
        console.error(err);
        toast["error"]("Error deleting Room: " + err?.response?.data);
      });
  };

  const handleClose = () => {
    setBuildingId(0);
    setLevelId(0);
    setUnitId(0);
    setRoomCount(0);
    setRoomName([]);
    setRoomDescription([]);
    setTeamId([]);
    setStep(1);
    setOpen(false);
  };

  const handleNext = () => {
    if (buildingId === 0) {
      toast["warning"]("Select Building");
      return;
    } else if (levelId === 0) {
      toast["warning"]("Select Level");
      return;
    } else if (unitId === 0) {
      toast["warning"]("Select Unit");
      return;
    } else if (roomCount < 1) {
      toast["warning"]("Select no. of rooms");
      return;
    }
    setStep(2);
  };

  const handleRoomName = (name: string, key: number) => {
    var roomNames = [...roomName];
    roomNames[key] = name;
    setRoomName(roomNames);
  };

  const handleRoomDesc = (desc: string, key: number) => {
    var roomDesc = [...roomDescription];
    roomDesc[key] = desc;
    setRoomDescription(roomDesc);
  };

  const handleTeamId = (team: number, key: number) => {
    var teams = [...teamId];
    teams[key] = team;
    setTeamId(teams);
  };

  const handleSubmit = () => {
    if (roomName.filter((name) => !!name).length < roomCount) {
      toast["warning"]("Enter Room Name");
      return;
    }
    var roomsData: any = [];

    for (var i = 0; i < roomCount; i++) {
      const newRoom = {
        name: roomName[i],
        description: roomDescription[i],
        team_id: teamId[i],
      };
      roomsData.push(newRoom);
    }

    axios
      .post("/api/v2.0/room", {
        building_id: buildingId,
        level_id: levelId,
        unit_id: unitId,
        rooms: roomsData,
      })
      .then((_res) => {
        handleClose();
        toast["success"]("Rooms Added!");
      })
      .catch((err) => {
        console.error(err);
        toast["error"]("Error adding new Room(s): " + err?.response?.data);
      });
  };

  return (
    <div className="Room">
      <div className="firstrow">
        <h3>Add Rooms</h3>
        <FontAwesomeIcon
          title="Add Room"
          icon={faPlus}
          size={"lg"}
          cursor="pointer"
          onClick={handleOpen}
        ></FontAwesomeIcon>
      </div>
      <div className="rooms">
        <Grid data={[...rooms]}>
          <Column className="fixed" field="room_id" title="ID" width="75px" />
          <Column className="fixed" field="building_name" title="Location" />
          <Column className="fixed" field="level_name" title="Level" />
          <Column className="fixed" field="unit_name" title="Unit" />
          <Column className="fixed" field="name" title="Room" />
          <Column className="fixed" field="description" title="Description" />
          <Column className="fixed" field="team_name" title="Team" />
          <Column cell={CommandCell} />
        </Grid>
      </div>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogTitle style={{ color: TEXT_COLOR, backgroundColor: BOX_COLOR }}>
          Add New Rooms
        </DialogTitle>
        <DialogContent
          style={{ color: TEXT_COLOR, backgroundColor: BOX_COLOR }}
          dividers
        >
          <DialogContentText style={{ color: TEXT_COLOR }}>
            {step === 1 ? (
              <h4>Step {step}: Select Location</h4>
            ) : (
              <h4>Step {step}: Enter Room Details </h4>
            )}
          </DialogContentText>

          {step === 1 ? (
            <LocationForm
              origin={"Rooms"}
              buildings={buildings}
              buildingId={buildingId}
              setBuildingId={setBuildingId}
              levels={levels}
              levelId={levelId}
              setLevelId={setLevelId}
              units={units}
              unitId={unitId}
              setUnitId={setUnitId}
              count={roomCount}
              setCount={setRoomCount}
            ></LocationForm>
          ) : (
            <ul>
              {countArr.map((i) => (
                <LocationSecondForm
                  origin={"Room"}
                  key={i.toString()}
                  teams={teams}
                  index={i}
                  handleName={handleRoomName}
                  handleDescription={handleRoomDesc}
                  handleTeamId={handleTeamId}
                />
              ))}
            </ul>
          )}
        </DialogContent>
        <DialogActions
          style={{ color: TEXT_COLOR, backgroundColor: BOX_COLOR }}
        >
          <Button style={{ color: TEXT_COLOR }} onClick={handleClose}>
            Cancel
          </Button>
          {step === 1 ? (
            <Button style={{ color: TEXT_COLOR }} onClick={handleNext}>
              Next
            </Button>
          ) : (
            <Button style={{ color: TEXT_COLOR }} onClick={handleSubmit}>
              Submit
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
