import React from "react";
import { APP_SETTINGS, roundUp } from "../../helpers/Utils";

interface CellProps {
  dataItem: any;
  field: string;
}

export class ActivityCell extends React.Component<CellProps> {
  render() {
    const value =
      this.props.dataItem["activities"][this.props.field]?.activity_duration ??
      0;
    return (
      <td className={`DurationCell`}>
        {value && roundUp(value, 1)}{" "}
        {APP_SETTINGS.displayHourly ? " hr" : " min"}
      </td>
    );
  }
}
