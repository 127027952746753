import React, { useEffect, useState } from "react";
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import "./UserProfile.scss";
import { ProfileHealth } from "../../subpages/ProfileHealth/ProfileHealth";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { AuthService } from "../../services/authService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faClock,
  faPhone,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";

export default function UserProfile() {
  const history = useHistory();

  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedUser, setSelectedUser] = useState("");
  const [hrData, setHrData] = useState([]);
  const [exertionData, setExertionData] = useState([]);
  const [userInfo, setUserInfo] = useState<any>({});
  const [hrDataLoading, setHrDataLoading] = useState(false);
  const [exertionDataLoading, setExertionDataLoading] = useState(false);
  const [userInoLoading, setUserInfoLoading] = useState(false);

  const [timePeriod, setTimePeriod] = useState("7d");

  useEffect(() => {
    const search = window.location.search,
      params = new URLSearchParams(search),
      userIdFromUrl = params.get("userid");

    if (userIdFromUrl) {
      setSelectedUser(userIdFromUrl);
    } else {
      ///if no urer id in url, get the logged in user id
      history.replace(
        "/user/" +
          AuthService.getLoggedInUserName() +
          "?userid=" +
          AuthService.getLoggedInUserId()
      );
    }
  }, []);

  useEffect(() => {
    if (selectedUser) {
      setHrDataLoading(true);
      setExertionDataLoading(true);
      setUserInfoLoading(true);

      axios
        .get(
          `/api/v2.0/profile/health/hr/trends?duration=${timePeriod}&user_id=${selectedUser}`
        )
        .then((res) => {
          setHrData(res.data ?? []);
        })
        .finally(() => {
          setHrDataLoading(false);
        });

      axios
        .get(
          `/api/v2.0/profile/health/exertion/trends?duration=${timePeriod}&user_id=${selectedUser}`
        )
        .then((res) => {
          setExertionData(res.data ?? []);
        })
        .finally(() => {
          setExertionDataLoading(false);
        });

      axios
        .get(`/api/v2.0/profile/user-info?user_id=${selectedUser}`)
        .then((res) => {
          setUserInfo(res.data);
        })
        .finally(() => {
          setUserInfoLoading(false);
        });
    }
  }, [timePeriod, selectedUser]);

  const handleTabSelected = (e: TabStripSelectEventArguments) => {
    setSelectedTab(e.selected);
  };
  return (
    <div className="ProfilePage">
      <div className="user-information">
        {userInoLoading ? (
          <></>
        ) : (
          <>
            <div className="label">
              <div>
                <FontAwesomeIcon
                  className="fa-icon"
                  icon={faClock}
                ></FontAwesomeIcon>
              </div>
              <div>
                <p>Last Seen</p>
                <h3>{userInfo.lastSeenAgo}</h3>
              </div>
            </div>
            <div className="label">
              <div>
                <FontAwesomeIcon
                  className="fa-icon"
                  icon={faUsers}
                ></FontAwesomeIcon>
              </div>
              <div>
                <p>Team</p>
                <h3>{userInfo.team}</h3>
              </div>
            </div>
            <div className="label">
              <div>
                <FontAwesomeIcon
                  className="fa-icon"
                  icon={faPhone}
                ></FontAwesomeIcon>
              </div>
              <div>
                <p>Contact Number</p>
                <h3>{userInfo.contact}</h3>
              </div>
            </div>
            <div className="label">
              <div>
                <FontAwesomeIcon
                  className="fa-icon"
                  icon={faAddressCard}
                ></FontAwesomeIcon>
              </div>
              <div>
                <p>Age</p>
                <h3>{userInfo.age}</h3>
              </div>
            </div>
          </>
        )}
      </div>
      <TabStrip selected={selectedTab} onSelect={handleTabSelected}>
        <TabStripTab title="Health">
          <ProfileHealth
            data={{
              hrData: hrData,
              isHrDataLoading: hrDataLoading,
              exertionData: exertionData,
              isExertionDataLoading: exertionDataLoading,
            }}
            timePeriod={timePeriod}
            setTimePeriod={setTimePeriod}
          ></ProfileHealth>
        </TabStripTab>
      </TabStrip>
    </div>
  );
}
