import React from "react";
import "./StatusCell.scss";
interface StatusCellProps {
  dataItem: any;
  field: string;
}

export class StatusCell extends React.Component<StatusCellProps> {
  render() {
    let value: string = this.props.dataItem[this.props.field];
    if (!value) value = "";
    let className = value.toLowerCase();
    if (value.toLowerCase().startsWith("new")) {
      className = "new";
    } else if (
      value.toLowerCase().startsWith("pending") ||
      value.toLocaleLowerCase().startsWith("on hold")
    ) {
      className = "open";
    } else if (
      value.toLowerCase().startsWith("resolved") ||
      value.toLowerCase().startsWith("false alarm") ||
      value.toLowerCase().startsWith("reviewed")
    ) {
      className = "closed";
    }

    return (
      <td className={`StatusCell ${className}`}>{value.toLocaleString()}</td>
    );
  }
}
