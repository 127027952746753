import React, { useState, useEffect } from "react";
import axios from "axios";

import "./YearlyDashboard.scss";
import { IncidentByMonth } from "../../models/IncidentByMonth";
import YOYChart from "../../components/YOYChart/YOYChart";
import IncidentByHourChart from "../../components/IncidentByHourChart/IncidentByHourChart";
import { IncidentByHour } from "../../models/IncidentByHour";
import { Incident } from "../../models/Incident";
import { CameraModel } from "../../models/CameraModel";
import { LocationModel } from "../../models/LocationModel";
import { IncidentSummary } from "../../models/IncidentSummary";
import IncidentsGrid from "../../components/IncidentsGrid/IncidentsGrid";
import "hammerjs";
import { SvgIcon } from "@progress/kendo-react-common";
import { clockIcon, exclamationCircleIcon } from "@progress/kendo-svg-icons";
import Modal from "react-modal";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

import {
  Chart,
  ChartArea,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesItemTooltip,
  ChartTitle,
  ChartTooltip,
  ChartSeriesLabels,
  ChartLegend,
  ChartLegendInactiveItems,
  ChartValueAxis,
  ChartValueAxisItem,
} from "@progress/kendo-react-charts";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { UserModel } from "../../models/UserModel";
import { ChartModel } from "../../models/ChartModel";
import { TimeSummary } from "../../models/TimeSummary";
import { monthNames } from "../../services/commonServices";
import {
  CHART_COLOR,
  FALL_COLOR,
  HAZARD_COLOR,
  LINE_COLOR,
  NEAR_MISS_COLOR,
  TEXT_COLOR,
  HR_ALERT_COLOR,
  BACKGROUND_COLOR,
} from "../../constants/constants";
import { UserSummary } from "../../models/UserSummary";
import TimePeriods from "../../components/TimePeriods/TimePeriods";
import { MenuItem, Select } from "@material-ui/core";
import { BuildingModel } from "../../models/BuildingModel";
import { getUnixTimestamp } from "../../helpers/DateHelper";
import IncidentReport from "../../components/IncidentReport/IncidentReport";
import { HazardType } from "../../models/HazardType";
import { IncidentType } from "../../models/IncidentType";
import { Status } from "../../models/Status";
import { ReportModalState } from "../DailyDashboard/DailyDashboard";

const TOP_LOCATION_MAX_CATEGORIES = 5;
const TOP_STAFF_MAX_CATEGORIES = 5;

export default function YearlyDashboard() {
  const [incidents, setIncidents] = useState<Incident[]>([]);
  const [selectedDate, setSelectedDate] = useState<MaterialUiPickersDate>(
    new Date()
  );
  const [allIncidentsSeries, setAllIncidentsSeries] = useState<number[]>([]);
  const [nearMissSeries, setNearMissSeries] = useState<number[]>([]);
  const [fallSeries, setFallSeries] = useState<number[]>([]);
  const [hazardSeries, setHazardSeries] = useState<number[]>([]);
  const [hrSeries, setHrSeries] = useState<number[]>([]);
  const [cameras, setCameras] = useState<CameraModel[]>([]);
  const [locations, setLocations] = useState<LocationModel[]>([]);
  const [buildingId, setBuildingId] = useState<number>(-1);
  const [buildings, setBuildings] = useState<BuildingModel[]>([]);
  const [urlDate, setUrlDate] = useState<number>(getUnixTimestamp());
  const [incidentSummary, setIncidentSummary] = useState<IncidentSummary>({
    fall_count: 0,
    fall_wearable_count: 0,
    fall_cctv_count: 0,
    nearmiss_count: 0,
    nearmiss_wearable_count: 0,
    nearmiss_cctv_count: 0,
    hazard_count: 0,
    hazard_wearable_count: 0,
    hazard_cctv_count: 0,
    hr_alert_count: 0,
    hr_alert_wearable_count: 0,
    hr_alert_cctv_count: 0,
  });
  const [barLocationCategories, setBarLocationCategories] = useState<string[]>(
    []
  );
  const [barLocationData, setBarLocationData] = useState<any[][]>(Array(3));
  const [staffBarChartData, setStaffBarChartData] = useState<ChartModel>({
    categories: [],
    nearMissData: [],
    fallData: [],
    hazardData: [],
    hrAlertData: [],
  });
  const [timeSummary, setTimeSummary] = useState<TimeSummary>({
    min_time_to_resolve: 0,
    max_time_to_resolve: 0,
    avg_time_to_resolve: 0,
    near_miss_count: 0,
    fall_count: 0,
    hazard_count: 0,
    spill_count: 0,
    object_count: 0,
    other_hazard_count: 0,
    top_hazard: "Unknown",
  });
  const [incidentsYOY, setIncidentsYOY] = useState<IncidentByMonth[]>([]);
  const [incidentsByHour, setIncidentsByHour] = useState<IncidentByHour[]>([]);
  const [hazardTypes, setHazardTypes] = useState<HazardType[]>([]);
  const [incidentTypes, setIncidentTypes] = useState<IncidentType[]>([]);
  const [statusTypes, setStatusTypes] = useState<Status[]>([]);
  const [users, setUsers] = useState<UserModel[]>([]);
  const [reportModalMode, setReportModalMode] =
  useState<ReportModalState>("none");
  const [selectedId, setSelectedId] = useState(0);
  //Set Incidents YOY
  useEffect(() => {
    const search = window.location.search,
      params = new URLSearchParams(search),
      siteIdFromUrl = params.get("location"),
      dateFromUrl = params.get("date");

    if (siteIdFromUrl) setBuildingId(parseInt(siteIdFromUrl));
    if (dateFromUrl) setUrlDate(parseInt(dateFromUrl));

    axios.get("api/v2.0/camera").then((res) => {
      if (res.data) setCameras(res.data);
    });
    axios.get("/api/v2.0/building").then((results) => {
      const buildings = results && results.data;
      setBuildings(buildings);
      (siteIdFromUrl && parseInt(siteIdFromUrl) > 0) ||
        setBuildingId(0);
    });
  }, []);
  useEffect(() => {
    const search = window.location.search,
      params = new URLSearchParams(search),
      siteIdFromUrl = params.get("location");

    if (siteIdFromUrl) setBuildingId(parseInt(siteIdFromUrl));
    Promise.all([
      axios.get("api/v2.0/data/hazard-types"),
      axios.get("api/v2.0/data/status"),
      axios.get("api/v2.0/data/incident-types"),
      axios.get("api/v2.0/user"),
    ]).then((results) => {
      setHazardTypes(results && results[0] && results[0].data);
      setStatusTypes(results && results[1] && results[1].data);
      setIncidentTypes(results && results[2] && results[2].data);
      setUsers(results && results[3] && results[3].data);
    });
  }, []);
  //Main Incident Call
  useEffect(() => {
    if(buildingId>=0){
      setFallSeries([]);
    setNearMissSeries([]);
    setHazardSeries([]);
    setHrSeries([]);
    setAllIncidentsSeries([]);
    const yearSelected =
      selectedDate?.getFullYear() ?? new Date().getFullYear();
    axios
      .get(
        "api/v2.0/incident/?incident_dt=" +
          yearSelected +
          "-01-1&incident_end_dt=" +
          yearSelected +
          "-12-31" +
          "&building_id=" +
          buildingId
      )
      .then((res) => {
        setIncidents(res && res.data);
        let listIncidents: Incident[] = res.data;
        let localAllIncidentSeries = Array(12).fill(0);
        let localFallSeries = Array(12).fill(0);
        let localNearMissSeries = Array(12).fill(0);
        let localHazardSeries = Array(12).fill(0);
        let localHRSeries = Array(12).fill(0);
        let localIncidentSummary = {
          fall_count: 0,
          fall_wearable_count: 0,
          fall_cctv_count: 0,
          nearmiss_count: 0,
          nearmiss_wearable_count: 0,
          nearmiss_cctv_count: 0,
          hazard_count: 0,
          hazard_wearable_count: 0,
          hazard_cctv_count: 0,
          hr_alert_count: 0,
          hr_alert_wearable_count: 0,
          hr_alert_cctv_count: 0,
        };
        listIncidents.forEach((incident) => {
          let index: number =
            parseInt(incident.incident_dt.substring(5, 7)) - 1;
          localAllIncidentSeries[index] += 1;

          switch (incident.incident_type_id) {
            case 2: //Fall
              localFallSeries[index] += 1;
              localIncidentSummary.fall_count += 1;
              incident.detected_by &&
              incident.detected_by.toLowerCase() === "wearable"
                ? (localIncidentSummary.fall_wearable_count += 1)
                : (localIncidentSummary.fall_cctv_count += 1);
              break;
            case 1: //Slip
              localNearMissSeries[index] += 1;
              localIncidentSummary.nearmiss_count += 1;
              incident.detected_by &&
              incident.detected_by.toLowerCase() === "wearable"
                ? (localIncidentSummary.nearmiss_wearable_count += 1)
                : (localIncidentSummary.nearmiss_cctv_count += 1);
              break;
            case 4: //Trip
              localNearMissSeries[index] += 1;
              localIncidentSummary.nearmiss_count += 1;
              incident.detected_by &&
              incident.detected_by.toLowerCase() === "wearable"
                ? (localIncidentSummary.nearmiss_wearable_count += 1)
                : (localIncidentSummary.nearmiss_cctv_count += 1);
              break;
            case 3: //Hazard
              localHazardSeries[index] += 1;
              localIncidentSummary.hazard_count += 1;
              incident.detected_by &&
              incident.detected_by.toLowerCase() === "wearable"
                ? (localIncidentSummary.hazard_wearable_count += 1)
                : (localIncidentSummary.hazard_cctv_count += 1);
              break;
            case 5: //Low HR
            case 6: //High HR
              localHRSeries[index] += 1;
              localIncidentSummary.hr_alert_count += 1;
              incident.detected_by &&
              incident.detected_by.toLowerCase() === "wearable"
                ? (localIncidentSummary.hr_alert_wearable_count += 1)
                : (localIncidentSummary.hr_alert_cctv_count += 1);
              break;
          }
        });
        setAllIncidentsSeries(localAllIncidentSeries);
        setFallSeries(localFallSeries);
        setNearMissSeries(localNearMissSeries);
        setHazardSeries(localHazardSeries);
        setHrSeries(localHRSeries);
        setIncidentSummary(localIncidentSummary);
      });
    axios
      .get("api/v2.0/incident/yoy?building_id=" + buildingId)
      .then((result) => {
        if (result && result.data) {
          setIncidentsYOY(result.data);
        }
      });

    if (selectedDate) {
      axios
        .get(
          "api/v2.0/incident/hour?year=" +
            selectedDate.getFullYear() +
            "&building_id=" +
            buildingId
        )
        .then((result) => {
          if (result && result.data) {
            setIncidentsByHour(result.data);
          }
        });
    }

    axios
      .get(
        "api/v2.0/incident/time_to_resolve?incident_dt=" +
          yearSelected +
          "-01-1&incident_end_dt=" +
          yearSelected +
          "-12-31" +
          "&building_id=" +
          buildingId
      )
      .then((res) => {
        let localTimeSummary: TimeSummary = res.data;
        localTimeSummary.top_hazard = "Unknown";
        if (
          localTimeSummary.spill_count === 0 &&
          localTimeSummary.object_count === 0
        ) {
          localTimeSummary.top_hazard = "Unknown";
        } else if (
          localTimeSummary.spill_count >= localTimeSummary.object_count
        ) {
          localTimeSummary.top_hazard = "Spills";
        } else {
          localTimeSummary.top_hazard = "Objects";
        }
        setTimeSummary(localTimeSummary);
      });
    }
  }, [selectedDate, buildingId]);

  //Getting Cameras
  useEffect(() => {
    if (cameras && cameras.length) {
      let locations: LocationModel[] = cameras.map((x) => {
        return {
          id: x.camera_id,
          name: x.camera_name,
          hasIncidents: false,
          fall_count: 0,
          nearmiss_count: 0,
          hazard_count: 0,
          total_incidents: 0,
        };
      });
      locations.push({
        id: -1,
        name: "Others",
        hasIncidents: false,
        fall_count: 0,
        nearmiss_count: 0,
        hazard_count: 0,
        total_incidents: 0,
      });
      setLocations(locations);
    }
  }, [cameras]);
  //Location Bar Chart filling up
  useEffect(() => {
    if (incidents) {
      let localLocations = locations;
      localLocations.forEach((x) => {
        x.hasIncidents = false;
        x.fall_count = 0;
        x.nearmiss_count = 0;
        x.hazard_count = 0;
        x.hr_alerts_count = 0;
        incidents
          .filter(
            (a) =>
              (a.detected_by_device_id === 2 && x.id === a.camera_id) ||
              (a.detected_by_device_id === 1 && x.id === -1)
          )

          .reduce((prev, curr) => {
            prev.hasIncidents = true;
            switch (curr.incident_type_id) {
              case 2: //Fall
                prev.fall_count = (prev.fall_count ? prev.fall_count : 0) + 1;
                prev.total_incidents += 1;
                break;
              case 4: //Trip
              case 1: //Slip
                prev.nearmiss_count =
                  (prev.nearmiss_count ? prev.nearmiss_count : 0) + 1;
                prev.total_incidents += 1;
                break;
              case 3: //Hazard
                prev.hazard_count =
                  (prev.hazard_count ? prev.hazard_count : 0) + 1;
                prev.total_incidents += 1;
                break;
              case 5: //Low HR
              case 6: //High HR
                prev.hr_alerts_count =
                  (prev.hr_alerts_count ? prev.hr_alerts_count : 0) + 1;
                prev.total_incidents += 1;
                break;
            }

            return prev;
          }, x);
      });
      localLocations.sort((a: LocationModel, b: LocationModel) => {
        return b.total_incidents - a.total_incidents;
      });
      let barLocationNearMissData: any[] = [];
      let barLocationFallData: any[] = [];
      let barLocationHazardData: any[] = [];
      let barHrAlertData: any[] = [];
      localLocations.forEach((loc) => {
        barLocationNearMissData.push(loc.nearmiss_count);
        barLocationFallData.push(loc.fall_count);
        barLocationHazardData.push({
          value: loc.hazard_count,
          note:
            "" +
            ((loc.nearmiss_count ? loc.nearmiss_count : 0) +
              (loc.fall_count ? loc.fall_count : 0) +
              (loc.hazard_count ? loc.hazard_count : 0)),
        });
        barHrAlertData.push(loc.hr_alerts_count ? loc.hr_alerts_count : 0);
      });
      setLocations(localLocations);
      let locationCategories = localLocations
        .map((location) => location.name)
        .slice(0, TOP_LOCATION_MAX_CATEGORIES);
      setBarLocationCategories(locationCategories);
      setBarLocationData([
        barLocationNearMissData.slice(0, TOP_LOCATION_MAX_CATEGORIES),
        barLocationFallData.slice(0, TOP_LOCATION_MAX_CATEGORIES),
        barLocationHazardData.slice(0, TOP_LOCATION_MAX_CATEGORIES),
        barHrAlertData.slice(0, TOP_LOCATION_MAX_CATEGORIES),
      ]);
    }
  }, [incidents, locations]);

  const handleDateChange = (date: MaterialUiPickersDate) => {
    setSelectedDate(date);
  };
  useEffect(() => {
    if (selectedId) {
      setReportModalMode("view");
      return;
    }
  }, [selectedId]);
  //Top Staff
  useEffect(() => {
    const userSummary: { [name: string]: UserSummary } = {};
    axios.get("api/v2.0/user?building_id=" + buildingId).then((res) => {
      const userList: UserModel[] = res.data;
      userList.forEach((user) => {
        userSummary[user.first_name.toLowerCase()] = {
          name: user.first_name,
          fallCount: 0,
          nearMissCount: 0,
          hrAlertCount: 0,
        };
      });
      incidents.forEach((incident) => {
        if (incident.victim_name?.toLowerCase() in userSummary) {
          switch (incident.incident_type_id) {
            case 2: //Fall
              userSummary[incident.victim_name.toLowerCase()].fallCount += 1;
              break;
            case 1: //Slip
              userSummary[
                incident.victim_name.toLowerCase()
              ].nearMissCount += 1;
              break;
            case 4: //Trip
              userSummary[
                incident.victim_name.toLowerCase()
              ].nearMissCount += 1;
              break;
            case 3: //Hazard
              break;
            case 5: //Low HR
            case 6: //High HR
              userSummary[incident.victim_name.toLowerCase()].hrAlertCount += 1;
              break;
          }
        }
      });
      let sortedUserSummary = Object.values(userSummary)
        .map<[UserSummary, number]>((summary) => [
          summary,
          summary.fallCount + summary.nearMissCount + summary.hrAlertCount,
        ])
        .sort((pair1, pair2) => pair2[1] - pair1[1])
        .map((pair) => pair[0]);
      let topUserSummary = sortedUserSummary.slice(0, TOP_STAFF_MAX_CATEGORIES);
      let localStaffBarChartData: ChartModel = {
        categories: [],
        nearMissData: [],
        fallData: [],
        hazardData: [],
        hrAlertData: [],
      };
      for (let summary of topUserSummary) {
        localStaffBarChartData.categories.push(summary.name);
        localStaffBarChartData.nearMissData.push(summary.nearMissCount);
        localStaffBarChartData.fallData.push({
          value: summary.fallCount,
          note: summary.nearMissCount + summary.fallCount,
        });
        localStaffBarChartData.hrAlertData.push(summary.hrAlertCount ?? 0);
      }
      setStaffBarChartData(localStaffBarChartData);
    });
  }, [incidents]);

  const donutIncidentsCenter = () =>
    allIncidentsSeries.reduce((x: number, y: number) => x + y, 0) !== 0 ? (
      <span>
        <h4>{allIncidentsSeries.reduce((x: number, y: number) => x + y, 0)}</h4>
      </span>
    ) : (
      <p>No Incidents</p>
    );
  const donutIncidentsLabel = (e: any) => e.value;
  const donuctIncidentsData = [
    { type: "Fall", value: incidentSummary.fall_count, color: FALL_COLOR },
    {
      type: "Near Miss",
      value: incidentSummary.nearmiss_count,
      color: NEAR_MISS_COLOR,
    },
    {
      type: "Hazard",
      value: incidentSummary.hazard_count,
      color: HAZARD_COLOR,
    },
    {
      type: "HR Alert",
      value: incidentSummary.hr_alert_count,
      color: HR_ALERT_COLOR,
    },
  ];
  //Column Chart for Detection
  const colDetectionCategores = ["V-Band", "CCTV"];
  const colDetectionNearMissData = [
    { value: incidentSummary.nearmiss_wearable_count },
    { value: incidentSummary.nearmiss_cctv_count },
  ];
  const colDetectionFallData = [
    {
      value: incidentSummary.fall_wearable_count,
      note:
        "" +
        (incidentSummary.fall_wearable_count +
          incidentSummary.nearmiss_wearable_count +
          incidentSummary.hr_alert_wearable_count),
    },
    {
      value: incidentSummary.fall_cctv_count,
      note:
        "" +
        (incidentSummary.fall_cctv_count +
          incidentSummary.nearmiss_cctv_count +
          incidentSummary.hr_alert_cctv_count),
    },
  ];
  const colHrAlertData = [
    {
      value: incidentSummary.hr_alert_wearable_count,
    },
    {
      value: incidentSummary.hr_alert_cctv_count,
    },
  ];
  return (
    <div className="YearlyDashboard">
      <div className="first_row">
        <div className="dashboard-type-selector">
          <TimePeriods date={urlDate} buildingId={buildingId}></TimePeriods>
        </div>
        <div className="filters">
          <div className="monthSelect">
            <span>
              <p>Select Year</p>
            </span>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                views={["year"]}
                value={selectedDate}
                onChange={handleDateChange}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className="building-select">
            <label>Location</label>
            <Select
              value={buildingId}
              onChange={(e) => {
                setBuildingId(e.target.value as number);
              }}
            >
              <MenuItem key={0} value={0}>
                All Locations
              </MenuItem>
              {buildings.map((building) => (
                <MenuItem
                  key={building.building_id}
                  value={building.building_id}
                >
                  {building.name}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      </div>
      <div className="second_row">
        <div className="first_column">
          <div className="donut_incidents">
            <Chart
              donutCenterRender={donutIncidentsCenter}
              style={{ height: 250 }}
            >
              <ChartTitle text="Total Incidents" color={TEXT_COLOR} />
              <ChartArea margin={10} background={CHART_COLOR} />
              <ChartLegend labels={{ color: TEXT_COLOR }}>
                <ChartLegendInactiveItems labels={{ color: TEXT_COLOR }} />
              </ChartLegend>
              <ChartTooltip />
              <ChartSeries>
                <ChartSeriesItem
                  type="donut"
                  data={donuctIncidentsData}
                  categoryField="type"
                  field="value"
                >
                  <ChartSeriesLabels
                    color={TEXT_COLOR}
                    background="none"
                    content={donutIncidentsLabel}
                  />
                  <ChartSeriesItemTooltip format="{0}" />
                </ChartSeriesItem>
              </ChartSeries>
            </Chart>
          </div>
          <div className="column_detection">
            <Chart style={{ height: 350 }}>
              <ChartTitle text="Incident Detection" color={TEXT_COLOR} />
              <ChartLegend position="bottom" labels={{ color: TEXT_COLOR }}>
                <ChartLegendInactiveItems labels={{ color: TEXT_COLOR }} />
              </ChartLegend>
              <ChartArea margin={30} background={CHART_COLOR} />
              <ChartTooltip />
              <ChartCategoryAxis>
                <ChartCategoryAxisItem
                  categories={colDetectionCategores}
                  color={TEXT_COLOR}
                ></ChartCategoryAxisItem>
              </ChartCategoryAxis>
              <ChartValueAxis>
                <ChartValueAxisItem color={TEXT_COLOR} />
              </ChartValueAxis>
              <ChartSeries>
                <ChartSeriesItem
                  name="Near Miss"
                  type="column"
                  stack={true}
                  data={colDetectionNearMissData}
                  field="value"
                  color={NEAR_MISS_COLOR}
                >
                  <ChartSeriesItemTooltip format="Near Miss: {0}" />
                </ChartSeriesItem>

                <ChartSeriesItem
                  name="HR Alerts"
                  type="column"
                  stack={true}
                  data={colHrAlertData}
                  field="value"
                  noteTextField="note"
                  notes={{
                    position: "top",
                    line: { color: TEXT_COLOR },
                    icon: { type: "circle", background: TEXT_COLOR },
                    label: { position: "outside", color: TEXT_COLOR },
                  }}
                  color={HR_ALERT_COLOR}
                >
                  <ChartSeriesItemTooltip format="HR Alerts: {0}" />
                </ChartSeriesItem>
                <ChartSeriesItem
                  name="Fall"
                  type="column"
                  stack={true}
                  data={colDetectionFallData}
                  field="value"
                  noteTextField="note"
                  notes={{
                    position: "top",
                    line: { color: TEXT_COLOR },
                    icon: { type: "circle", background: TEXT_COLOR },
                    label: { position: "outside", color: TEXT_COLOR },
                  }}
                  color={FALL_COLOR}
                >
                  <ChartSeriesItemTooltip format="Fall: {0}" />
                </ChartSeriesItem>
              </ChartSeries>
            </Chart>
          </div>
          <div className="bar_locations">
            <Chart>
              <ChartTitle text="Top Locations" color={TEXT_COLOR} />
              <ChartLegend position="bottom" labels={{ color: TEXT_COLOR }}>
                <ChartLegendInactiveItems labels={{ color: TEXT_COLOR }} />
              </ChartLegend>
              <ChartArea margin={30} background={CHART_COLOR} />
              <ChartTooltip />
              <ChartCategoryAxis>
                <ChartCategoryAxisItem
                  categories={barLocationCategories}
                  color={TEXT_COLOR}
                ></ChartCategoryAxisItem>
              </ChartCategoryAxis>
              <ChartValueAxis>
                <ChartValueAxisItem color={TEXT_COLOR} />
              </ChartValueAxis>
              <ChartSeries>
                <ChartSeriesItem
                  name="Near Miss"
                  type="bar"
                  stack={true}
                  data={barLocationData[0]}
                  field="value"
                  color={NEAR_MISS_COLOR}
                >
                  <ChartSeriesItemTooltip format="Near Miss: {0}" />
                </ChartSeriesItem>
                <ChartSeriesItem
                  name="Fall"
                  type="bar"
                  stack={true}
                  data={barLocationData[1]}
                  field="value"
                  noteTextField="note"
                  notes={{
                    position: "right",
                    line: { color: TEXT_COLOR },
                    icon: { type: "circle", background: TEXT_COLOR },
                    label: { position: "outside", color: TEXT_COLOR },
                  }}
                  color={FALL_COLOR}
                >
                  <ChartSeriesItemTooltip format="Fall: {0}" />
                </ChartSeriesItem>
                <ChartSeriesItem
                  name="Hazard"
                  type="bar"
                  stack={true}
                  data={barLocationData[2]}
                  field="value"
                  noteTextField="note"
                  notes={{
                    position: "right",
                    line: { color: TEXT_COLOR },
                    icon: { type: "circle", background: TEXT_COLOR },
                    label: { position: "outside", color: TEXT_COLOR },
                  }}
                  color={HAZARD_COLOR}
                >
                  <ChartSeriesItemTooltip format="Hazard: {0}" />
                </ChartSeriesItem>
                <ChartSeriesItem
                  name="HR Alerts"
                  type="bar"
                  stack={true}
                  data={barLocationData[3]}
                  field="value"
                  noteTextField="note"
                  notes={{
                    position: "right",
                    line: { color: TEXT_COLOR },
                    icon: { type: "circle", background: TEXT_COLOR },
                    label: { position: "outside", color: TEXT_COLOR },
                  }}
                  color={HR_ALERT_COLOR}
                >
                  <ChartSeriesItemTooltip format="HR Alerts: {0}" />
                </ChartSeriesItem>
              </ChartSeries>
            </Chart>
          </div>
          <div className="bar_staff">
            <Chart>
              <ChartTitle text="Top Staff" color={TEXT_COLOR} />
              <ChartLegend position="bottom" labels={{ color: TEXT_COLOR }}>
                <ChartLegendInactiveItems labels={{ color: TEXT_COLOR }} />
              </ChartLegend>
              <ChartArea margin={30} background={CHART_COLOR} />
              <ChartTooltip />
              <ChartCategoryAxis>
                <ChartCategoryAxisItem
                  categories={staffBarChartData.categories}
                  color={TEXT_COLOR}
                ></ChartCategoryAxisItem>
              </ChartCategoryAxis>
              <ChartValueAxis>
                <ChartValueAxisItem color={TEXT_COLOR} />
              </ChartValueAxis>
              <ChartSeries>
                <ChartSeriesItem
                  name="Near Miss"
                  type="bar"
                  stack={true}
                  data={staffBarChartData.nearMissData}
                  field="value"
                  color={NEAR_MISS_COLOR}
                >
                  <ChartSeriesItemTooltip format="Near Miss: {0}" />
                </ChartSeriesItem>
                <ChartSeriesItem
                  name="Fall"
                  type="bar"
                  stack={true}
                  data={staffBarChartData.fallData}
                  field="value"
                  noteTextField="note"
                  notes={{
                    position: "right",
                    line: { color: TEXT_COLOR },
                    icon: { type: "circle", background: TEXT_COLOR },
                    label: { position: "outside", color: TEXT_COLOR },
                  }}
                  color={FALL_COLOR}
                >
                  <ChartSeriesItemTooltip format="Fall: {0}" />
                </ChartSeriesItem>
                <ChartSeriesItem
                  name="HR Alerts"
                  type="bar"
                  stack={true}
                  data={staffBarChartData.hrAlertData}
                  field="value"
                  noteTextField="note"
                  notes={{
                    position: "right",
                    line: { color: TEXT_COLOR },
                    icon: { type: "circle", background: TEXT_COLOR },
                    label: { position: "outside", color: TEXT_COLOR },
                  }}
                  color={HR_ALERT_COLOR}
                >
                  <ChartSeriesItemTooltip format="HR Alerts: {0}" />
                </ChartSeriesItem>
              </ChartSeries>
            </Chart>
          </div>
        </div>
        <div className="second_column">
          <div className="stats_row">
            <div className="stats_container">
              <div className="title">Top Hazard</div>
              <div className="icon">
                <SvgIcon
                  icon={exclamationCircleIcon}
                  size="large"
                  style={{ color: HAZARD_COLOR, padding: 8 }}
                />
              </div>
              <div className="value">{timeSummary.top_hazard}</div>
            </div>
            <div className="stats_container">
              <div className="title">
                <p>Average time </p>
                <p>to Resolve</p>
              </div>
              <div className="icon">
                <SvgIcon
                  icon={clockIcon}
                  size="large"
                  style={{ color: NEAR_MISS_COLOR, padding: 8 }}
                />
              </div>
              <div className="value">
                {(timeSummary.avg_time_to_resolve / 60).toFixed(0) + " mins"}
              </div>
            </div>
            <div className="stats_container" style={{ margin: 0 }}>
              <div className="title">
                <p>Longest time </p>
                <p>to Resolve</p>
              </div>
              <div className="icon">
                <SvgIcon
                  icon={clockIcon}
                  size="large"
                  style={{ color: FALL_COLOR, padding: 8 }}
                />
              </div>
              <div className="value">
                {(timeSummary.max_time_to_resolve / 60).toFixed(0) + " mins"}
              </div>
            </div>
          </div>
          <div className="line_chart">
            <Chart>
              <ChartArea margin={30} background={CHART_COLOR} />
              <ChartLegend
                position="bottom"
                orientation="horizontal"
                labels={{ color: TEXT_COLOR }}
              >
                <ChartLegendInactiveItems labels={{ color: TEXT_COLOR }} />
              </ChartLegend>
              <ChartTooltip format="STF Incidents: {0}" />
              <ChartTitle
                text={"STF Warnings in " + selectedDate?.getFullYear()}
                color={TEXT_COLOR}
              />
              <ChartCategoryAxis>
                <ChartCategoryAxisItem
                  min={0}
                  title={{ text: "Month" }}
                  categories={monthNames}
                  color={TEXT_COLOR}
                />
              </ChartCategoryAxis>
              <ChartValueAxis>
                <ChartValueAxisItem color={TEXT_COLOR} />
              </ChartValueAxis>
              <ChartSeries>
                <ChartSeriesItem
                  name="All Incidents"
                  type="line"
                  data={allIncidentsSeries}
                  color={LINE_COLOR}
                  visible={false}
                >
                  <ChartSeriesItemTooltip format="STF Incidents: {0}" />
                </ChartSeriesItem>
                <ChartSeriesItem
                  name="Fall"
                  type="line"
                  data={fallSeries}
                  color={FALL_COLOR}
                >
                  <ChartSeriesItemTooltip format="Falls: {0}" />
                </ChartSeriesItem>
                <ChartSeriesItem
                  name="Near Miss"
                  type="line"
                  data={nearMissSeries}
                  color={NEAR_MISS_COLOR}
                >
                  <ChartSeriesItemTooltip format="Near Misses: {0}" />
                </ChartSeriesItem>
                <ChartSeriesItem
                  name="Hazard"
                  type="line"
                  data={hazardSeries}
                  color={HAZARD_COLOR}
                >
                  <ChartSeriesItemTooltip format="Hazards: {0}" />
                </ChartSeriesItem>
                <ChartSeriesItem
                  name="HR Alerts"
                  type="line"
                  data={hrSeries}
                  color={HR_ALERT_COLOR}
                >
                  <ChartSeriesItemTooltip format="HR Alerts: {0}" />
                </ChartSeriesItem>
              </ChartSeries>
            </Chart>
          </div>
          <div className="col_timeofday">
            <IncidentByHourChart data={incidentsByHour}></IncidentByHourChart>
          </div>
          <div className="line_yoy">
            <YOYChart data={incidentsYOY}></YOYChart>
          </div>
          <div className="incident_grid">
            <IncidentsGrid
              incidents={incidents}
              showDateColumn={true}
              onRowClick={(incident_id: number) => {
                setSelectedId(incident_id);
                setReportModalMode("view");
              }}
            ></IncidentsGrid>
          </div>
        </div>
      </div>
      <Modal
        isOpen={reportModalMode !== "none"}
        onRequestClose={() => setReportModalMode("none")}
        ariaHideApp={false}
        style={{
          content: {
            borderRadius: "20px",
            padding: "15px",
            left: "20px",
            right: "20px",
            top: "20px",
            bottom: "20px",
            backgroundColor: BACKGROUND_COLOR,
          },
          overlay: { zIndex: 2 },
        }}
      >
        <IncidentReport
          id={selectedId}
          mode={reportModalMode}
          setMode={setReportModalMode}
          hazardTypes={hazardTypes}
          statusTypes={statusTypes}
          incidentTypes={incidentTypes}
          users={users}
        ></IncidentReport>
      </Modal>
    </div>
  );
}
