import React from "react";
import NoDataIcon from "../../resources/icons/dailyOps/empty.svg";

export default function NoData({ padding = 100 }: { padding?: number }) {
  return (
    <div
      style={{
        display: "grid",
        justifyContent: "center",
        verticalAlign: "middle",
        padding: padding ?? 100,
      }}
    >
      <img style={{ width: 50 }} src={NoDataIcon} alt="no data"></img>
      No Data
    </div>
  );
}
