import React from "react";

import "./LocationForm.scss";

import { MenuItem, Select, TextField } from "@material-ui/core";
import { BuildingModel } from "../../../models/BuildingModel";
import { LevelModel } from "../../../models/LevelModel";
import { UnitModel } from "../../../models/UnitModel";
import { RoomModel } from "../../../models/RoomModel";

interface Props {
  origin: string;

  buildings: BuildingModel[];
  buildingId: number;
  setBuildingId: (
    building_id: number | ((prevState: number) => number)
  ) => void;

  levels?: LevelModel[];
  levelId?: number;
  setLevelId?: (level_Id: number | ((prevState: number) => number)) => void;

  units?: UnitModel[];
  unitId?: number;
  setUnitId?: (unit_id: number | ((prevState: number) => number)) => void;

  rooms?: RoomModel[];
  roomId?: number;
  setRoomId?: (room_id: number | ((prevState: number) => number)) => void;

  count: number;
  setCount: (count: number | ((prevState: number) => number)) => void;
}

export default function LocationForm(props: Props) {
  const handleBuildingChange = (event: { target: { value: any } }) => {
    props.setBuildingId(event.target.value);
  };

  const handleLevelChange = (event: { target: { value: any } }) => {
    props.setLevelId && props.setLevelId(event.target.value);
  };

  const handleUnitChange = (event: { target: { value: any } }) => {
    props.setUnitId && props.setUnitId(event.target.value);
  };

  const handleRoomChange = (event: { target: { value: any } }) => {
    props.setRoomId && props.setRoomId(event.target.value);
  };

  const handleCountChange = (event: { target: { value: any } }) => {
    props.setCount(event.target.value);
  };
  return (
    <div className="LocationForm">
      <div className="parent">
        <h4 className="required">Select Building</h4>
        <Select
          fullWidth
          value={props.buildingId}
          onChange={handleBuildingChange}
        >
          {props.buildings.map((building) => (
            <MenuItem key={building.building_id} value={building.building_id}>
              {building.name}
            </MenuItem>
          ))}
        </Select>
      </div>

      {props.levelId !== undefined ? (
        <div className="parent">
          <h4 className="required">Select Level</h4>
          <Select
            fullWidth
            value={props.levelId}
            onChange={handleLevelChange}
            disabled={!props.buildingId}
          >
            {props.levels &&
              props.levels
                .filter((level) => level.building_id === props.buildingId)
                .map((level) => (
                  <MenuItem key={level.level_id} value={level.level_id}>
                    {level.name}
                  </MenuItem>
                ))}
          </Select>
        </div>
      ) : null}

      {props.unitId !== undefined ? (
        <div className="parent">
          <h4 className="required">Select Unit</h4>
          <Select
            fullWidth
            value={props.unitId}
            onChange={handleUnitChange}
            disabled={!props.levelId}
          >
            {props.units &&
              props.units
                .filter(
                  (unit) => !props.levelId || unit.level_id === props.levelId
                )
                .map((unit) => (
                  <MenuItem key={unit.unit_id} value={unit.unit_id}>
                    {unit.name}
                  </MenuItem>
                ))}
          </Select>
        </div>
      ) : null}

      {props.roomId !== undefined ? (
        <div className="parent">
          <h4 className="required">Select Room</h4>
          <Select
            fullWidth
            value={props.roomId}
            onChange={handleRoomChange}
            disabled={!props.unitId}
          >
            {props.rooms &&
              props.rooms
                .filter(
                  (room) => !props.unitId || room.unit_id === props.unitId
                )
                .map((room) => (
                  <MenuItem key={room.room_id} value={room.room_id}>
                    {room.name}
                  </MenuItem>
                ))}
          </Select>
        </div>
      ) : null}

      <div className="count">
        <h4 className="required">Number of {props.origin}</h4>
        <TextField
          fullWidth
          type="number"
          inputProps={{ min: 1, max: 10 } as any}
          value={props.count}
          onChange={handleCountChange}
        />
      </div>
    </div>
  );
}
