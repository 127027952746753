import React from 'react';
import './TeamAssignmentForm.scss';
import { MenuItem, Select } from "@material-ui/core";
import { UserWearable } from "../../../models/UserWearable";
import { TeamModel } from "../../../models/TeamModel";
import { TeamAssignmentModel } from '../../../models/TeamAssignmentModel';

interface Props {
  TeamAssignment: TeamAssignmentModel;
  users: UserWearable[];
  teams: TeamModel[];
  setWearableAssignment: React.Dispatch<React.SetStateAction<TeamAssignmentModel>>;
}
const TeamAssignmentForm = (props: Props) => {

  const handleUserChange = (event: { target: { value: any } }) => {
    props.setWearableAssignment((prevState) => ({
      ...prevState,
      user_id: event.target.value,
    }));
  };

  const handleTeamChange = (event: { target: { value: any } }) => {
    props.setWearableAssignment((prevState) => ({
      ...prevState,
      team_id: event.target.value,
    }));
  };

  return (
    <div className="TeamAssignmentForm">
      <div className="user">
        <h4 className="required">Select User</h4>
        <Select
          fullWidth
          value={props.TeamAssignment.user_id}
          onChange={handleUserChange}
        >
          {props.users.map((user) => (
            <MenuItem key={user.user_id} value={user.user_id}>
              {user.email}
            </MenuItem>
          ))}
        </Select>
      </div>

      <div className="team">
        <h4 className="required">Select Team</h4>
        <Select
          fullWidth
          value={props.TeamAssignment.team_id}
          onChange={handleTeamChange}
        >
          {props.teams.map((team) => (
            <MenuItem key={team.team_id} value={team.team_id}>
              {team.team_name}
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default TeamAssignmentForm;
