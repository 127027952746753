export const REGEXP_USER_ID = /^[a-zA-Z][a-zA-Z0-9_]{8,20}$/;
export const REGEXP_USER_PASSWORD =
  /^(.{0,7}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$/;

export const REGEXP_NAME = /^[a-zA-Z ]{3,20}$/;
export const REGEXP_EMAIL = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

export const REGEXP_NUM = /^(\+\d{1,3}([ ]?))?\d{7,12}$/;

export const REGEXP_MACADDR = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
