import React, { useEffect, useState } from "react";
import axios from "axios";
import { IncidentSummary } from "../../models/IncidentSummary";
import { LocationModel } from "../../models/LocationModel";
import "./IncidentList.scss";
import { Incident } from "../../models/Incident";
import { downLoadFile, JSONToCSV } from "../../services/commonServices";
import { CameraModel } from "../../models/CameraModel";
import Summary from "../../components/Summary/Summary";
import IncidentsGrid from "../../components/IncidentsGrid/IncidentsGrid";
import { circleIcon } from "@progress/kendo-svg-icons";
import { SvgIcon } from "@progress/kendo-react-common";
import { GREEN_COLOR, RED_COLOR } from "../../constants/constants";

interface Props {
  buildingId?: number;
  onSelectIncident: (incident_id: number) => void;
  onClickNewReportButton: (report_modal_state: boolean) => void;
  showDateColumn: boolean;
  incidents: Incident[];
}

const IncidentList: React.FC<Props> = ({
  buildingId,
  onSelectIncident,
  onClickNewReportButton,
  showDateColumn,
  incidents,
}) => {
  const [cameras, setCameras] = useState<CameraModel[]>([]);
  const [locations, setLocations] = useState<LocationModel[]>([]);
  const [incidentSummary, setIncidentSummary] = useState<IncidentSummary>({
    fall_count: 0,
    fall_wearable_count: 0,
    fall_cctv_count: 0,
    nearmiss_count: 0,
    nearmiss_wearable_count: 0,
    nearmiss_cctv_count: 0,
    hazard_count: 0,
    hazard_wearable_count: 0,
    hazard_cctv_count: 0,
    hr_alert_count: 0,
    hr_alert_wearable_count: 0,
    hr_alert_cctv_count: 0,
  });
  const [incidentsToDisplay, setIncidentsToDisplay] = useState<Incident[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<LocationModel>();
  const [selectedIncidentType, setSelectedIncidentType] = useState("");

  useEffect(() => {
    axios.get("api/v2.0/camera").then((res) => {
      if (res.data) setCameras(res.data);
    });
  }, []);

  useEffect(() => {
    let locations: LocationModel[] = [];
    if (cameras && cameras.length) {
      locations = cameras.map((x) => {
        return {
          id: x.camera_id,
          name: x.camera_name,
          room_name: x.room_name,
          unit_name: x.unit_name,
          level_name: x.level_name,
          building_name: x.building_name,
          building_id: x.building_id,
          hasIncidents: false,
          fall_count: 0,
          nearmiss_count: 0,
          hazard_count: 0,
          total_incidents: 0,
        };
      });
    }
    locations.push({
      id: -1,
      name: "Wearable",
      hasIncidents: false,
      fall_count: 0,
      nearmiss_count: 0,
      hazard_count: 0,
      total_incidents: 0,
    });
    setLocations(locations);
  }, [cameras]);

  useEffect(() => {
    if (incidents) {
      setLocations((locations) => {
        locations.forEach((x) => {
          x.hasIncidents = false;
          x.fall_count = 0;
          x.nearmiss_count = 0;
          x.hazard_count = 0;

          incidents
            .filter(
              (a) =>
                (a.detected_by_device_id === 2 && x.id === a.camera_id) ||
                (a.detected_by_device_id === 1 && x.id === -1)
            )

            .reduce((prev, curr) => {
              prev.hasIncidents = true;
              switch (curr.incident_type_id) {
                case 2: //Fall
                  prev.fall_count = (prev.fall_count ? prev.fall_count : 0) + 1;
                  prev.total_incidents += 1;
                  break;
                case 4: //Trip
                case 1: //Slip
                  prev.nearmiss_count =
                    (prev.nearmiss_count ? prev.nearmiss_count : 0) + 1;
                  prev.total_incidents += 1;
                  break;
                case 3: //Hazard
                  prev.hazard_count =
                    (prev.hazard_count ? prev.hazard_count : 0) + 1;
                  prev.total_incidents += 1;
                  break;
              }

              return prev;
            }, x);
        });
        return locations.sort((a: LocationModel, b: LocationModel) => {
          return b.total_incidents - a.total_incidents;
        });
      });
    }
  }, [incidents]);

  useEffect(() => {
    if (incidents) {
      let tincidentSummary = {
        fall_cctv_count: 0,
        fall_count: 0,
        fall_wearable_count: 0,
        hazard_cctv_count: 0,
        hazard_count: 0,
        hazard_wearable_count: 0,
        nearmiss_cctv_count: 0,
        nearmiss_count: 0,
        nearmiss_wearable_count: 0,
        hr_alert_count: 0,
        hr_alert_wearable_count: 0,
        hr_alert_cctv_count: 0,
      };

      incidents.forEach((x) => {
        switch (x.incident_type_id) {
          case 2: //Fall
            tincidentSummary.fall_count += 1;
            x.detected_by && x.detected_by.toLowerCase() === "wearable"
              ? (tincidentSummary.fall_wearable_count += 1)
              : (tincidentSummary.fall_cctv_count += 1);
            break;
          case 1: //Slip
          case 4: //Trip
            tincidentSummary.nearmiss_count += 1;
            x.detected_by && x.detected_by.toLowerCase() === "wearable"
              ? (tincidentSummary.nearmiss_wearable_count += 1)
              : (tincidentSummary.nearmiss_cctv_count += 1);
            break;
          case 3: //Hazard
            tincidentSummary.hazard_count += 1;
            x.detected_by && x.detected_by.toLowerCase() === "wearable"
              ? (tincidentSummary.hazard_wearable_count += 1)
              : (tincidentSummary.hazard_cctv_count += 1);
            break;
          case 5: //Low HR
          case 6: //High HR
            tincidentSummary.hr_alert_count += 1;
            x.detected_by && x.detected_by.toLowerCase() === "wearable"
              ? (tincidentSummary.hr_alert_wearable_count += 1)
              : (tincidentSummary.hr_alert_cctv_count += 1);
            break;
          default:
            break;
        }
      });
      setIncidentSummary(tincidentSummary);
    }
  }, [incidents, selectedLocation]);

  useEffect(() => {
    if (incidents) {
      setIncidentsToDisplay(
        incidents
          .filter((x) =>
            selectedIncidentType
              ? x.incident_type &&
                x.incident_type.toLowerCase().indexOf(selectedIncidentType) > -1
              : true
          )
          .filter((x) =>
            selectedLocation && selectedLocation.id
              ? (selectedLocation.id > 0 &&
                  x.camera_id === selectedLocation.id) ||
                (selectedLocation.id === -1 && x.detected_by_device_id === 1)
              : true
          )
      );
    }
  }, [incidents, selectedLocation, selectedIncidentType]);

  return (
    <div className="IncidentList">
      <div className="summary_row">
        <Summary
          name="Fall Warnings"
          value={incidentSummary.fall_count}
          isSelected={selectedIncidentType === "fall"}
          leftValue={`Wearables: ${incidentSummary.fall_wearable_count}`}
          rightValue={`CCTV: ${incidentSummary.fall_cctv_count}`}
          onSelect={() =>
            setSelectedIncidentType(
              selectedIncidentType === "fall" ? "" : "fall"
            )
          }
          valueClass={"falls"}
        ></Summary>

        <Summary
          name="Near Miss"
          value={incidentSummary.nearmiss_count}
          isSelected={selectedIncidentType === "near miss"}
          leftValue={`Wearables: ${incidentSummary.nearmiss_wearable_count}`}
          rightValue={`CCTV: ${incidentSummary.nearmiss_cctv_count}`}
          onSelect={() =>
            setSelectedIncidentType(
              selectedIncidentType === "near miss" ? "" : "near miss"
            )
          }
          valueClass={"near-miss"}
        ></Summary>

        {/* <Summary
          name="Hazard"
          value={incidentSummary.hazard_count}
          isSelected={selectedIncidentType === "hazard"}
          leftValue={`Wearables: ${incidentSummary.hazard_wearable_count}`}
          rightValue={`CCTV: ${incidentSummary.hazard_cctv_count}`}
          onSelect={() =>
            setSelectedIncidentType(
              selectedIncidentType === "hazard" ? "" : "hazard"
            )
          }
          valueClass={"hazard"}
        ></Summary> */}

        <Summary
          name="Heart Rate"
          value={incidentSummary.hr_alert_count}
          isSelected={selectedIncidentType === "hr alert"}
          leftValue={`Wearables: ${incidentSummary.hr_alert_wearable_count}`}
          rightValue={`CCTV: ${incidentSummary.hr_alert_cctv_count}`}
          onSelect={() =>
            setSelectedIncidentType(
              selectedIncidentType === "hr alert" ? "" : "hr alert"
            )
          }
          valueClass={"hr-alert"}
        ></Summary>
      </div>
      <div className="content_row">
        <div className="floating-report-button-container">
          <div className="title">Incident Reports</div>
          <button
            className="floating-report-button"
            onClick={() => onClickNewReportButton(true)}
          >
            + New
          </button>
        </div>
        <div className="floor_plan_title">Sensors</div>
        <div className="floor_plan">
          {locations &&
            locations
              .filter(
                (location) =>
                  !location.building_id || location.building_id === buildingId
              )
              .map((x) => (
                <div
                  className={`location ${
                    selectedLocation && x.id === selectedLocation.id
                      ? "active"
                      : ""
                  }`}
                  key={x.id}
                  onClick={(_) =>
                    setSelectedLocation(
                      selectedLocation && selectedLocation.id === x.id
                        ? undefined
                        : x
                    )
                  }
                >
                  {!x.hasIncidents ? (
                    <>
                      <div className="icon">
                        <SvgIcon
                          icon={circleIcon}
                          style={{
                            color: GREEN_COLOR,
                            width: "24px",
                            height: "24px",
                          }}
                        />
                      </div>
                      <div className="info">
                        <div className="title">
                          <p>{x.name}</p>
                        </div>
                        <div className="details">
                          {x.room_name ? <p>Room: {x.room_name}</p> : null}
                          {x.unit_name ? <p>Unit: {x.unit_name}</p> : null}
                          {x.level_name ? <p>Level: {x.level_name}</p> : null}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="icon">
                        <SvgIcon
                          icon={circleIcon}
                          style={{
                            color: RED_COLOR,
                            width: "24px",
                            height: "24px",
                          }}
                        />
                      </div>
                      <div className="info">
                        <div className="title">
                          <p>{x.name}</p>
                        </div>
                        <div className="details">
                          {x.room_name ? <p>Room: {x.room_name}</p> : null}
                          {x.unit_name ? <p>Unit: {x.unit_name}</p> : null}
                          {x.level_name ? <p>Level: {x.level_name}</p> : null}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ))}
        </div>
        <div className="v_line"></div>
        <div className="grid">
          <IncidentsGrid
            incidents={incidentsToDisplay}
            showDateColumn={showDateColumn}
            onRowClick={(incident_id) => onSelectIncident(incident_id)}
          ></IncidentsGrid>
          {incidents && incidents.length ? (
            <button
              className="download"
              onClick={(_) => {
                let csv = JSONToCSV(incidents);
                downLoadFile(csv, "STF_DailyReport.csv");
              }}
            >
              Download CSV
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default IncidentList;
