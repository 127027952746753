import React from "react";
import { Marker, Tooltip } from "react-leaflet";
import * as L from "leaflet";
import ReactDOMServer from "react-dom/server";

import alertIcon from "../../resources/icons/alert.svg";
import garyAlertIcon from "../../resources/icons/alert-gray.svg";

import "./IncidentMarker.scss";
import { Incident } from "../../models/Incident";
import { hhMMZZFormat } from "../../helpers/DateHelper";

const IncidentMarker = ({
  incidentDetails,
  incidentType,
  onClick,
}: {
  incidentDetails: Incident;
  incidentType: string;
  onClick: () => void;
}) => {
  console.log(incidentDetails);
  return (
    <Marker
      className={`IncidentMarker`}
      position={[incidentDetails.lat, incidentDetails.lng]}
      autoPan={true}
      icon={L.divIcon({
        className: "custom-icon",
        html: ReactDOMServer.renderToString(
          <img
            src={incidentDetails.status_id === 1 ? alertIcon : garyAlertIcon}
            style={{ width: "20px", height: "auto" }}
            alt="Incident"
          ></img>
        ),
      })}
      onclick={onClick}
    >
      <Tooltip interactive={true} permanent={false} offset={[-40, -50]}>
        <div className={`marker-tooltip ${incidentDetails.status_id === 1 ? '' : 'gray'}`}>
          <div className="incident-row">
            <div className="details">
              <div className="incident">{incidentType}</div>
              <div className="name">Name : {incidentDetails.victim_name}</div>
              <div className="date-row">
                Time :{" "}
                {incidentDetails.incident_dt
                  ? hhMMZZFormat(incidentDetails.incident_dt)
                  : "..."}
                {/* <img
                  className="next-img"
                  src={nextIcon}
                  alt="View Incident Details"
                ></img> */}
              </div>
            </div>
          </div>
        </div>
      </Tooltip>
    </Marker>
  );
};

export default IncidentMarker;
