import React, { useState, useEffect } from "react";
import "./DailyDashboard.scss";
import Modal from "react-modal";
import axios from "axios";
import IncidentReport from "../../components/IncidentReport/IncidentReport";
import { Incident } from "../../models/Incident";
import { HazardType } from "../../models/HazardType";
import { Status } from "../../models/Status";
import { IncidentType } from "../../models/IncidentType";

import IncidentListMobile from "../../subpages/IncidentListMobile/IncidentListMobile";
import IncidentList from "../../subpages/IncidentList/IncidentList";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { BACKGROUND_COLOR } from "../../constants/constants";
import { Select, MenuItem } from "@material-ui/core";
import { BuildingModel } from "../../models/BuildingModel";
import { UserModel } from "../../models/UserModel";
import { dateToString } from "../../services/commonServices";
import TimePeriods from "../../components/TimePeriods/TimePeriods";
import { formatDateObjUnix, getDateObj } from "../../helpers/DateHelper";
import { TeamModel } from "../../models/TeamModel";

export type ReportModalState = "none" | "add" | "view";

export default function DailyDashboard() {
  const [incidents, setIncidents] = useState<Incident[]>([]);
  const [reportModalMode, setReportModalMode] =
    useState<ReportModalState>("none");
  const [buildingId, setBuildingId] = useState<number>(-1);
  const [buildings, setBuildings] = useState<BuildingModel[]>([]);

  const [teamId, setTeamId] = useState<number>(-1);
  const [teams, setTeams] = useState<TeamModel[]>([]);

  const [selectedId, setSelectedId] = useState(0);
  const [hazardTypes, setHazardTypes] = useState<HazardType[]>([]);
  const [incidentTypes, setIncidentTypes] = useState<IncidentType[]>([]);
  const [statusTypes, setStatusTypes] = useState<Status[]>([]);
  const [users, setUsers] = useState<UserModel[]>([]);
  const [startDate, setStartDate] = useState<MaterialUiPickersDate>(new Date());
  const [endDate, setEndDate] = useState<MaterialUiPickersDate>(null);
  //const [date, setDate] = useState<number>(getUnixTimestamp());

  useEffect(() => {
    const search = window.location.search,
      params = new URLSearchParams(search),
      siteIdFromUrl = params.get("location"),
      dateFromUrl = params.get("date");

    if (siteIdFromUrl) setBuildingId(parseInt(siteIdFromUrl));
    if (dateFromUrl) setStartDate(getDateObj(parseInt(dateFromUrl)));

    Promise.all([
      axios.get("api/v2.0/data/hazard-types"),
      axios.get("api/v2.0/data/status"),
      axios.get("api/v2.0/data/incident-types"),
      axios.get("/api/v2.0/building"),
      axios.get("api/v2.0/user"),
      axios.get("api/v2.0/data/team"),
    ]).then((results) => {
      setHazardTypes(results && results[0] && results[0].data);
      setStatusTypes(results && results[1] && results[1].data);
      setIncidentTypes(results && results[2] && results[2].data);
      let buildings = results && results[3] && results[3].data;
      setBuildings(buildings);

      setTeams(results && results[5] && results[5].data);
      setTeamId(0);

      (siteIdFromUrl && parseInt(siteIdFromUrl) > 0) || setBuildingId(0);
      setUsers(results && results[4] && results[4].data);
    });
  }, []);
  useEffect(() => {
    if (buildingId >= 0) {
      let startDateQS = "";
      let endDateQS = "";
      if (startDate) {
        startDateQS = dateToString(startDate);

        if (endDate) {
          endDateQS = `&incident_end_dt=${dateToString(endDate)}`;
        }
      } else {
        startDateQS = `${dateToString(new Date())}`;
      }

      axios
        .get(
          `/api/v2.0/incident?incident_dt=${startDateQS}${endDateQS}&building_id=${buildingId}&team_id=${teamId}`
        )
        .then((res) => {
          setIncidents(res && res.data);
        });
    }
  }, [startDate, endDate, buildingId, teamId, reportModalMode]);

  useEffect(() => {
    if (selectedId) {
      setReportModalMode("view");
      return;
    }
  }, [selectedId]);

  useEffect(() => {
    if (reportModalMode === "none") setSelectedId(0);
  }, [reportModalMode]);

  const onChangeStartDate = (date: MaterialUiPickersDate) => {
    if (date == null) {
      return;
    }
    if (endDate && endDate < date) {
      setStartDate(endDate);
      setEndDate(date);
      return;
    } else {
      setStartDate(date);
    }
  };
  const onChangeEndDate = (date: MaterialUiPickersDate) => {
    if (date == null) {
      return;
    }
    if (startDate && date < startDate) {
      setEndDate(startDate);
      setStartDate(date);
      return;
    } else if (!startDate) {
      setStartDate(date);
      return;
    } else {
      setEndDate(date);
      return;
    }
  };

  return (
    <div className="DailyDashboard">
      <div className="zero_row">
        <TimePeriods
          date={formatDateObjUnix(startDate ?? new Date())}
          buildingId={buildingId}
        ></TimePeriods>
      </div>
      <div className="first_row">
        <div className="filters">
          <div className=" daterange">
            <label className="mbl-dt">
              <span>
                <p>Start Date</p>
              </span>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  views={["year", "month", "date"]}
                  openTo="date"
                  format="dd MMM yyyy"
                  value={startDate}
                  onChange={onChangeStartDate}
                />
              </MuiPickersUtilsProvider>
            </label>
            <label className="mbl-dt">
              <span>
                <p>End Date</p>
              </span>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  views={["year", "month", "date"]}
                  openTo="date"
                  format="dd MMM yyyy"
                  value={endDate}
                  onChange={onChangeEndDate}
                />
              </MuiPickersUtilsProvider>
            </label>
          </div>
          <div className="building-select">
            <label>Location</label>
            <Select
              value={buildingId}
              onChange={(e) => {
                setBuildingId(e.target.value as number);
              }}
            >
              <MenuItem key={0} value={0}>
                All Locations
              </MenuItem>
              {buildings.map((building) => (
                <MenuItem
                  key={building.building_id}
                  value={building.building_id}
                >
                  {building.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="team-select">
            <label>Team</label>
            <Select
              value={teamId}
              onChange={(e) => {
                setTeamId(e.target.value as number);
              }}
            >
              <MenuItem key={0} value={0}>
                All Teams
              </MenuItem>
              {teams.map((team) => (
                <MenuItem key={team.team_id} value={team.team_id}>
                  {team.team_name}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      </div>
      <IncidentListMobile
        incidents={incidents}
        onSelectInident={(incident_id: number) => setSelectedId(incident_id)}
      ></IncidentListMobile>
      <IncidentList
        buildingId={buildingId}
        onSelectIncident={(incident_id: number) => {
          setSelectedId(incident_id);
          setReportModalMode("view");
        }}
        onClickNewReportButton={() => setReportModalMode("add")}
        showDateColumn={Boolean(endDate && endDate !== startDate)}
        incidents={incidents}
      ></IncidentList>
      <Modal
        isOpen={reportModalMode !== "none"}
        onRequestClose={() => setReportModalMode("none")}
        ariaHideApp={false}
        style={{
          content: {
            borderRadius: "20px",
            padding: "15px",
            left: "20px",
            right: "20px",
            top: "20px",
            bottom: "20px",
            backgroundColor: BACKGROUND_COLOR,
          },
          overlay: { zIndex: 2 },
        }}
      >
        <IncidentReport
          id={selectedId}
          mode={reportModalMode}
          setMode={setReportModalMode}
          hazardTypes={hazardTypes}
          statusTypes={statusTypes}
          incidentTypes={incidentTypes}
          users={users}
        ></IncidentReport>
      </Modal>
    </div>
  );
}
