import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table, TableHead, TableBody } from "@material-ui/core";
import {
  faSortAmountDown,
  faSortAmountDownAlt,
} from "@fortawesome/free-solid-svg-icons";
import { formatDate, getUnixTimestamp } from "../../helpers/DateHelper";
import Loading from "../../components/Loading/Loading";
import DailyOpsSiteSelection from "../../components/DailyOpsSiteSelection/DailyOpsSiteSelection";
import NoData from "../../components/NoData/NoData";
import ProfileIcon from "../../resources/icons/dailyOps/profile.svg";
import "./DailyOpsEmployeeHeartRateTableView.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  StyledTableCell,
  StyledTableRow,
  StyledTableHeaderRow,
  defaultPageSize,
} from "../../helpers/tableDesignHelper";

export default function DailyOpsEmployeeHeartRateTableView() {
  const [selectedSiteId, setSelectedSiteId] = useState<number>(-1);
  const [date, setDate] = useState<number>(getUnixTimestamp());
  const [selectedTeamId, setSelectedTeamId] = useState<number>(-1);
  const [loading, setLoading] = useState(false);
  const [employeeSearchText, setEmployeeSearchText] = useState<string>("");
  const [tableRows, setData] = useState<any[]>([]);
  const [sortOrderAsc, setSortOrderAsc] = useState<boolean>(true);
  const [sortKey, setSortKey] = useState<string>("");
  const [currentPage] = useState<number>(1);

  useEffect(() => {
    const search = window.location.search,
      params = new URLSearchParams(search),
      siteIdFromUrl = params.get("location"),
      teamIdFromUrl = params.get("team"),
      dateFromUrl = params.get("date");
    if (siteIdFromUrl) setSelectedSiteId(parseInt(siteIdFromUrl));
    if (dateFromUrl) setDate(parseInt(dateFromUrl));
    if (teamIdFromUrl) setSelectedTeamId(parseInt(teamIdFromUrl));
  }, []);

  useEffect(() => {
    const formattedDate = formatDate(date);
    if (formattedDate && selectedSiteId > 0) {
      setLoading(true);
      axios
        .get(
          `/api/v2.0/daily-ops/user-heart-rate-list?startDate=${formattedDate}&endDate=${formattedDate}` +
            `&buildingId=${selectedSiteId}&employeeName=${employeeSearchText}&teamId=${selectedTeamId}` +
            `&sortKey=${sortKey}&sortOrder=${
              sortOrderAsc ? "asc" : "desc"
            }&pageSize=${defaultPageSize}&page=${currentPage}`
        )
        .then((result) => {
          setData(result.data ?? {});
        })
        .finally(() => setLoading(false));
    }
  }, [date, selectedSiteId, employeeSearchText, selectedTeamId]);
  return (
    <div className="DailyOpsEmployeeHeartRateTableView">
      <div>
        <DailyOpsSiteSelection
          selectedSiteId={selectedSiteId}
          date={date}
          setDate={setDate}
          setSelectedSiteId={setSelectedSiteId}
          selectedTeamId={selectedTeamId}
          setSelectedTeamId={setSelectedTeamId}
          employeeSearchText={employeeSearchText}
          setEmployeeSearchText={setEmployeeSearchText}
        ></DailyOpsSiteSelection>
      </div>
      <div>
        <Table>
          <TableHead>
            <StyledTableHeaderRow>
              <StyledTableCell align="left">ID</StyledTableCell>
              <StyledTableCell align="left">Name</StyledTableCell>
              <StyledTableCell
                align="center"
                onClick={() => {
                  setSortKey("");
                  setSortOrderAsc(!sortOrderAsc);
                }}
                className="table-sort-cell"
              >
                Avg. Heart Rate
                {sortKey === "" &&
                  (sortOrderAsc ? (
                    <FontAwesomeIcon
                      className="table-sort-icon"
                      icon={faSortAmountDownAlt}
                    ></FontAwesomeIcon>
                  ) : (
                    <FontAwesomeIcon
                      className="table-sort-icon"
                      icon={faSortAmountDown}
                    ></FontAwesomeIcon>
                  ))}
              </StyledTableCell>
              <StyledTableCell
                align="center"
                onClick={() => {
                  setSortKey("");
                  setSortOrderAsc(!sortOrderAsc);
                }}
                className="table-sort-cell"
              >
                Avg. Physical Exertion
                {sortKey === "" &&
                  (sortOrderAsc ? (
                    <FontAwesomeIcon
                      className="table-sort-icon"
                      icon={faSortAmountDownAlt}
                    ></FontAwesomeIcon>
                  ) : (
                    <FontAwesomeIcon
                      className="table-sort-icon"
                      icon={faSortAmountDown}
                    ></FontAwesomeIcon>
                  ))}
              </StyledTableCell>
              <StyledTableCell
                align="center"
                onClick={() => {
                  setSortKey("");
                  setSortOrderAsc(!sortOrderAsc);
                }}
                className="table-sort-cell"
              >
                Avg. Physical Exertion
                {sortKey === "" &&
                  (sortOrderAsc ? (
                    <FontAwesomeIcon
                      className="table-sort-icon"
                      icon={faSortAmountDownAlt}
                    ></FontAwesomeIcon>
                  ) : (
                    <FontAwesomeIcon
                      className="table-sort-icon"
                      icon={faSortAmountDown}
                    ></FontAwesomeIcon>
                  ))}
              </StyledTableCell>
              <StyledTableCell
                align="center"
                onClick={() => {
                  setSortKey("");
                  setSortOrderAsc(!sortOrderAsc);
                }}
                className="table-sort-cell"
              >
                High Heart Rate Alerts
                {sortKey === "" &&
                  (sortOrderAsc ? (
                    <FontAwesomeIcon
                      className="table-sort-icon"
                      icon={faSortAmountDownAlt}
                    ></FontAwesomeIcon>
                  ) : (
                    <FontAwesomeIcon
                      className="table-sort-icon"
                      icon={faSortAmountDown}
                    ></FontAwesomeIcon>
                  ))}
              </StyledTableCell>
              <StyledTableCell align="center">Profile</StyledTableCell>
            </StyledTableHeaderRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <StyledTableRow>
                <StyledTableCell colSpan={7}>
                  <Loading></Loading>
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              <></>
            )}
            {tableRows &&
              tableRows.map((row: any, i: number) => {
                return (
                  <StyledTableRow key={i}>
                    <StyledTableCell component="th" align="left" scope="row">
                      {`${row.userId.split("-")[0]}`}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {`${row.userName}`}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {`${row.avgHeartRate}`} BPM
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {`${row.heartRateZone}`}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {`${row.exertionType}`}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {`${row.highHeartRateAlerts}`}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <a href={`/user/${row.userName}?userid=${row.userId}`}>
                        <img
                          className="table-icon-large"
                          src={ProfileIcon}
                        ></img>
                      </a>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            {!tableRows ? (
              <StyledTableRow key={0}>
                <StyledTableCell colSpan={7}>
                  <NoData></NoData>
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
