import React from "react";
import "./Summary.scss";
interface Props {
  name: string;
  value: string | number | undefined;
  isSelected?: boolean;
  leftValue?: string;
  rightValue?: string;
  onSelect?: () => void;
  valueClass?: string;
  height?: number;
  width?: number;
}

const Summary: React.FC<Props> = ({
  name,
  value,

  isSelected,
  onSelect,
  valueClass,
}) => {
  return (
    <div
      className={`Summary ${onSelect ? "selectable" : ""} ${
        isSelected ? "active" : ""
      }`}
      onClick={(_) => {
        if (onSelect) onSelect();
      }}
    >
      <div className={`summary_container `}>
        <div className="name">{name}</div>

        <div className="icon">
          {valueClass === "falls" ? (
            <img src="fall_icon.svg" alt="Fall Icon"></img>
          ) : null}
          {valueClass === "near-miss" ? (
            <img src="near_miss_icon.svg" alt="Near Miss Icon"></img>
          ) : null}
          {valueClass === "hazard" ? (
            <img src="hazard_icon.svg" alt="Hazard Icon"></img>
          ) : null}
          {valueClass === "hr-alert" ? (
            <img src="hr_icon.svg" alt="HR Icon"></img>
          ) : null}
        </div>
        <div className={`value ${valueClass}`}>{value}</div>
      </div>
    </div>
  );
};

export default Summary;
