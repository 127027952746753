import { Mark, Slider } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
	Bar,
	BarChart,
	CartesianGrid,
	Legend,
	ResponsiveContainer,
	XAxis,
	YAxis,
} from 'recharts';
import './DailyOpsEmployeeStatus.scss';
import MapPinIcon from './../../resources/icons/dailyOps/map-pin.svg';
import MapPinCrossedIcon from './../../resources/icons/dailyOps/crossed-pin.svg';
import InformationIcon from './../../resources/icons/dailyOps/information.svg';
import InformationIconBlack from './../../resources/icons/dailyOps/information-black.svg';
import DailyOpsSiteSelection from '../../components/DailyOpsSiteSelection/DailyOpsSiteSelection';
import axios from 'axios';
import { formatDate, getUnixTimestamp } from '../../helpers/DateHelper';
import { Link } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';

export default function DailyOpsEmployeeStatus() {
	const [selectedSiteId, setSelectedSiteId] = useState<number>(-1);
	const [date, setDate] = useState<number>(getUnixTimestamp());
	const [marks, setMarks] = useState<Mark[]>([]);
	const [chartData, setData] = useState<any[]>([]);
	const [sliderValue, setSliderValue] = useState(0);
	const [selectedHour, setSelectedHour] = useState(0);
	const [chartLoading, setChartLoading] = useState(false);
	const [selectedTeamId, setSelectedTeamId] = useState<number>(-1);
	const [zoneValues, setZoneValues] = useState({
		inZone: 0,
		outZone: 0,
	});
	const [zoneBreakdownData, setZoneBreakdownData] = useState<any[]>([]);

	useEffect(() => {
		const search = window.location.search,
			params = new URLSearchParams(search),
			siteIdFromUrl = params.get('location'),
			dateFromUrl = params.get('date'),
			teamIdFromUrl = params.get('team'),
			hourFromUrl = params.get('hour');

		if (siteIdFromUrl) setSelectedSiteId(parseInt(siteIdFromUrl));
		if (dateFromUrl) setDate(parseInt(dateFromUrl));
		if (hourFromUrl) setSelectedHour(parseInt(hourFromUrl ?? '0'));
		if (teamIdFromUrl) setSelectedTeamId(parseInt(teamIdFromUrl));
	}, []);

	useEffect(() => {
		let _marks: any = [];
		for (let i = 0; i < chartData.length; i++) {
			//calc marks
			const val = (100 / chartData.length) * 0.5 * (2 * (i + 1) - 1);
			_marks.push({
				value: val,
			});
		}
		setMarks(_marks);
		if (chartData.length > 0 && selectedHour >= 0) {
			const indexForHour =
				chartData.map((x) => x.hour).indexOf(selectedHour) ?? 0;
			console.log(indexForHour);
			setInOutZoneValue(indexForHour >= 0 ? indexForHour : 0);
			setSliderValue(_marks[indexForHour]?.value ?? _marks[0]?.value ?? 0);
		}
	}, [chartData]);
	useEffect(() => {
		setInOutZoneValue(
			Math.round((sliderValue * chartData.length) / 100 - 1 / 2)
		);
	}, [sliderValue]);

	useEffect(() => {
		loadChartData();
	}, [date, selectedSiteId]);

	const loadChartData = () => {
		const formattedDate = formatDate(date);
		if (formattedDate && selectedSiteId > 0) {
			setChartLoading(true);
			axios
				.get(
					`/api/v2.0/daily-ops/user-status?startDate=${formattedDate}&endDate=${formattedDate}&buildingId=${selectedSiteId}`
				)
				.then((result) => {
					console.log(result.data);
					setData(result.data);
				})
				.finally(() => setChartLoading(false));
		}
	};
	const setInOutZoneValue = (index: number) => {
		setZoneValues({
			inZone: chartData[index]?.inZone ?? 0,
			outZone: chartData[index]?.outZone ?? 0,
		});
		setSelectedHour(chartData[index]?.hour ?? 0);
		setZoneBreakdownData(chartData[index]?.zoneBreakDown ?? []);
	};
	return (
		<div className="DailyOpsEmployeeStatus">
			<div className="">
				<DailyOpsSiteSelection
					selectedSiteId={selectedSiteId}
					date={date}
					setDate={setDate}
					setSelectedSiteId={setSelectedSiteId}
					selectedTeamId={selectedTeamId}
					setSelectedTeamId={setSelectedTeamId}
				></DailyOpsSiteSelection>
			</div>
			<div className="card chart-card">
				<div className="chart-title ">
					<div>
						<strong> Daily Active Workers</strong>
					</div>
				</div>
				{chartLoading ? (
					<Loading></Loading>
				) : (
					<ResponsiveContainer width={'100%'} height={400}>
						<BarChart
							data={chartData}
							margin={{
								top: 10,
								right: 0,
								left: 0,
								bottom: 10,
							}}
						>
							<CartesianGrid strokeDasharray="3" vertical={false} />
							<XAxis
								dataKey="hour"
								tick={{ fill: 'var(--text-color)', fontSize: 'var(--font-M)' }}
								tickFormatter={(hours): string => {
									//it is pm if hours from 12 onwards
									const suffix = hours >= 12 ? 'pm' : 'am';
									hours = hours > 12 ? hours - 12 : hours;
									hours = hours == '00' ? 12 : hours;
									return `${hours}${suffix}`;
								}}
							/>
							<YAxis hide />
							{/* <Tooltip /> */}
							<Legend
								align="center"
								layout="horizontal"
								wrapperStyle={{ bottom: -20 }}
								content={
									<Slider
										value={sliderValue}
										aria-labelledby="discrete-slider"
										valueLabelDisplay="off"
										step={null}
										marks={marks}
										min={0}
										max={100}
										track={false}
										onChange={(_e, value) => {
											setSliderValue(Array.isArray(value) ? value[0] : value);
										}}
									/>
								}
							/>
							<Bar dataKey="inZone" stackId="a" fill="#FFF" barSize={40} />
							<Bar dataKey="outZone" stackId="a" fill="#A37BFC" />
						</BarChart>
					</ResponsiveContainer>
				)}
			</div>
			<div className="summary-counts">
				{!chartLoading && (
					<>
						<Link
							to={`/daily-ops/overview/employee-status/in-zone?location=${selectedSiteId}&date=${date}&hour=${selectedHour}`}
						>
							<div className="card in-zone">
								<div className="number-summary">
									<div className="count">
										In Zone{' '}
										<img
											className="inline-icon"
											src={InformationIconBlack}
											alt=""
										></img>
									</div>
									<div className="number">{zoneValues.inZone}</div>
								</div>
								<div>
									<img className="icon" src={MapPinIcon} alt=""></img>
								</div>
							</div>
						</Link>
						<Link
							to={`/daily-ops/overview/employee-status/out-zone?location=${selectedSiteId}&date=${date}&hour=${selectedHour}`}
						>
							<div className="card out-zone">
								<div className="number-summary">
									<div className="count">
										Out of Zone{' '}
										<img
											className="inline-icon"
											src={InformationIcon}
											alt=""
										></img>
									</div>
									<div className="number">{zoneValues.outZone}</div>
								</div>
								<div>
									<img className="icon" src={MapPinCrossedIcon} alt=""></img>
								</div>
							</div>
						</Link>
					</>
				)}
			</div>
			{zoneBreakdownData.length > 0 && (
				<div className="breakdown-row">
					<h3> Distribution of Time Across Rooms</h3>
				</div>
			)}
			<div className="zone-breakdown">
				{!chartLoading &&
					zoneBreakdownData.map((x, index) => {
						return (
							<div
								className="card"
								style={{
									color: x.fill,
									textShadow:
										'-0.5px -0.5px 0 white,0.5px -0.5px 0 white,-0.5px 0.5px 0 white,0.5px 0.5px 0 white',
								}}
								key={index}
							>
								<div className="number-summary">
									<div className="count">{x.name}</div>
									<div className="number">
										{x.duration === 0
											? x.duration
											: (
													(x.duration /
														zoneBreakdownData.reduce(
															(a, b) => a + b.duration,
															0
														)) *
													100
											  ).toFixed(0)}{' '}
										%
									</div>
								</div>
							</div>
						);
					})}
			</div>
		</div>
	);
}
