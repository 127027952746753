import React, { useState, useEffect, useReducer } from "react";
import axios from "axios";
import {
  Grid,
  GridColumn,
  GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { TEXT_COLOR, BOX_COLOR } from "../../constants/constants";
import { UserWearable } from "../../models/UserWearable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

import "./WearableAssignment.scss";
import { WearableModel } from "../../models/WearableModel";
import { TeamModel } from "../../models/TeamModel";
import { WearableAssignmentModel } from "../../models/WearableAssignmentModel";
import WearableAssignmentForm from "../../components/Forms/WearableAssignmentForm/WearableAssignmentForm";
import { toast } from "react-toastify";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import QRCode from "../../components/QRCode/QRCode";

const initialSort: Array<SortDescriptor> = [
  { field: "first_name", dir: "asc" },
];


export default function WearableAssignment() {
  const [userWearables, setUserWearables] = useState<UserWearable[]>([]);
  // const [usersWithWearables, setUsersWithWearables] = useState<UserWearable[]>([]);

  const [
    wearableAssignment,
    setWearableAssignment,
  ] = useState<WearableAssignmentModel>({
    user_id: "",
    wearable_id: 0,
    team_id: 0,
  });
  const [wearables, setWearables] = useState<WearableModel[]>([]);
  const [teams, setTeams] = useState<TeamModel[]>([]);
  const [open, setOpen] = useState<boolean>(false);

  const [update, forceUpdate] = useReducer((x: number) => x + 1, 0);

  const [sort, setSort] = useState(initialSort);

  useEffect(() => {
    (async () => {
      let results = await Promise.all([
        axios.get("/api/v2.0/user/wearable"),
        axios.get("/api/v2.0/wearable"),
        axios.get("/api/v2.0/data/team"),
      ]);
      // setUsersWithWearables(results && results[0] && results[0].data.filter((row: UserWearable) => row.wearable_id!=undefined))
      setUserWearables(results && results[0] && results[0].data);
      setWearables(results && results[1] && results[1].data);
      setTeams(results && results[2] && results[2].data);
    })();
  }, [update, open]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setWearableAssignment({
      user_id: "",
      wearable_id: 0,
      team_id: 0,
    });
    setOpen(false);
  };

  const CommandCell = (props: any) => (
    <td className="k-command-cell">
      <button
        className="k-button k-grid-remove-command"
        onClick={() => {
          if (props.dataItem.wearable_id) {
            window.confirm(
              "Confirm deleting: " + props.dataItem.wearable_name
            ) && remove(props.dataItem);
          } else {
            toast["warning"]("No Wearable Assignment to delete.");
          }
        }}
      >
        <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
      </button>
    </td>
  );
  const QRCodeCell = (props: { dataItem: UserWearable }) => (
    <td className="k-command-cell">
      <QRCode user_id={props.dataItem.user_id}></QRCode>
    </td>
  );
  const remove = (dataItem: UserWearable) => {
    axios
      .delete("/api/v2.0/user/wearable", {
        data: {
          user_id: dataItem.user_id,
          wearable_id: dataItem.wearable_id,
        },
      })
      .then((_res) => {
        toast["success"]("Deleted Wearable Assignment");
        forceUpdate();
      })
      .catch((err) => {
        console.error(err);
        toast["error"](
          "Error deleting Wearable Assignment:" + err?.response?.data
        );
      });
  };

  const handleSubmit = () => {
    if (wearableAssignment.user_id === "") {
      toast["warning"]("Select User");
      return;
    } else if (wearableAssignment.wearable_id === 0) {
      toast["warning"]("Select Wearable");
      return;
    } else if (wearableAssignment.team_id === 0) {
      toast["warning"]("Select Team");
      return;
    }
    axios
      .post("/api/v2.0/user/wearable", {
        user_id: wearableAssignment.user_id,
        wearable_id: wearableAssignment.wearable_id,
        activity_level_id: 2, //Default to Medium for now
        team_id: wearableAssignment.team_id,
      })
      .then((_) => {
        toast["success"]("Wearable Assigned");
        handleClose();
      })
      .catch((err) => {
        console.error(err);
        toast["error"]("Error Assigning Wearable " + err?.response?.data);
      });
  };

  return (
    <div className="WearableAssignment">
      <div className="firstrow">
        <h3>Assign Wearables to Staff</h3>
        <FontAwesomeIcon
          title="Assign Wearable"
          icon={faPlus}
          size={"lg"}
          cursor="pointer"
          onClick={handleOpen}
        ></FontAwesomeIcon>
      </div>
      <div className="table">
        <Grid
          data={orderBy(userWearables, sort)}
          sort={sort}
          sortable={true}
          onSortChange={(e: GridSortChangeEvent) => {
            setSort(e.sort);
          }}
        >
          <GridColumn className="fixed" field="first_name" title="First Name" />
          <GridColumn className="fixed" field="last_name" title="Last Name" />
          <GridColumn
            className="fixed"
            field="wearable_name"
            title="Wearable"
          />
          <GridColumn className="fixed" field="mac_id" title="MAC ID" />
          <GridColumn
            className="fixed"
            field="team_name"
            title="Supervising Team"
          />
          <GridColumn cell={QRCodeCell} filterable={false} />

          <GridColumn cell={CommandCell} />
        </Grid>
      </div>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogTitle style={{ color: TEXT_COLOR, backgroundColor: BOX_COLOR }}>
          Add Wearable
        </DialogTitle>
        <DialogContent
          style={{ color: TEXT_COLOR, backgroundColor: BOX_COLOR }}
          dividers
        >
          <DialogContentText style={{ color: TEXT_COLOR }}>
            Assign a wearable to your staff here
          </DialogContentText>
          <WearableAssignmentForm
            wearableAssignment={wearableAssignment}
            users={userWearables}
            wearables={wearables}
            teams={teams}
            setWearableAssignment={setWearableAssignment}
          ></WearableAssignmentForm>
        </DialogContent>
        <DialogActions
          style={{ color: TEXT_COLOR, backgroundColor: BOX_COLOR }}
        >
          <Button style={{ color: TEXT_COLOR }} onClick={handleClose}>
            Cancel
          </Button>
          <Button style={{ color: TEXT_COLOR }} onClick={handleSubmit}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
