import React from "react";

interface CellProps {
  dataItem: any;
  field: string;
}

export class PositiveValueCell extends React.Component<CellProps> {
  render() {
    const value = this.props.dataItem[this.props.field];
    const displayValue =
      isNaN(value) || parseInt(value) < 1 ? "" : `${value.toFixed(0)}`;
    return <td>{displayValue}</td>;
  }
}
