import React from "react";
import "./LiveViewChart.scss";
import {
  PieChart,
  Pie,
  ResponsiveContainer,
  Legend,
  Label,
  Tooltip,
} from "recharts";
import NoData from "../NoData/NoData";

export default function LiveViewChart({
  centerText,
  centerSubText,
  chartData = [],
}: {
  centerText: string | number;
  centerSubText: string | number;
  chartData: any;
}) {
  const RenderLegend = (props: any) => {
    const { payload } = props;
    return (
      <ul className="live-view-chart-legend">
        {payload.map((entry: any, index: number) => (
          <li
            className="legend-item"
            style={{
              borderBottom: `${
                chartData.length - 1 !== index &&
                chartData[index + 1] &&
                chartData[index + 1].name !== ""
                  ? "solid 1px"
                  : ""
              }`,
              verticalAlign: "middle",
            }}
            key={`item-${index}`}
          >
            <div
              className="live-view-chart-dot"
              style={{ backgroundColor: `${chartData[index].fill ?? "#000"}` }}
            ></div>
            {entry.value}
          </li>
        ))}
      </ul>
    );
  };
  return chartData.length === 0 ? (
    <NoData padding={25}></NoData>
  ) : (
    <ResponsiveContainer className="LiveViewChart" width={"100%"}>
      <PieChart>
        <Pie
          data={chartData}
          dataKey="value"
          cx="80%"
          cy="50%"
          innerRadius={"85%"}
          outerRadius={"100%"}
          stroke="var(--card-bg)"
        >
          <>
            <Label
              position={"center"}
              dy={-10}
              style={{
                textAnchor: "middle",
                fill: "var(--text-color)",
                fontSize: "var(--font-XL)",
              }}
            >
              {centerText ?? ""}
            </Label>

            <Label
              position="center"
              dy={10}
              style={{
                textAnchor: "middle",
                fill: "var(--text-color)",
              }}
            >
              {centerSubText ?? ""}
            </Label>
          </>
        </Pie>
        <Legend
          verticalAlign="top"
          layout="radial"
          iconType="circle"
          content={RenderLegend}
        />
        <Tooltip
          formatter={function () {
            return "";
          }}
          separator={""}
        />
      </PieChart>
    </ResponsiveContainer>
  );
}
