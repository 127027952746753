import React, { useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import "./IncidentHistoryList.scss";
import { Incident } from "../../models/Incident";
import { NameCell } from "../../components/NameCell/NameCell";
// import { IncidentCell } from '../../components/IncidentCell/IncidentCell';
import { StatusCell } from "../../components/StatusCell/StatusCell";
import { DateCell } from "../../components/DateCell/DateCell";
import { DateTimeCell } from "../../components/DateTimeCell/DateTimeCell";

interface Props {
  incidentHistory: Incident[];
}

const IncidentHistoryList: React.FC<Props> = ({ incidentHistory }) => {
  const [gridDataState, setGridDataState] = useState<any>({
    sort: [],
    skip: 0,
    take: 5,
  });
  return (
    <div className="IncidentHistoryList">
      <div className="content_row">
        <div className="grid">
          <Grid
            data={process(incidentHistory, gridDataState)}
            pageable={{ buttonCount: 5, pageSizes: [5, 10] }}
            pageSize={5}
            sortable={true}
            {...gridDataState}
            onDataStateChange={(e) => setGridDataState(e.data)}
            style={{ height: "90%" }}
          >
            <GridColumn
              field="updated_dt"
              title="Date"
              format="{0:d}"
              cell={(props: any) => <DateCell {...props}></DateCell>}
            />

            <GridColumn
              field="updated_dt"
              title="Time"
              format="{0:d}"
              cell={(props: any) => <DateTimeCell {...props}></DateTimeCell>}
            />

            <GridColumn
              field="victim_name"
              title="Name"
              cell={(props: any) => <NameCell {...props}></NameCell>}
            />

            <GridColumn
              field="status"
              title="Status"
              cell={(props: any) => <NameCell {...props}></NameCell>}
            />
            <GridColumn
              field="updated_by"
              title="Updated By"
              cell={(props: any) => <NameCell {...props}></NameCell>}
            />

            <GridColumn field="incident_type" title="Incident Type" />

            <GridColumn
              field="hazard_type"
              title="Hazard Type"
              cell={(props: any) => <NameCell {...props}></NameCell>}
            />

            <GridColumn field="remarks" title="Remarks" format="{0:d}" />
            <GridColumn
              field="hazard_description"
              title="Hazard Description"
              cell={(props: any) => <StatusCell {...props}></StatusCell>}
            />
            <GridColumn field="location" title="Location" />
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default IncidentHistoryList;
