import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

import "./Wearable.scss";

import { Grid, GridColumn } from "@progress/kendo-react-grid";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

import { TEXT_COLOR, BOX_COLOR } from "../../constants/constants";
import { WearableModel } from "../../models/WearableModel";
import { REGEXP_MACADDR } from "../../constants/regularExpressions";
import WearableForm from "../../components/Forms/WearableForm/WearableForm";
import { WearableTypeModel } from "../../models/WearableTypeModel";

export default function Wearable() {
  const [wearables, setWearables] = useState<WearableModel[]>([]);
  const [wearable, setWearable] = useState<WearableModel>({
    wearable_id: 0,
    wearable_model_id: 1,
    wearable_model_name: "MetaMotion RL",
    wearable_name: "",
    mac_id: "",
  });

  const [wearablesTypes, setWearableTypes] = useState<WearableTypeModel[]>([]);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    axios
      .get("/api/v2.0/wearable")
      .then((res) => {
        setWearables(res && res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [open]);

  useEffect(() => {
    axios
      .get("/api/v2.0/data/wearable-types")
      .then((res) => {
        setWearableTypes(res && res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setWearable({
      wearable_id: 0,
      wearable_model_id: 1,
      wearable_model_name: "MetaMotion RL",
      wearable_name: "",
      mac_id: "",
    });
    setOpen(false);
  };

  const CommandCell = (props: any) => (
    <td className="k-command-cell">
      <button
        className="k-button k-grid-remove-command"
        onClick={() =>
          window.confirm("Confirm deleting: " + props.dataItem.wearable_name) &&
          remove(props.dataItem)
        }
      >
        <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
      </button>
    </td>
  );

  const remove = (dataItem: WearableModel) => {
    axios
      .delete("/api/v2.0/wearable", {
        data: {
          wearable_id: dataItem.wearable_id,
        },
      })
      .then((res) => {
        console.log(res.data);
        toast["success"]("Wearable Deleted");
        const filtered = wearables.filter(
          (record) => record.wearable_id !== dataItem.wearable_id
        );
        setWearables(filtered);
      })
      .catch((err) => {
        console.error(err);
        toast["error"]("Error deleting Wearable:" + err?.response?.data);
      });
  };

  const handleSubmit = () => {
    if (wearable.wearable_name.length < 1) {
      toast["warning"]("Enter Wearable Name");
      return;
    } else if (wearable.wearable_name.length > 10) {
      toast["warning"]("Wearable Name cannot be longer than 10 characters");
      return;
    } else if (!REGEXP_MACADDR.test(wearable.mac_id)) {
      toast["warning"]("Enter Valid Mac Address");
      return;
    }
    axios
      .post("/api/v2.0/wearable", {
        wearable_name: wearable.wearable_name,
        mac_id: wearable.mac_id.toUpperCase(),
        wearable_model_id: wearable.wearable_model_id,
      })
      .then((_) => {
        toast["success"]("Wearable Added");
        handleClose();
      })
      .catch((err) => {
        console.error(err);
        toast["error"]("Error adding new wearable " + err?.response?.data);
      });
  };
  return (
    <div className="Wearable">
      <div className="firstrow">
        <h3>Add Wearables</h3>
        <FontAwesomeIcon
          title="Add Wearable"
          icon={faPlus}
          size={"lg"}
          cursor="pointer"
          onClick={handleOpen}
        ></FontAwesomeIcon>
      </div>
      <div className="table">
        <Grid data={[...wearables]}>
          <GridColumn
            className="fixed"
            field="wearable_model_name"
            title="Wearable Model"
          />
          <GridColumn className="fixed" field="mac_id" title="MAC ID" />
          <GridColumn className="fixed" field="wearable_name" title="Name" />
          <GridColumn cell={CommandCell} />
        </Grid>
      </div>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogTitle style={{ color: TEXT_COLOR, backgroundColor: BOX_COLOR }}>
          Add Wearable
        </DialogTitle>
        <DialogContent
          style={{ color: TEXT_COLOR, backgroundColor: BOX_COLOR }}
          dividers
        >
          <DialogContentText style={{ color: TEXT_COLOR }}>
            Onboard wearables here to assign to your Staff
          </DialogContentText>
          <WearableForm
            wearable={wearable}
            setWearable={setWearable}
            wearableTypes={wearablesTypes}
          ></WearableForm>
        </DialogContent>
        <DialogActions
          style={{ color: TEXT_COLOR, backgroundColor: BOX_COLOR }}
        >
          <Button style={{ color: TEXT_COLOR }} onClick={handleClose}>
            Cancel
          </Button>
          <Button style={{ color: TEXT_COLOR }} onClick={handleSubmit}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
