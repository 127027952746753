import React, { useEffect } from "react";
import "./VulcanStore.scss";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { VulcanStoreItem } from "../../models/VulcanStoreItem";

const VulcanStore: React.FC = () => {
  const [vulcanStoreItems, setVulcanStoreItems] = React.useState<
    VulcanStoreItem[]
  >([]);
  useEffect(() => {
    axios
      .get(`api/v2.0/user/user-appcenter-link`)
      .then((res: any) => {
        setVulcanStoreItems(res.data);
      })
      .catch((err: any) => {
        console.log(err.message);
      });
  }, []);
  return (
    <div className="VulcanStore">
      <div className="container">
        <section>
          <div className="actions">
            {vulcanStoreItems
              ? vulcanStoreItems.map((item: VulcanStoreItem, index: number) => {
                  return (
                    <button
                    key={index}
                      onClick={() => window.open(item.apk_link)}
                    >
                      {item.apk_name}
                    </button>
                  );
                })
              : "No Data!"}
          </div>
        </section>
      </div>
    </div>
  );
};
export default withRouter(VulcanStore);
