import React, { useState } from "react";

import "./WearableAssignmentForm.scss";
import { WearableModel } from "../../../models/WearableModel";
import { MenuItem, Select } from "@material-ui/core";
import { UserWearable } from "../../../models/UserWearable";
import { WearableAssignmentModel } from "../../../models/WearableAssignmentModel";
import { TeamModel } from "../../../models/TeamModel";

interface Props {
  wearableAssignment: WearableAssignmentModel;
  users: UserWearable[];
  wearables: WearableModel[];
  teams: TeamModel[];

  setWearableAssignment: (
    wearable:
      | WearableAssignmentModel
      | ((prevState: WearableAssignmentModel) => WearableAssignmentModel)
  ) => void;
}

export default function WearableAssignmentForm(props: Props) {
  const [users] = useState<UserWearable[]>(props.users);
  const [wearables] = useState<WearableModel[]>(props.wearables);
  const [teams] = useState<TeamModel[]>(props.teams);

  const handleUserChange = (event: { target: { value: any } }) => {
    props.setWearableAssignment((prevState) => ({
      ...prevState,
      user_id: event.target.value,
    }));
  };

  const handleWearableChange = (event: { target: { value: any } }) => {
    props.setWearableAssignment((prevState) => ({
      ...prevState,
      wearable_id: event.target.value,
    }));
  };

  const handleTeamChange = (event: { target: { value: any } }) => {
    props.setWearableAssignment((prevState) => ({
      ...prevState,
      team_id: event.target.value,
    }));
  };

  return (
    <div className="WearableAssignmentForm">
      <div className="user">
        <h4 className="required">Select User</h4>
        <Select
          fullWidth
          value={props.wearableAssignment.user_id}
          onChange={handleUserChange}
        >
          {users.map((user) => (
            <MenuItem key={user.user_id} value={user.user_id}>
              {user.email}
            </MenuItem>
          ))}
        </Select>
      </div>

      <div className="wearable">
        <h4 className="required">Select Wearable</h4>
        <Select
          fullWidth
          value={props.wearableAssignment.wearable_id}
          onChange={handleWearableChange}
          placeholder="Select Wearable"
        >
          {wearables.map((wearable) => (
            <MenuItem key={wearable.wearable_id} value={wearable.wearable_id}>
              {wearable.wearable_name}
            </MenuItem>
          ))}
        </Select>
      </div>

      <div className="team">
        <h4 className="required">Select Team</h4>
        <Select
          fullWidth
          value={props.wearableAssignment.team_id}
          onChange={handleTeamChange}
        >
          {teams.map((team) => (
            <MenuItem key={team.team_id} value={team.team_id}>
              {team.team_name}
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
}
