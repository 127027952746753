import React, { ReactNode } from "react";
import { Route } from "react-router-dom";
import { TEXT_COLOR } from "../../constants/constants";
import { AuthService } from "../../services/authService";
import Layout from "../Layout/Layout";

// A wrapper for <Route> that redirects to the logi
// screen if you're not yet authenticated.
interface Props {
  children?: ReactNode;
  path?: string;
  exact?: boolean;
}
export const AdminRoute: React.FC<Props> = ({ children, path, exact }) => {
  return (
    <Route
      path={path}
      exact={exact}
      render={() =>
        AuthService.isAdmin() ? (
          children
        ) : (
          <Layout>
            <p
              style={{
                color: TEXT_COLOR,
                fontSize: "16px",
                textAlign: "center",
              }}
            >
              Forbidden: Please request access from an Admin Account and
              re-login.
            </p>
          </Layout>
        )
      }
    />
  );
};
