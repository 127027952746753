import React, { useRef, useEffect, useState, useMemo } from "react";
import axios from "axios";
import { Map as LeafletMap, TileLayer } from "react-leaflet";
import { TextField } from "@material-ui/core";
import HeatmapLayer from "react-leaflet-heatmap-layer";
import { Autocomplete } from "@material-ui/lab";

import { ActivityTypeModel } from "../../models/ActivityTypeModel";
import { UserActivityModel } from "../../models/UserActivityModel";
import { UserModel } from "../../models/UserModel";
import { UnitModel } from "../../models/UnitModel";
import { formatDate, getUnixTimestamp } from "../../helpers/DateHelper";
import DailyOpsSiteSelection from "../../components/DailyOpsSiteSelection/DailyOpsSiteSelection";
import { RangeSlider, SliderLabel } from "@progress/kendo-react-inputs";
import "./DailyOpsHeatmapView.scss";

function dateToHoursSinceMidnight(date: Date): number {
  return (
    date.getHours() +
    (date.getMinutes() +
      (date.getSeconds() + date.getMilliseconds() / 1000) / 60) /
      60
  );
}

async function fetchUsers(
  selectedSiteId: number,
  date: number
): Promise<UserModel[]> {
  const allZones: UnitModel[] = (await axios.get("/api/v2.0/unit")).data;
  const siteZones = allZones.filter(
    (zone) => zone.building_id === selectedSiteId
  );
  const zoneUsers = await Promise.all(
    siteZones.map((zone) =>
      axios
        .get<UserModel[]>(
          `/api/v2.0/user/zone?zone_id=${zone.unit_id}&date=${formatDate(date)}`
        )
        .then((result) => result.data)
    )
  );
  const siteUsers = zoneUsers.flat();
  return siteUsers;
}

async function fetchUserActivityPoints(
  users: UserModel[],
  date: number
): Promise<Map<string, UserActivityModel[]>> {
  const mapDataPromises = users.map((user) =>
    axios
      .get<UserActivityModel[]>(
        `/api/v2.0/ws/user_activity?date=${formatDate(date)}` +
          `&startTime=00:00:00&endTime=23:59:59.9999999&userId=${user.user_id}`
      )
      .then(
        (result) => [user.user_id, result.data] as [string, UserActivityModel[]]
      )
  );
  return new Map(await Promise.all(mapDataPromises));
}

export default function DailyOpsHeatmapView() {
  const mapRef = useRef<any>();
  // backend configured values
  const [activityTypes, setActivityTypes] = useState<ActivityTypeModel[]>([]);
  const normalActivityType = useMemo(() => {
    return activityTypes.find((type) => type.activity_type === "Normal");
  }, [activityTypes]);
  const [selectedActivityTypeId, setSelectedActivityTypeId] = useState(-1);
  const [selectedSiteId, setSelectedSiteId] = useState<number>(-1);
  const [selectedTeamId, setSelectedTeamId] = useState<number>(-1);
  const [date, setDate] = useState<number>(getUnixTimestamp());
  const [timespan, setTimespan] = useState<[number, number]>([6, 18]);
  const [users, setUsers] = useState<UserModel[]>([]);
  const [usersId, setUserId] = useState("");

  useEffect(() => {
    const search = window.location.search,
      params = new URLSearchParams(search),
      siteIdFromUrl = params.get("location"),
      teamIdFromUrl = params.get("team"),
      dateFromUrl = params.get("date");

    if (siteIdFromUrl) setSelectedSiteId(parseInt(siteIdFromUrl));
    if (dateFromUrl) setDate(parseInt(dateFromUrl));
    if (teamIdFromUrl) setSelectedTeamId(parseInt(teamIdFromUrl));
  }, []);

  const [activityPoints, setActivityPoints] = useState<UserActivityModel[]>([]);
  const selectedActivityPoints = useMemo(
    () =>
      activityPoints.filter((point) => {
        const hours = dateToHoursSinceMidnight(new Date(point.timestamp));
        return (
          (selectedActivityTypeId === -1 ||
            point.activity_type_id === selectedActivityTypeId) &&
          timespan[0] <= hours &&
          hours <= timespan[1] &&
          (point.lat !== 0 || point.long !== 0)
        );
      }),
    [selectedActivityTypeId, activityPoints, timespan]
  );
  // initialize backend configured values
  useEffect(() => {
    axios
      .get("/api/v2.0/data/activity-types")
      .then((result) => setActivityTypes(result.data));
  }, []);

  // fetch data for the given site and date
  useEffect(() => {
    (async () => {
      if (selectedSiteId) {
        const users = await fetchUsers(selectedSiteId, date);
        setUsers(users);

        if (
          usersId !== "" &&
          users.filter((x) => x.user_id === usersId).length > 0
        ) {
          const userActivityPoints = await fetchUserActivityPoints(
            users.filter((x) => x.user_id === usersId),
            date
          );
          setActivityPoints(Array.from(userActivityPoints.values()).flat());
        } else {
          const userActivityPoints = await fetchUserActivityPoints(users, date);
          const filteredUserActivityPoints = Array.from(
            userActivityPoints.values()
          )
            .filter((x) => x.length > 0)
            .flat();
          // if (filteredUserActivityPoints.length === 0) {
          //   toast["warning"]("No activity found for selected site.");
          // }
          setActivityPoints(filteredUserActivityPoints);
        }
      }
    })();
  }, [selectedSiteId, date, normalActivityType, timespan, usersId]);
  return (
    <div className="DailyOpsView">
      <div>
        <DailyOpsSiteSelection
          selectedSiteId={selectedSiteId}
          date={date}
          setDate={setDate}
          setSelectedSiteId={setSelectedSiteId}
          selectedTeamId={selectedTeamId}
          setSelectedTeamId={setSelectedTeamId}
        ></DailyOpsSiteSelection>
        <select
          className="form-element short-element"
          onChange={(e) =>
            setSelectedActivityTypeId(Number.parseInt(e.target.value))
          }
          value={selectedActivityTypeId}
        >
          <option key="-1" value="-1">
            All tasks
          </option>
          {activityTypes.map((type) => (
            <option key={type.activity_type_id} value={type.activity_type_id}>
              {type.activity_type}
            </option>
          ))}
        </select>
        <Autocomplete
          id="size-small-standard"
          size="small"
          className="form-element"
          options={users.map((user) => {
            return {
              label: `${user.first_name} ${user.last_name}`,
              value: user.user_id,
            };
          })}
          onChange={(_e, row) => {
            setUserId(row?.value ?? "");
          }}
          getOptionLabel={(option: any) => option.label}
          value={
            usersId && users.filter((x) => x.user_id === usersId)[0]
              ? {
                  label:
                    users.filter((x) => x.user_id === usersId)[0].first_name +
                    " " +
                    users.filter((x) => x.user_id === usersId)[0].last_name,
                  value: usersId,
                }
              : null
          }
          defaultValue={
            usersId && users.filter((x) => x.user_id === usersId)[0]
              ? {
                  label:
                    users.filter((x) => x.user_id === usersId)[0].first_name +
                    " " +
                    users.filter((x) => x.user_id === usersId)[0].last_name,
                  value: usersId,
                }
              : null
          }
          renderInput={(params) => (
            <TextField {...params} placeholder="Staff Name" />
          )}
        />
      </div>
      <div className="map-section">
        <div id="heatmap-legend">
          <div className="heatmap-label" id="heatmap-least">
            Least time spent
          </div>
          <div className="heatmap-label" id="heatmap-most">
            Most time spent
          </div>
          <div id="heatmap-spectrum" />
        </div>
        <div className="heat-map-slider">
          <RangeSlider
            defaultValue={{ start: timespan[0], end: timespan[1] }}
            step={1 / 12}
            min={0}
            max={24 - 1 / 60000}
            onChange={(e) => setTimespan([e.value.start, e.value.end])}
          >
            {Array(24)
              .fill(0)
              .map((_, i) => (
                <SliderLabel key={i} position={i}>
                  {i >= 12
                    ? `${i - 12 === 0 ? "12" : i - 12} PM`
                    : `${i === 0 ? "12" : i.toString().padStart(2, "0")} AM`}
                </SliderLabel>
              ))}
          </RangeSlider>
        </div>
        <LeafletMap
          ref={mapRef}
          center={[1.3394749558729497, 103.93366648033013]}
          zoom={19}
          zoomControl={true}
        >
          <HeatmapLayer
            fitBoundsOnLoad
            fitBoundsOnUpdate
            points={selectedActivityPoints}
            longitudeExtractor={(m: UserActivityModel) => m.long}
            latitudeExtractor={(m: UserActivityModel) => m.lat}
            intensityExtractor={(m: UserActivityModel) => m.activity_duration}
          />
          <TileLayer
            maxNativeZoom={19}
            maxZoom={23}
            attribution='New OneMap | Map data &copy; contributors, <a href="http://SLA.gov.sg">Singapore Land Authority</a>'
            url="https://maps-{s}.onemap.sg/v3/Default/{z}/{x}/{y}.png"
          />
        </LeafletMap>
      </div>
    </div>
  );
}
