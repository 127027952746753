import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Table,
  TableHead,
  TableBody,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import DailyOpsSiteSelection from "../../components/DailyOpsSiteSelection/DailyOpsSiteSelection";
import NoData from "../../components/NoData/NoData";
import { formatDate, getUnixTimestamp } from "../../helpers/DateHelper";
import "./DailyOpsEmployeeStatusTableView.scss";
import LocationPinIcon from "../../resources/icons/dailyOps/location-pin.svg";
import MessageIcon from "../../resources/icons/dailyOps/message.svg";
import ProfileIcon from "../../resources/icons/dailyOps/profile.svg";
import LastSeenLocationPopup from "../../components/LastSeenLocationPopup/LastSeenLocationPopup";
import Loading from "../../components/Loading/Loading";
import SendUserMessagePopUp from "../../components/SendUserMessagePopUp/SendUserMessagePopUp";
import {
  StyledTableCell,
  StyledTableRow,
  StyledTableHeaderRow,
} from "../../helpers/tableDesignHelper";

export default function DailyOpsEmployeeStatusTableView() {
  const { zone } = useParams<{ zone: string }>();
  const [selectedSiteId, setSelectedSiteId] = useState<number>(-1);
  const [date, setDate] = useState<number>(getUnixTimestamp());
  const [hour, setHour] = useState<number>(0);
  const [selectedUserId, setSelectedUser] = useState<string>("");
  const [tableRows, setData] = useState<any[]>([]);
  const [isInZoneSelected, setIsInZoneSelected] = useState<boolean>(true);
  const [showUserLocation, setShowUserLocation] = useState<boolean>(false);
  const [showUserMessage, setShowUserMessage] = useState<boolean>(false);
  const [selectedTeamId, setSelectedTeamId] = useState<number>(-1);
  const [employeeSearchText, setEmployeeSearchText] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const search = window.location.search,
      params = new URLSearchParams(search),
      siteIdFromUrl = params.get("location"),
      dateFromUrl = params.get("date"),
      teamIdFromUrl = params.get("team"),
      hourFromUrl = params.get("hour");
    if (siteIdFromUrl) setSelectedSiteId(parseInt(siteIdFromUrl));
    if (dateFromUrl) setDate(parseInt(dateFromUrl));
    if (zone !== "in-zone") setIsInZoneSelected(false);
    if (hourFromUrl) setHour(parseInt(hourFromUrl) ?? 0);
    if (teamIdFromUrl) setSelectedTeamId(parseInt(teamIdFromUrl));
  }, []);

  useEffect(() => {
    const formattedDate = formatDate(date);
    if (formattedDate && selectedSiteId > 0 && hour && zone) {
      setLoading(true);
      axios
        .get(
          `/api/v2.0/daily-ops/user-list?date=${formattedDate}&hour=${hour}&zone=${zone}` +
            `&buildingId=${selectedSiteId}&employeeName=${employeeSearchText}&teamId=${selectedTeamId}`
        )
        .then((result) => {
          console.log(result.data);
          setData(result.data);
        })
        .finally(() => setLoading(false));
    }
  }, [date, hour, zone, selectedSiteId, employeeSearchText, selectedTeamId]);

  return (
    <div className="DailyOpsEmployeeStatusTableView">
      <div className="header-row">
        <div>
          <DailyOpsSiteSelection
            selectedSiteId={selectedSiteId}
            date={date}
            setDate={setDate}
            setSelectedSiteId={setSelectedSiteId}
            hour={hour}
            sethour={setHour}
            hours={[
              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
              19, 20, 21, 22, 23,
            ]}
            selectedTeamId={selectedTeamId}
            setSelectedTeamId={setSelectedTeamId}
            employeeSearchText={employeeSearchText}
            setEmployeeSearchText={setEmployeeSearchText}
          ></DailyOpsSiteSelection>
        </div>
        <div className="radio-section">
          <RadioGroup row>
            <FormControlLabel
              control={
                <Radio
                  color="primary"
                  checked={isInZoneSelected}
                  onChange={(e) => {
                    console.log(e.target.checked);
                    if (e.target.checked) {
                      setIsInZoneSelected(true);
                      history.replace(
                        "/daily-ops/overview/employee-status/in-zone" +
                          (window.location.search ? window.location.search : "")
                      );
                    }
                  }}
                />
              }
              labelPlacement="start"
              label="In Zone"
            />
            <FormControlLabel
              control={
                <Radio
                  color="primary"
                  checked={!isInZoneSelected}
                  onChange={(e) => {
                    console.log(e.target.checked);
                    if (e.target.checked) {
                      setIsInZoneSelected(false);
                      history.replace(
                        "/daily-ops/overview/employee-status/out-zone" +
                          (window.location.search ? window.location.search : "")
                      );
                    }
                  }}
                />
              }
              label="Out Zone"
              labelPlacement="start"
            />
          </RadioGroup>
        </div>
      </div>
      <div>
        {loading ? (
          <Loading></Loading>
        ) : (
          <Table>
            <TableHead>
              <StyledTableHeaderRow>
                <StyledTableCell align="left">ID</StyledTableCell>
                <StyledTableCell align="left">Name</StyledTableCell>
                <StyledTableCell align="left">Contact No.</StyledTableCell>
                {!isInZoneSelected && (
                  <StyledTableCell align="center">Status</StyledTableCell>
                )}
                <StyledTableCell align="center">Location</StyledTableCell>
                <StyledTableCell align="center">Message</StyledTableCell>

                <StyledTableCell align="center">Profile</StyledTableCell>
              </StyledTableHeaderRow>
            </TableHead>
            <TableBody>
              {tableRows.map((row, i) => (
                <StyledTableRow key={i}>
                  <StyledTableCell component="th" align="left" scope="row">
                    {`${row.user_id.split("-")[0]}`}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {`${row.first_name} ${row.last_name}`}
                  </StyledTableCell>
                  <StyledTableCell align="left">{`${row.phone_no}`}</StyledTableCell>
                  {!isInZoneSelected && (
                    <StyledTableCell align="center">{`${row.status}`}</StyledTableCell>
                  )}
                  <StyledTableCell align="center">
                    <img
                      onClick={() => {
                        setShowUserLocation(true);
                        setSelectedUser(row.user_id);
                        console.log(row.user_id);
                      }}
                      className="table-icon-large"
                      src={LocationPinIcon}
                    ></img>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <img
                      className="table-icon"
                      onClick={() => {
                        setShowUserMessage(true);
                        setSelectedUser(row.user_id);
                      }}
                      src={MessageIcon}
                    ></img>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <a
                      href={`/user/${row.first_name} ${row.last_name}?userid=${row.user_id}`}
                    >
                      <img className="table-icon-large" src={ProfileIcon}></img>
                    </a>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              {tableRows && tableRows.length === 0 ? (
                <StyledTableRow key={0}>
                  <StyledTableCell colSpan={6}>
                    <NoData></NoData>
                  </StyledTableCell>
                </StyledTableRow>
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        )}
        {showUserLocation && (
          <LastSeenLocationPopup
            isOpen={showUserLocation}
            handleVisibility={setShowUserLocation}
            selectedUser={selectedUserId}
          ></LastSeenLocationPopup>
        )}
        {showUserMessage && (
          <SendUserMessagePopUp
            isOpen={showUserMessage}
            handleVisibility={setShowUserMessage}
            selectedUserId={selectedUserId}
            selectedUser={
              tableRows.filter((x) => x.user_id === selectedUserId)[0] ?? {}
            }
            teamUserIds={tableRows.map((x) => x.user_id).join(",") ?? {}}
          ></SendUserMessagePopUp>
        )}
      </div>
    </div>
  );
}
