import moment from "moment";
import React, { useMemo } from "react";
import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  //ZAxis,
  CartesianGrid,
  RectangleProps,
  Line,
  Tooltip,
  Legend,
} from "recharts";
import "./BoxPlotChart.scss";

const DotBar = (props: RectangleProps) => {
  const { x, y, width, height } = props;

  if (x == null || y == null || width == null || height == null) {
    return null;
  }

  return (
    <line
      x1={x + width / 2}
      y1={y + height}
      x2={x + width / 2}
      y2={y}
      stroke={"white"}
      strokeWidth={1}
      stroke-dasharray={"4"}
    />
  );
};

const HorizonBar = (props: RectangleProps) => {
  const { x, y, width, height } = props;

  if (x == null || y == null || width == null || height == null) {
    return null;
  }

  return (
    <line
      x1={x}
      y1={y}
      x2={x + width}
      y2={y}
      stroke={"white"}
      strokeWidth={2}
    />
  );
};
const granularityTimeFormat: any = {
  "24h": "HH:mm zz",
  "7d": "DD MMM",
  "30d": "DD MMM",
  "90d": "DD MMM",
  "365d": "MMM YYYY",
};
const formatXAxis = (tickFormat: any, timePeriodSelected: any) => {
  return moment(tickFormat).format(
    granularityTimeFormat[timePeriodSelected] ?? "DD MMM"
  );
};
type BoxPlot = {
  min: number;
  q1: number;
  q2: number;
  q3: number;
  max: number;
  avg?: number;
  timestamp: string;
};

type BoxPlotData = {
  min: number;
  bottomWhisker: number;
  bottomBox: number;
  topBox: number;
  topWhisker: number;
  average?: number;
  size: number;
  timestamp: string;
};

const useBoxPlot = (boxPlots: BoxPlot[]): BoxPlotData[] => {
  const data = useMemo(
    () =>
      boxPlots.map((v) => {
        return {
          min: v.min,
          bottomWhisker: v.q1 - v.min,
          bottomBox: v.q2 - v.q1,
          topBox: v.q3 - v.q2,
          topWhisker: v.max - v.q3,
          average: v.avg,
          size: 50,
          timestamp: v.timestamp,
          max: v.max,
          q1: v.q1,
          q2: v.q2,
          q3: v.q3,
        };
      }),
    [boxPlots]
  );

  return data;
};

const BoxPlotChart = ({
  boxPlots,
  timePeriod,
  chartType,
}: {
  boxPlots: any;
  timePeriod: string;
  chartType: string;
}) => {
  const data = useBoxPlot(boxPlots);
  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          {chartType && chartType === "HR" && (
            <>
              <div className="header">
                <h4>Heart Rate Statistics</h4>
                <h5> @ {payload[0].payload.timestamp ?? ""}</h5>
              </div>
              <p className="label">
                {`Average :`}{" "}
                <span className="val">
                  {payload[0].payload.average ?? 0} bpm
                </span>
              </p>
              <p className="label">
                {`Max :`}{" "}
                <span className="val">{payload[0].payload.max ?? 0} bpm</span>
              </p>
              <p className="label">
                {`Min :`}{" "}
                <span className="val">{payload[0].payload.min ?? 0} bpm</span>
              </p>
              <p className="label">
                {`First Quartile :`}{" "}
                <span className="val">{payload[0].payload.q1 ?? 0} bpm</span>
              </p>
              <p className="label">
                {`Median :`}{" "}
                <span className="val">{payload[0].payload.q2 ?? 0} bpm</span>
              </p>
              <p className="label">
                {`Third Quartile :`}{" "}
                <span className="val">{payload[0].payload.q3 ?? 0} bpm</span>
              </p>
            </>
          )}
        </div>
      );
    }

    return null;
  };
  return (
    <div className="BoxPlotChart">
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 50,
          }}
        >
          <CartesianGrid stroke="#696969" />
          <Bar
            stackId={"a"}
            dataKey={"min"}
            barSize={20}
            fill={"none"}
            legendType={"none"}
          />
          <Bar
            stackId={"a"}
            dataKey={"bar"}
            shape={<HorizonBar />}
            barSize={20}
            fill={"#fff"}
            legendType={"none"}
          />
          <Bar
            stackId={"a"}
            dataKey={"bottomWhisker"}
            shape={<DotBar />}
            barSize={20}
            fill={"#fff"}
            legendType={"none"}
          />
          <Bar
            stackId={"a"}
            dataKey={"bottomBox"}
            fill={"#8884d8"}
            barSize={20}
            legendType={"none"}
          />
          <Bar
            stackId={"a"}
            dataKey={"bar"}
            shape={<HorizonBar />}
            barSize={20}
            fill={"#fff"}
            legendType={"none"}
          />
          <Bar
            stackId={"a"}
            dataKey={"topBox"}
            fill={"#8884d8"}
            barSize={20}
            legendType={"none"}
          />
          <Bar
            stackId={"a"}
            dataKey={"topWhisker"}
            shape={<DotBar />}
            barSize={20}
            fill={"#fff"}
            legendType={"none"}
          />
          <Bar
            stackId={"a"}
            dataKey={"bar"}
            shape={<HorizonBar color="red" />}
            barSize={20}
            legendType={"none"}
          />
          <Line
            type="monotone"
            dataKey="average"
            name="Average Heart Rate"
            stroke="limegreen"
            strokeWidth={1}
          />
          <XAxis
            dataKey="timestamp"
            tick={{ fill: "white" }}
            tickLine={{ stroke: "white" }}
            tickFormatter={(tick) => formatXAxis(tick, timePeriod)}
          />
          <YAxis
            unit=" bpm"
            tick={{ fill: "white" }}
            tickLine={{ stroke: "white" }}
            type="number"
            domain={[50, 120]}
            allowDataOverflow={true}
          />
          <Legend></Legend>
          <Tooltip content={<CustomTooltip />} />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export { BoxPlotChart };
