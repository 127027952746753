import React, { useEffect, useState } from "react";

import "./WearableForm.scss";
import { WearableModel } from "../../../models/WearableModel";
import { MenuItem, Select, TextField } from "@material-ui/core";
import { WearableTypeModel } from "../../../models/WearableTypeModel";

interface Props {
  wearable: WearableModel;
  setWearable: (
    wearable: WearableModel | ((prevState: WearableModel) => WearableModel)
  ) => void;
  wearableTypes: WearableTypeModel[];
}

export default function WearableForm(props: Props) {
  const [macId, setMacId] = useState<string>(props.wearable.mac_id);
  const [wearableName, setWearableName] = useState<string>(
    props.wearable.wearable_name
  );

  useEffect(() => {
    props.setWearable((prevState) => ({
      ...prevState,
      mac_id: macId,
    }));
    // eslint-disable-next-line
  }, [macId]);

  useEffect(() => {
    props.setWearable((prevState) => ({
      ...prevState,
      wearable_name: wearableName,
    }));
    // eslint-disable-next-line
  }, [wearableName]);

  const handleModelChange = (event: { target: { value: any } }) => {
    props.setWearable((prevState) => ({
      ...prevState,
      wearable_model_id: event.target.value,
    }));
  };
  return (
    <div className="WearableForm">
      <div className="wearable_model">
        <h4 className="required">Wearable Model</h4>
        <Select
          fullWidth
          value={props.wearable.wearable_model_id}
          onChange={handleModelChange}
        >
          {props.wearableTypes.map((wearableType) => (
            <MenuItem
              key={wearableType.wearable_model_id}
              value={wearableType.wearable_model_id}
            >
              {wearableType.wearable_model_name}
            </MenuItem>
          ))}
        </Select>
      </div>

      <div className="mac_id">
        <h4 className="required">MAC ID</h4>
        <TextField
          fullWidth
          placeholder="MAC ID"
          size="medium"
          value={macId}
          onChange={(e) => {
            setMacId(e.target.value);
          }}
        />
      </div>

      <div className="wearable_name">
        <h4 className="required">Wearable Name</h4>
        <TextField
          fullWidth
          placeholder="Wearable Name"
          size="medium"
          value={wearableName}
          onChange={(e) => {
            setWearableName(e.target.value);
          }}
        />
      </div>
    </div>
  );
}
