import React, { useRef, useEffect, useState, useMemo } from "react";
import axios from "axios";
import Modal from "react-modal";
import socketIOClient from "socket.io-client";
import {
  Map as LeafletMap,
  TileLayer,
  ZoomControl,
  GeoJSON as GeoJSONElement,
} from "react-leaflet";
import { FeatureCollection } from "geojson";
import "./LiveMapView.scss";
import UserMarker from "../../components/UserMarker/UserMarker";
import safetyIncidentIcon from "../../resources/icons/safety_incident.svg";
import workersIcon from "../../resources/icons/workers.svg";
import outOfAreaIcon from "../../resources/icons/out_of_area_alert.svg";
import { Incident } from "../../models/Incident";
import IncidentMarker from "../../components/IncidentMarker/IncidentMarker";
import { IncidentType } from "../../models/IncidentType";
import { ReportModalState } from "../../pages/DailyDashboard/DailyDashboard";
import IncidentReport from "../../components/IncidentReport/IncidentReport";
import { BACKGROUND_COLOR, REACT_TOKEN_AUTH } from "../../constants/constants";
import { Status } from "../../models/Status";
import { UserModel } from "../../models/UserModel";
import { HazardType } from "../../models/HazardType";
import { ActivityTypeModel } from "../../models/ActivityTypeModel";
//import { WorkGoalModel } from "../../models/WorkGoalModel";
import { UserActivitySocketMessageModel } from "../../models/UserActivitySocketMessageModel";
import { dateToString } from "../../services/commonServices";
import { OutOfAreaAlertModel } from "../../models/OutOfAreaAlertModel";
import { UserIntensityModel } from "../../models/UserIntensityModel";
//import IntensityLevelChart from "../../components/IntensityLevelChart/IntensityLevelChart";
import { LatLngTuple } from "leaflet";
import { NavLink } from "react-router-dom";
import { getGeometryPositions } from "../../services/geoJsonService";
import LiveViewChart from "../../components/LiveViewChart/LiveViewChart";
import { BuildingModelWithLocation } from "../../models/BuildingModel";
import { UserActivityModel } from "../../models/UserActivityModel";
import { APP_SETTINGS, roundUp } from "../../helpers/Utils";
import { getUnixTimestamp } from "../../helpers/DateHelper";
import Switch from "@material-ui/core/Switch";

const timeDivider = APP_SETTINGS.displayHourly ? 3600 : 60;

const getActivityColorByName = (type: string) => {
  switch (type) {
    case "Sweeping":
      return "#f84be7";
    case "Blowing":
      return "#D1FF87";
    case "Raking":
      return "#36F3FF";
    case "Mopping":
      return "#ccffcc";
    case "Wiping":
      return "#f7ce65";
    case "Normal":
      return "#b3aebf";
    case "Sweeping Indoor":
      return "#faadff";
    case "Drain Cleaning":
      return "#99ff99";
    case "Fall":
      return "#ff5050";
    case "Tray push pull":
      return "#0000ff";
    case "Sit Stand":
      return "#660033";
    case "Wipe Vertical":
      return "#ff9900";
    case "Wipe Horizontal":
      return "#33cc33";
    case "Moving":
      return "#6600cc";
    default:
      return "#FFF";
  }
};
const getIntensityDetailsById = (type: number) => {
  switch (type) {
    case 0:
      return {
        fill: "#63F84A",
        name: "Normal",
      };
    case 1:
      return {
        fill: "#FECB46",
        name: "Moderate",
      };
    case 2:
      return {
        fill: "#FA2C2C",
        name: "High",
      };
    case 3:
      return {
        fill: "#FA2C2C",
        name: "High",
      };
    default:
      return {
        fill: "#000",
        name: "Other",
      };
  }
};
export default function LiveMapView({
  selectedZone,
  locationSearchCoordinates,
  selectedSite,
}: {
  selectedZone: number;
  locationSearchCoordinates: {
    lat: number;
    long: number;
  };
  selectedSite: number;
}) {
  const liveViewRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<LeafletMap>(null);
  const locationInfoRef = useRef<HTMLDivElement>(null);
  const [reportModalMode, setReportModalMode] =
    useState<ReportModalState>("none");
  const [statusTypes, setStatusTypes] = useState<Status[]>([]);
  const [incidents, setIncidents] = useState<Incident[]>([]);
  const [incidentTypesDict, setIncidentTypesDict] = useState<any>();
  const [incidentTypes, setIncidentTypes] = useState<IncidentType[]>([]);
  const [userDict, setUserDict] = useState<any>({});
  const [hazardTypes, setHazardTypes] = useState<HazardType[]>([]);
  const [selectedId, setSelectedId] = useState(0);
  const [geoJson, setGeoJson] = useState<FeatureCollection>();
  const [activityTypes, setActivityTypes] = useState<ActivityTypeModel[]>([]);
  const [activityTypesDict, setActivityTypesDict] = useState<any>({});
  const [cumulativeWorkGoals, setCumulativeWorkGoals] = useState<any>({});
  const [zoneUsers, setZoneUsers] = useState<UserModel[]>([]);
  const [outOfAreaAlerts, setOutOfAreaAlerts] = useState<OutOfAreaAlertModel[]>(
    []
  );
  const [selectedSiteId, setSelectedSiteId] = useState<number>(-1);
  const [intensityLevelArr, setIntensityLevelArr] = useState<number[]>([]);
  const [sites, setSites] = useState<BuildingModelWithLocation[]>([]);
  const [date] = useState<number>(getUnixTimestamp());
  const [showIncedents, setShowIncedents] = useState(true);

  useEffect(() => {
    const search = window.location.search,
      params = new URLSearchParams(search),
      siteIdFromUrl = params.get("location");
    if (siteIdFromUrl && parseInt(siteIdFromUrl) > 0)
      setSelectedSiteId(parseInt(siteIdFromUrl));
    if (selectedSite > 0) setSelectedSiteId(selectedSite);

    axios.get("/api/v2.0/data/incident-types").then((result) => {
      let incidentTypes: any = {};
      result.data.forEach(
        (d: IncidentType) => (incidentTypes[d.incident_type_id] = d.incident)
      );
      setIncidentTypesDict(incidentTypes);
    });
    (async () => {
      let results = await Promise.all([
        axios.get("/api/v2.0/data/hazard-types"),
        axios.get("/api/v2.0/data/status"),
        axios.get("/api/v2.0/data/incident-types"),
        axios.get("/api/v2.0/data/activity-types"),
        axios.get("/api/v2.0/building/location"),
      ]);
      setHazardTypes(results && results[0] && results[0].data);
      setStatusTypes(results && results[1] && results[1].data);
      setIncidentTypes(results && results[2] && results[2].data);
      setActivityTypes(results && results[3] && results[3].data);
      setSites(results && results[4] && results[4].data);
      if (!siteIdFromUrl || parseInt(siteIdFromUrl) <= 0 || selectedSite <= 0)
        setSelectedSiteId(results[4].data[0]?.building_id ?? -1);
    })();
  }, []);
  useEffect(() => {
    if (selectedSite > 0) setSelectedSiteId(selectedSite);
  }, [selectedSite]);
  useEffect(() => {
    if (selectedZone > 0) {
      setGeoJson(void 0);
      axios
        .get(`/api/v2.0/unit/zone?zone_id=${selectedZone}`)
        .then((res) => {
          setGeoJson(
            res?.data?.boundary &&
              res?.data?.boundary !== {} &&
              res?.data?.boundary.hasOwnProperty("features")
              ? res?.data?.boundary
              : {
                  type: "FeatureCollection",
                  features: [],
                }
          );
        })
        .catch((e) => {
          console.log(e);
        });
      (async () => {
        try {
          let zoneUsers = (
            await axios.get(
              `/api/v2.0/user/zone?zone_id=${selectedZone}&date=${dateToString(
                new Date()
              )}`
            )
          ).data;
          setZoneUsers(zoneUsers);
          if (zoneUsers.length) {
            let userDict: any = {};
            let userIds = "";
            for (let index = 0; index < zoneUsers.length; index++) {
              const user = zoneUsers[index];
              userDict[user.user_id.toLowerCase()] = {
                name: user.first_name + " " + user.last_name,
                activities: {},
                intensities: { 1: 0, 2: 0, 3: 0 },
                count_intensity_type_id_1: 0,
                count_intensity_type_id_2: 0,
                count_intensity_type_id_3: 0,
                count_intensity_type_id_4: 0,
                last_activity: {},
              };
              userIds += user.user_id.toLowerCase() + ",";
            }
            userIds = userIds.substring(0, userIds.length - 1);
            let todayStr = dateToString(new Date());
            let results = await Promise.all([
              axios.get(
                `/api/v2.0/ws/user_activity_cumulative?date=${todayStr}&userIds=${userIds}&startTime=00:00&endTime=23:59:59`
              ),
              axios.get(
                `/api/v2.0/ws/user_out_of_area_alerts?date=${todayStr}&userIds=${userIds}&startTime=00:00&endTime=23:59:59`
              ),
              axios.get(
                `/api/v2.0/ws/user_intensity_cumulative?date=${todayStr}&userIds=${userIds}&startTime=00:00&endTime=23:59:59`
              ),
              axios.get(
                `/api/v2.0/ws/user_last_activity?date=${todayStr}&userIds=${userIds}&startTime=00:00&endTime=23:59:59`
              ),
            ]);
            setOutOfAreaAlerts(results[1].data);
            results[0].data &&
              results[0].data.forEach((userActivity: UserActivityModel) => {
                userDict[userActivity.user_id.toLowerCase()].activities[
                  userActivity.activity_type_id
                ] = {
                  activity_duration:
                    userActivity.activity_duration / timeDivider ?? 0,
                  hours_per_day: 0,
                };
              });
            results[2].data &&
              results[2].data.forEach((userIntensity: UserIntensityModel) => {
                const user = userDict[userIntensity.user_id.toLowerCase()];
                user.intensities[1] = user.intensities[1]
                  ? user.intensities[1] +
                    userIntensity.count_intensity_type_id_1
                  : userIntensity.count_intensity_type_id_1;
                user.intensities[2] = user.intensities[2]
                  ? user.intensities[2] +
                    userIntensity.count_intensity_type_id_2
                  : userIntensity.count_intensity_type_id_2;
                user.intensities[3] = user.intensities[3]
                  ? user.intensities[3] +
                    userIntensity.count_intensity_type_id_3
                  : userIntensity.count_intensity_type_id_3;
              });

            results[3].data &&
              results[3].data.forEach((userActivity: UserActivityModel) => {
                userDict[userActivity.user_id.toLowerCase()].last_activity = {
                  activity_type_id: userActivity.activity_type_id,
                  battery_level: userActivity.battery_level,
                  intensity_type_id: parseInt(
                    userActivity.intensity_type_id.toString()
                  ),
                  lat: userActivity.lat,
                  long: userActivity.long,
                  timestamp: userActivity.timestamp,
                  user_id: userActivity.user_id,
                  in_zone: userActivity.in_zone ?? false,
                };
              });
            setUserDict(userDict);
          } else {
            setUserDict({});
          }
        } catch (error) {
          console.error(error);
        }
      })();
    }
  }, [selectedZone]);

  useEffect(() => {
    if (activityTypes.length) {
      let dict: any = {};
      activityTypes.forEach((activityType) => {
        dict[activityType.activity_type_id] = activityType.activity_type;
      });
      setActivityTypesDict(dict);
    }
  }, [activityTypes]);

  // Start listening to socket
  useEffect(() => {
    const socket = socketIOClient("/", {
      query: {
        token: JSON.parse(localStorage.getItem(REACT_TOKEN_AUTH) ?? "")
          .accessToken,
      },
    });
    socket.on(
      "user_activity",
      (userActivity: UserActivitySocketMessageModel) => {
        if (
          userActivity &&
          userActivity.user_id &&
          userDict[userActivity.user_id.toLowerCase()] &&
          new Date(userActivity.last_activity.timestamp).toDateString() ===
            new Date().toDateString()
        ) {
          console.log(userActivity);

          setUserDict((x: any) => {
            let userDictCopy = { ...x };
            userDictCopy[userActivity.user_id.toLowerCase()].last_activity = {
              ...userActivity.last_activity,
              activity_type:
                activityTypesDict[
                  userActivity.last_activity.activity_type_id
                ] ?? "Other",
            };
            userDictCopy[userActivity.user_id.toLowerCase()].out_of_area =
              userActivity.out_of_area;
            Object.keys(userActivity.cumulative_activity).forEach(
              (key: string) => {
                const activity = userActivity.cumulative_activity[key];
                if (userDictCopy[userActivity.user_id.toLowerCase()])
                  if (
                    userDictCopy[userActivity.user_id.toLowerCase()].activities[
                      activity.activity_type_id
                    ]
                  ) {
                    userDictCopy[userActivity.user_id.toLowerCase()].activities[
                      activity.activity_type_id
                    ].activity_duration +=
                      Number(activity.activity_duration) / timeDivider ?? 0;
                  } else {
                    userDictCopy[userActivity.user_id.toLowerCase()].activities[
                      activity.activity_type_id
                    ] = {
                      activity_duration:
                        Number(activity.activity_duration) / timeDivider ?? 0,
                      hours_per_day: 0,
                    };
                  }
              }
            );

            Object.keys(userActivity.cumulative_intensity).forEach(
              (key: string) => {
                if (
                  userActivity?.last_activity?.intensity_type_id.toString() ===
                  key
                ) {
                  if (
                    userDictCopy[userActivity.user_id.toLowerCase()]
                      .intensities[key]
                  ) {
                    userDictCopy[
                      userActivity.user_id.toLowerCase()
                    ].intensities[key] += Number(
                      userActivity?.last_activity?.activity_duration ?? 0
                    );
                  } else {
                    userDictCopy[
                      userActivity.user_id.toLowerCase()
                    ].intensities[key] = Number(
                      userActivity?.last_activity?.activity_duration ?? 0
                    );
                  }
                }
              }
            );

            return { ...userDictCopy };
          });
        }
      }
    );
    socket.on("user_safety_incidents", (incident: Incident) => {
      console.log(incident);
      if (
        incident &&
        incident.user_id &&
        incident.incident_dt &&
        userDict[incident.user_id.toLowerCase()]
      ) {
        setIncidents((x: Incident[]) => {
          return [...x, incident];
        });
      }
    });
    socket.on("user_out_of_area", (outOfAreaAlert: OutOfAreaAlertModel) => {
      console.log(outOfAreaAlert);

      if (outOfAreaAlert && userDict[outOfAreaAlert.user_id.toLowerCase()]) {
        setOutOfAreaAlerts((alerts: OutOfAreaAlertModel[]) => {
          let existingAlert = alerts.find(
            (alert) =>
              alert.user_id.toLowerCase() ===
              outOfAreaAlert.user_id.toLowerCase()
          );
          if (existingAlert) {
            existingAlert.lat = outOfAreaAlert.lat;
            existingAlert.long = outOfAreaAlert.long;
            existingAlert.out_of_area_duration =
              outOfAreaAlert.out_of_area_duration;
            existingAlert.timestamp = outOfAreaAlert.timestamp;
            existingAlert.datetime = outOfAreaAlert.datetime;
            return [
              ...alerts.filter(
                (x) =>
                  x.user_id.toLowerCase() !==
                  outOfAreaAlert.user_id.toLowerCase()
              ),
              existingAlert,
            ];
          } else {
            return [...alerts, outOfAreaAlert];
          }
        });
      }
    });
    return () => {
      socket.disconnect();
    };
  }, [activityTypesDict, userDict]);

  useMemo(() => {
    setIntensityLevelArr(
      Object.keys(userDict).reduce(
        (prev, userId) => {
          const user = userDict[userId];
          if (user.intensities) {
            Object.keys(user.intensities).forEach((key) => {
              prev[Number(key) - 1] +=
                user.intensities[Number(key)] / timeDivider ?? 0;
            });
          }
          return prev;
        },
        [0, 0, 0]
      )
    );
  }, [userDict]);

  useEffect(() => {
    let workGoalsCumulative: any = {};
    Object.keys(userDict).forEach((userId) => {
      Object.keys(userDict[userId].activities).reduce((prev, activityId) => {
        const current = userDict[userId].activities[activityId];
        if (prev[activityId]) {
          prev[activityId].activity_duration += current.activity_duration;
          prev[activityId].hours_per_day += current.hours_per_day
            ? current.hours_per_day
            : 0;
        } else {
          prev[activityId] = {};
          prev[activityId].activity_type =
            activityTypesDict[activityId] ?? "Other";
          prev[activityId].activity_duration = current.activity_duration;
          prev[activityId].hours_per_day = current.hours_per_day
            ? current.hours_per_day
            : 0;
        }
        return prev;
      }, workGoalsCumulative);
    });
    setCumulativeWorkGoals(workGoalsCumulative);
  }, [userDict, activityTypesDict]);

  useEffect(() => {
    if (zoneUsers.length > 0) {
      axios
        .get(`/api/v2.0/incident?incident_dt=${dateToString(new Date())}`)
        .then((res) => {
          let incidents = res.data.filter(
            (x: Incident) =>
              !!zoneUsers.find(
                (user) => x.user_id.toLowerCase() === user.user_id.toLowerCase()
              )
          );
          setIncidents(incidents);
        });
    }
  }, [zoneUsers]);

  useEffect(() => {
    if (
      Number.isFinite(locationSearchCoordinates.lat) &&
      Number.isFinite(locationSearchCoordinates.long)
    ) {
      const leafletElement = mapRef.current?.leafletElement;
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      leafletElement?.flyTo(
        [locationSearchCoordinates.lat, locationSearchCoordinates.long],
        Math.max(leafletElement?.getZoom() ?? 0, 17)
      );
    }
  }, [locationSearchCoordinates]);

  useEffect(() => {
    const positions = geoJson?.features.flatMap((feature) =>
      getGeometryPositions(feature.geometry)
    );
    const longs = positions?.map((pos) => pos[0]);
    const lats = positions?.map((pos) => pos[1]);
    if (lats?.length && longs?.length && liveViewRef.current?.clientHeight) {
      const unobstructedHeight =
        liveViewRef.current.clientHeight -
        (locationInfoRef.current?.clientHeight ?? 0);
      const maxLat = Math.max(...lats);
      const minLat = Math.min(...lats);
      const correctedLatSpan =
        ((maxLat - minLat) * liveViewRef.current.clientHeight) /
        unobstructedHeight;
      const correctedMinLat = maxLat - correctedLatSpan;
      const from: LatLngTuple = [correctedMinLat, Math.min(...longs)];
      const to: LatLngTuple = [maxLat, Math.max(...longs)];
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      mapRef.current?.leafletElement.fitBounds([from, to]);
    }
  }, [geoJson]);

  return (
    <div ref={liveViewRef} className="LiveMapView">
      <LeafletMap
        ref={mapRef}
        center={[1.3521, 103.8198]}
        zoom={11.75}
        zoomSnap={0.25}
        zoomControl={false}
        maxBounds={[
          [1.155, 103.585],
          [1.483, 104.105],
        ]}
      >
        <TileLayer
          maxNativeZoom={19}
          maxZoom={23}
          minZoom={11.75}
          attribution='New OneMap | Map data &copy; contributors, <a href="http://SLA.gov.sg">Singapore Land Authority</a>'
          url="https://maps-{s}.onemap.sg/v3/Default/{z}/{x}/{y}.png"
        />
        <ZoomControl position="topright"></ZoomControl>
        {showIncedents &&
          incidents.map(
            (incident: Incident) =>
              incident.lat &&
              incident.lng && (
                <IncidentMarker
                  key={incident.incident_id}
                  incidentDetails={incident}
                  incidentType={incidentTypesDict[incident.incident_type_id]}
                  onClick={() => {
                    setSelectedId(incident.incident_id);
                    setReportModalMode("view");
                  }}
                ></IncidentMarker>
              )
          )}
        {Object.keys(userDict).map((x: any, i: number) => {
          return (
            <UserMarker
              key={i}
              userActivityDetails={userDict[x]?.last_activity ?? {}}
              userName={userDict[x]?.name ?? ""}
              userActivityTypesDict={activityTypesDict}
              isHidden={false}
              userOutOfAreaAlertDetails={
                outOfAreaAlerts
                  .filter((y) => y.user_id.toLowerCase() === x.toLowerCase())
                  .slice(-1)[0] ?? {}
              }
              mapRef={mapRef}
            ></UserMarker>
          );
        })}

        {geoJson && <GeoJSONElement data={geoJson}></GeoJSONElement>}
      </LeafletMap>

      {selectedZone ? (
        <div ref={locationInfoRef} className="location-info">
          <div className="item">
            <div>
              <NavLink className="title" to={`/daily-dashboard?location=${selectedSiteId}&date=${date}`}>
                Safety Incidents
                <br></br>
                <div style={{fontSize:"small"}}>Heart Rate & Fall</div>
              </NavLink>
              <Switch
                className="card-switch"
                color="secondary"
                defaultChecked
                value={showIncedents}
                onChange={(_e, val) => {
                  setShowIncedents(val);
                }}
              />
              
            </div>

            <div className="details">
              <div className="count">{incidents.length}</div>
              <div className="icon">
                <img src={safetyIncidentIcon} alt="Safety Incidents"></img>
              </div>
            </div>
          </div>
          <div className="item">
            <NavLink
              to={
                `/live/` +
                `${
                  sites.filter((x) => x.building_id === selectedSiteId).length >
                  0
                    ? sites.filter((x) => x.building_id === selectedSiteId)[0]
                        .name
                    : ""
                }` +
                `/workshift?location=${selectedSiteId}&zoneid=${selectedZone}`
              }
              className="title"
            >
              Out of Zone Alerts
              <br></br>
                <div style={{fontSize:"small"}}>More than 10 minutes out of area</div>
            </NavLink>

            <div className="details">
              <div className="count">{outOfAreaAlerts.length ?? 0}</div>
              <div className="icon">
                <img src={outOfAreaIcon} alt="Out Of Area"></img>
              </div>
            </div>
          </div>
          <div className="item">
            <NavLink
              to={`/daily-ops/overview/employee-status?location=${selectedSiteId}&date=${date}`}
              className="title"
            >
              Total Workers
            </NavLink>
            <div className="details-3bar">
              <div className="count n-workers">
                <div className="number-label">Total</div>
                <div className="number">
                  {Object.keys(userDict).reduce(
                    (prev, current) =>
                      prev +
                      (userDict[current].last_activity &&
                      Number.isFinite(userDict[current].last_activity.lat)
                        ? 1
                        : 0),
                    0
                  )}
                </div>
                <hr />
                <div style={{ color: "#2CAEFC" }} className="number-label">
                  In Zone
                </div>
                <div className="number">
                  {Object.keys(userDict).reduce(
                    (prev, current) =>
                      prev +
                      (userDict[current].last_activity &&
                      Number.isFinite(userDict[current].last_activity.lat) &&
                      userDict[current].last_activity.in_zone
                        ? 1
                        : 0),
                    0
                  )}{" "}
                </div>
                <div style={{ color: "#F7D64C" }} className="number-label">
                  Out of Zone
                </div>
                <div className="number">
                  {Object.keys(userDict).reduce(
                    (prev, current) =>
                      prev +
                      (userDict[current].last_activity &&
                      Number.isFinite(userDict[current].last_activity.lat) &&
                      !userDict[current].last_activity.in_zone
                        ? 1
                        : 0),
                    0
                  )}{" "}
                </div>
              </div>
              <div className="icon">
                <img src={workersIcon} alt="Workers"></img>
              </div>
            </div>
          </div>
          <div className="item">
            <NavLink
              to={`/daily-ops/overview/activity-hours?location=${selectedSiteId}&date=${date}`}
              className="title"
            >
              Activity Hours
            </NavLink>
            <LiveViewChart
              centerSubText=""
              centerText=""
              chartData={
                Object.values(cumulativeWorkGoals).filter(
                  (x: any) => x.activity_duration > 0
                ).length > 0
                  ? Object.values(cumulativeWorkGoals).map((x: any) => {
                      return {
                        name: `${
                          x.activity_type
                        } / ${x.activity_duration.toFixed(1)} ${
                          APP_SETTINGS.displayHourly ? " hr" : " min"
                        }`,
                        value: roundUp(x.activity_duration, 1),
                        fill: getActivityColorByName(x.activity_type),
                      };
                    })
                  : []
              }
            ></LiveViewChart>
          </div>
          <div className="item">
            <NavLink
              to={`/daily-ops/overview/exertion-hours?location=${selectedSiteId}&date=${date}`}
              className="title"
            >
              Physical Exertion
            </NavLink>
            <LiveViewChart
              centerSubText=""
              centerText=""
              chartData={intensityLevelArr.map((x, index) => {
                return {
                  name: `${getIntensityDetailsById(index).name} / ${x.toFixed(
                    1
                  )} ${APP_SETTINGS.displayHourly ? " hr" : " min"}`,
                  value: roundUp(x, 1),
                  fill: getIntensityDetailsById(index).fill,
                };
              })}
            ></LiveViewChart>
          </div>
        </div>
      ) : null}
      <Modal
        isOpen={reportModalMode !== "none"}
        onRequestClose={() => setReportModalMode("none")}
        ariaHideApp={false}
        style={{
          content: {
            borderRadius: "20px",
            padding: "15px",
            left: "20px",
            right: "20px",
            top: "20px",
            bottom: "20px",
            backgroundColor: BACKGROUND_COLOR,
          },
          overlay: { zIndex: 1000 },
        }}
      >
        <IncidentReport
          id={selectedId}
          mode={reportModalMode}
          setMode={setReportModalMode}
          hazardTypes={hazardTypes}
          statusTypes={statusTypes}
          incidentTypes={incidentTypes}
          users={zoneUsers}
        ></IncidentReport>
      </Modal>
    </div>
  );
}
