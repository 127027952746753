import React, { useEffect, useState } from "react";
import Autocomplete, {
  AutocompleteChangeReason,
} from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

import "./TeamForm.scss";
import { UserModel } from "../../models/UserModel";
import { TeamModel } from "../../models/TeamModel";

interface Props {
  users: UserModel[];
  team: TeamModel;
  setTeam: (team: TeamModel | ((prevState: TeamModel) => TeamModel)) => void;
}

export default function TeamForm(props: Props) {
  const [teamName, setTeamName] = useState<string>(props.team.team_name);

  useEffect(() => {
    props.setTeam((prevState) => ({
      ...prevState,
      team_name: teamName,
    }));
    // eslint-disable-next-line
  }, [teamName]);

  const handleSelectedFirstResponder = (
    _event: any,
    value: UserModel[],
    _reason: AutocompleteChangeReason,
    _details: any
  ) => {
    props.setTeam((prevState) => ({
      ...prevState,
      first_responders: value,
    }));
  };

  const handleSelectedEscalationPoint = (
    _event: any,
    value: UserModel[],
    _reason: AutocompleteChangeReason,
    _details: any
  ) => {
    props.setTeam((prevState) => ({
      ...prevState,
      escalation_points: value,
    }));
  };

  return (
    <div className="TeamForm">
      <div className="teamName">
        <h4 className="required">Team Name:</h4>
        <TextField
          placeholder="Team Name"
          size="medium"
          role="team-name"
          value={teamName}
          onChange={(e) => {
            setTeamName(e.target.value);
          }}
        />
      </div>

      <div className="firstResponders">
        <h5 className="required">First Responders</h5>
        <Autocomplete
          multiple
          value={props.team.first_responders}
          onChange={handleSelectedFirstResponder}
          filterSelectedOptions
          options={props.users}
          getOptionLabel={(option) => option.email}
          getOptionSelected={(option, value) => {
            return option.user_id === value.user_id;
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              placeholder="Select User(s)"
            />
          )}
        />
      </div>
      <div className="escalationPoints">
        <h5 className="required">Escalation Points</h5>
        <Autocomplete
          multiple
          value={props.team.escalation_points}
          onChange={handleSelectedEscalationPoint}
          filterSelectedOptions
          options={props.users}
          getOptionLabel={(option) => option.email}
          getOptionSelected={(option, value) => {
            return option.user_id === value.user_id;
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              placeholder="Select User(s)"
            />
          )}
        />
      </div>
    </div>
  );
}
