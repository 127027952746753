import axios from "axios";
import { REACT_TOKEN_AUTH } from "../constants/constants";

export const AuthService = {
  isAuthenticated: () => {
    return !!localStorage.getItem(REACT_TOKEN_AUTH);
  },
  getLoggedInUserId: () => {
    let token = JSON.parse(localStorage.getItem(REACT_TOKEN_AUTH));
    if (token) return token.user.user_id;
    else return "";
  },
  getLoggedInUserName: () => {
    let token = JSON.parse(localStorage.getItem(REACT_TOKEN_AUTH));
    if (token) return token.user.first_name + " " + token.user.last_name;
    else return "";
  },
  isAdmin: () => {
    let token = JSON.parse(localStorage.getItem(REACT_TOKEN_AUTH));
    if (token && token.user)
      return token.user.role_id === 2 || token.user.role_id === 3;
    else return false;
  },
  hasSettingsAccess: () => {
    let token = JSON.parse(localStorage.getItem(REACT_TOKEN_AUTH));
    if (token && token.user)
      return token.user.role_id === 2 || token.user.role_id === 3;
    else return false;
  },

  authenticate: async (userName, password, successCb, errorCallback) => {
    try {
      const loginResult = await axios.post(`/api/v2.0/auth/login`, {
        email: userName,
        password: password,
      });
      if (loginResult.data && loginResult.data.accessToken) {
        localStorage.setItem(
          REACT_TOKEN_AUTH,
          JSON.stringify(loginResult.data)
        );
        successCb();
      } else {
        errorCallback();
      }
    } catch (error) {
      console.error(error);
      errorCallback();
    }
  },
  tokenAuthenticate: async (token, successCb, errorCallback) => {
    try {
      const loginResult = await axios.post(`/api/v2.0/auth/login`, {
        accessToken: token,
      });
      if (loginResult.data && loginResult.data.accessToken) {
        localStorage.setItem(
          REACT_TOKEN_AUTH,
          JSON.stringify(loginResult.data)
        );
        successCb();
      } else {
        errorCallback();
      }
    } catch (error) {
      console.error(error);
      errorCallback();
    }
  },
  signout(cb) {
    localStorage.setItem(REACT_TOKEN_AUTH, "");
  },
};
