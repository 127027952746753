import React from "react";
import "./ProfileHealth.scss";
import NoData from "../../components/NoData/NoData";
import { BoxPlotChart } from "../../components/BoxPlotChart/BoxPlotChart";
import Loading from "../../components/Loading/Loading";
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Line,
  Legend,
  ComposedChart,
  Bar,
  Tooltip,
} from "recharts";
import moment from "moment";

const granularityTimeFormat: any = {
  "24h": "HH:mm zz",
  "7d": "DD MMM",
  "30d": "DD MMM",
  "90d": "DD MMM",
  "365d": "MMM YYYY",
};
const exertionMap: any = {
  1: "Low",
  2: "Medium",
  3: "High",
};
const formatXAxis = (tickFormat: any, timePeriodSelected: any) => {
  return moment(tickFormat).format(
    granularityTimeFormat[timePeriodSelected] ?? "DD MMM"
  );
};

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <>
          <div className="header">
            <h4>Avg Exertion</h4>
            <h5> @ {payload[0].payload.timestamp ?? ""}</h5>
            <h4>{exertionMap[payload[0].payload.avg] ?? ""}</h4>
          </div>
        </>
      </div>
    );
  }

  return null;
};
export const ProfileHealth = ({
  data,
  timePeriod,
  setTimePeriod,
}: {
  data: any;
  timePeriod: string;
  setTimePeriod: any;
}) => {
  return (
    <div className="ProfileHealth">
      <div className="selection">
        <select
          onChange={(e) => {
            setTimePeriod(e.target.value);
          }}
          value={timePeriod}
        >
          <option value="24h">Last 24 hour</option>
          <option selected value="7d">
            Last 7 days
          </option>
          <option value="30d">Last 30 days</option>
          <option value="365d">Last year</option>
        </select>
      </div>
      <div className="section">
        <h3>Heart Rate Trend</h3>
        {data.isHrDataLoading ? (
          <Loading></Loading>
        ) : (
          <>
            {data.hrData.length === 0 && <NoData></NoData>}
            {data.hrData && data.hrData.length > 0 && (
              <BoxPlotChart
                boxPlots={data.hrData}
                timePeriod={timePeriod}
                chartType="HR"
              ></BoxPlotChart>
            )}
          </>
        )}
      </div>
      <div className="section">
        <h3>Physical Exertion Trend</h3>
        {data.isExertionDataLoading ? (
          <Loading></Loading>
        ) : (
          <>
            {data.exertionData.length === 0 && <NoData></NoData>}
            {data.exertionData && data.exertionData.length > 0 && (
              <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                  data={data.exertionData}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 50,
                  }}
                >
                  <CartesianGrid stroke="#696969" />
                  <Bar
                    stackId={"a"}
                    dataKey={"min"}
                    barSize={20}
                    fill={"none"}
                    legendType={"none"}
                  />
                  <defs>
                    <linearGradient
                      id="colorUv"
                      x1="0%"
                      y1="0%"
                      x2="0%"
                      y2="100%"
                    >
                      <stop offset="0%" stopColor="red" />
                      <stop offset="50%" stopColor="orange" />
                      <stop offset="100%" stopColor="green" />
                    </linearGradient>
                  </defs>
                  <Line
                    type="monotone"
                    dataKey="avg"
                    name="Average Exertion"
                    stroke={
                      new Set(data.exertionData.map((x: { avg: any }) => x.avg))
                        .size == 1
                        ? "orange"
                        : "url(#colorUv)"
                    }
                    strokeWidth={2}
                  ></Line>
                  <XAxis
                    dataKey="timestamp"
                    tick={{ fill: "white" }}
                    tickFormatter={(tick) => formatXAxis(tick, timePeriod)}
                    tickLine={{ stroke: "white" }}
                  />
                  <YAxis
                    unit=""
                    tick={{ fill: "white" }}
                    ticks={[0, 1, 2, 3]}
                    tickFormatter={(tick) => exertionMap[tick] ?? ""}
                    tickLine={{ stroke: "white" }}
                    type="number"
                    domain={[0, 3]}
                  />
                  <Legend></Legend>
                  <Tooltip content={<CustomTooltip />} />
                </ComposedChart>
              </ResponsiveContainer>
            )}
          </>
        )}
      </div>
    </div>
  );
};
