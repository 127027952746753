export const roundUp = (num: number, decimalPlaces: number = 1): number => {
  if (num) {
    const roundedUp = Math.round(num * Math.pow(10, decimalPlaces));
    const pow = Math.pow(10, decimalPlaces);
    return roundedUp / pow;
  } else {
    return 0.0;
  }
};

export const APP_SETTINGS: Record<string, any> = {
  displayHourly: true, ///default is hourly, if not it will be minute
};

declare global {
  interface Array<T> {
    except(this: T[], obj: any): Array<T>;
    intersect(this: T[], obj: any): Array<T>;
  }
}

Array.prototype.except = function (
  other: any[],
  idSelector: any = (obj: any) => obj
) {
  const otherSet = new Set([...other.map(idSelector)]);
  const difference = new Set(
    this.filter((object) => !otherSet.has(idSelector(object)))
  );
  return [...difference];
};
Array.prototype.intersect = function (
  other: any[],
  idSelector: any = (obj: any) => obj
) {
  const otherSet = new Set([...other.map(idSelector)]);
  const intersection = new Set(
    this.filter((object) => otherSet.has(idSelector(object)))
  );
  return [...intersection];
};
