import React from "react";

import "./LocationSecondForm.scss";

import { MenuItem, Select, TextField } from "@material-ui/core";
import { TeamModel } from "../../../models/TeamModel";

interface Props {
  index: number;
  origin: string;
  teams: TeamModel[];
  handleName: (name: string, key: number) => void;
  handleDescription: (desc: string, key: number) => void;
  handleTeamId: (id: number, key: number) => void;
  handleRtspURLs?: (url: string, key: number) => void;
}

export default function LocationSecondForm(props: Props) {
  const handleTeamChange = (event: { target: { value: any } }) => {
    props.handleTeamId(event.target.value, props.index);
  };
  return (
    <div className="LocationSecondForm">
      <div className="name">
        <h4 className="required">{props.origin + " "} Name</h4>
        <TextField
          fullWidth
          placeholder={props.origin + " Name"}
          size="medium"
          onChange={(e) => {
            props.handleName(e.target.value, props.index);
          }}
        />
      </div>

      <div className="desc">
        <h4>Description</h4>
        <TextField
          fullWidth
          placeholder="Description"
          size="medium"
          onChange={(e) => {
            props.handleDescription(e.target.value, props.index);
          }}
        />
      </div>

      {props.handleRtspURLs !== undefined ? (
        <div className="url">
          <h4 className="required">RTSP URL</h4>
          <TextField
            fullWidth
            onChange={(e) => {
              props.handleRtspURLs!(e.target.value, props.index);
            }}
          />
        </div>
      ) : null}

      <div className="team">
        <h4>Select Team</h4>
        <Select fullWidth onChange={handleTeamChange}>
          {props.teams.map((team) => (
            <MenuItem key={team.team_id} value={team.team_id}>
              {team.team_name}
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
}
