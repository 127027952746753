import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "./SiteBreadcrumb.scss";

export default function SiteBreadcrumb() {
  const [paths, setPaths] = useState<string[]>([]);
  const history = useHistory();

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname && location.pathname.indexOf("/") !== -1) {
        setPaths(location.pathname.split("/").filter((x) => x));
      }
    });
  }, [history]);
  useEffect(() => {
    const pathName = window.location.pathname;
    if (pathName && pathName.indexOf("/") !== -1) {
      setPaths(pathName.split("/").filter((x) => x));
    }
  }, []);
  ///Alternate mappings
  const menuKeyMapping: any = {
    "daily-ops": "Operations",
    "employee-status": "Employee Status",
    "in-zone": "In Zone",
    "out-zone": "Out Zone",
    "activity-hours": "Activity Hours",
    "exertion-hours": "Physical Exertion",
    "heart-rate-hours": "Heart Rate",
    "live-view": "Live View",
    "daily-dashboard": "Daily Dashboard",
    "monthly-dashboard": "Monthly Dashboard",
    "yearly-dashboard": "Yearly Dashboard",
    "vulcan-store": "Vulcan Store",
    workshift: "Out of Zone Alerts",
  };
  function toTitleCase(str: string) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  return (
    <div className="Breadcrumb">
      {paths.map((x: string, i) => {
        return i === paths.length - 1 ? (
          <strong key={i}>
            {menuKeyMapping[x] ?? toTitleCase(decodeURI(x))}
          </strong>
        ) : (
          <Link
            key={i}
            className="link"
            to={
              "/" +
              paths
                .slice(0, i + 1)
                .map((x) => x)
                .join("/") +
              window.location.search
            }
          >
            {(menuKeyMapping[x] ?? toTitleCase(decodeURI(x))) + " / "}
          </Link>
        );
      })}
    </div>
  );
}
