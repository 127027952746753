import React from "react";
import { Link } from "react-router-dom";
import "./IncidentNumberSummary.scss";

export default function IncidentNumberSummary({
  icon,
  numberOfIncedents = 0,
  incedentType = "",
  loading = false,
  url = "",
}: {
  icon: any;
  numberOfIncedents: number;
  incedentType: string;
  loading: boolean;
  url?: string;
}) {
  return (
    <div className="IncidentNumberSummary">
      <div className="icon-card">
        {url ? (
          <Link to={url}>
            <img className="icon" src={icon} alt=""></img>
          </Link>
        ) : (
          <img className="icon" src={icon} alt=""></img>
        )}
      </div>
      <div className="number-summary">
        <div className="count">{loading ? "..." : numberOfIncedents}</div>
        <div className="text">{incedentType}</div>
      </div>
    </div>
  );
}
