import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Modal from "react-modal";
import axios from "axios";
import "./QRCode.scss";
import { dateTimeToString } from "../../services/commonServices";
import { toast } from "react-toastify";
interface Props {
  user_id: string;
}

const QRCode = ({ user_id }: Props) => {
  const [qrCodeImageUrl, setQRCodeImageUrl] = useState("");
  const [qrExpiryDate, setQrExpiryDate] = useState<Date | null>();

  const getQRCode = () => {
    axios
      .get(`api/v2.0/user/qrcode?user_id=${user_id}`)
      .then((response: { data: { qrcode: string; exp: Date } }) => {
        setQRCodeImageUrl(response.data.qrcode);
        setQrExpiryDate(new Date(response.data.exp));
      })
      .catch((error) => {
        console.log(error);
        toast["error"]("Error getting QR Code");
        setQRCodeImageUrl("");
        setQrExpiryDate(null);
      });
  };
  return (
    <div className="QRCodeButton">
      <button onClick={() => getQRCode()} data-testid="qr_button">
        <FontAwesomeIcon icon={faQrcode} title="Get my QR code" />
      </button>

      <Modal
        isOpen={!!qrCodeImageUrl}
        onRequestClose={() => setQRCodeImageUrl("")}
        ariaHideApp={false}
        style={{
          content: {
            borderRadius: "20px",
            padding: "15px",
            left: "20px",
            right: "20px",
            top: "20px",
            bottom: "20px",
          },
          overlay: { zIndex: 2 },
        }}
        // appElement={document.getElementById('app')}
      >
        <div className="QRCode">
          <span className="header">User QR Code</span>
          <button className="close" onClick={() => setQRCodeImageUrl("")}>
            Close
          </button>
          <section>
            <img src={qrCodeImageUrl} alt="QR Code" />
            <div>
              Expires on: {qrExpiryDate ? dateTimeToString(qrExpiryDate) : ""}
            </div>
          </section>
        </div>
      </Modal>
    </div>
  );
};

export default QRCode;
