import React, { useEffect, useState } from "react";
import axios from "axios";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "./User.scss";
import {
  REGEXP_EMAIL,
  REGEXP_NAME,
  REGEXP_NUM,
  REGEXP_USER_PASSWORD,
  // REGEXP_USER_PASSWORD,
} from "../../constants/regularExpressions";
import { MenuItem, Select, TextField } from "@material-ui/core";
interface Props {
  toast: any;
}
const User: React.FC<Props & RouteComponentProps> = ({ toast, history }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [role, setRole] = useState("Supervisor");
  const [roleId, setRoleId] = useState(2);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [personalEmail, setPersonalEmail] = useState("");
  const [emergencyContactName, setEmergencyContactName] = useState("");
  const [emergencyContactRelationship, setEmergencyContactRelationship] =
    useState("");
  const [emergencyContactPhoneNumber, setEmergencyContactPhoneNumber] =
    useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    if (role === "Staff") {
      setRoleId(1);
    } else if (role === "Supervisor") {
      setRoleId(2);
    } else if (role === "Admin") {
      setRoleId(3);
    }
  }, [role]);

  const onAddUser = (e: any) => {
    e.preventDefault();

    console.log({
      first_name: firstName,
      last_name: lastName,
      email: email,
      personal_email: personalEmail,
      phone_no: phoneNumber,
      password: password,
      emergency_contact_name: emergencyContactName,
      emergency_contact_relationship: emergencyContactRelationship,
      emergency_contact_phone: emergencyContactPhoneNumber,
      role_id: roleId,
      team_id: null,
    });
    let validations: string[] = [];

    if (!firstName) validations.push("First name");
    else {
      if (firstName.length < 3 || firstName.length > 20) {
        toast["warning"](
          `The length of the First Name should be between 3 - 20 characters.`
        );
        return;
      }
      if (!REGEXP_NAME.test(firstName)) {
        toast["warning"](`First Name has invalid characters.`);
        return;
      }
    }
    if (!lastName) validations.push("Last name");
    else {
      if (lastName.length < 3 || lastName.length > 20) {
        toast["warning"](
          `The length of the Last Name should be between 3 - 20 characters.`
        );
        return;
      }
      if (!REGEXP_NAME.test(lastName)) {
        toast["warning"](`Last Name has invalid characters.`);
        return;
      }
    }

    if (!email) validations.push("Work Email");
    else {
      if (!REGEXP_EMAIL.test(email)) {
        toast["warning"](` Work Email has invalid characters.`);
        return;
      }
    }
    // if (!personalEmail) validations.push("Personal Email");
    // else {
    //   if (!REGEXP_EMAIL.test(personalEmail)) {
    //     toast["warning"](`Personal Email has invalid characters.`);
    //     return;
    //   }
    // }
    if (personalEmail) {
      if (!REGEXP_EMAIL.test(personalEmail)) {
        toast["warning"](`Personal Email has invalid characters.`);
        return;
      }
    }
    if (!phoneNumber) {
      validations.push("Phone Number");
    } else {
      if (!REGEXP_NUM.test(phoneNumber)) {
        toast["warning"](`Phone Number has invalid characters.`);
        return;
      }
    }

    // if (!emergencyContactName) validations.push("Emergency Contact Name");
    // if (!emergencyContactRelationship)
    //   validations.push("Emergency Contact Relationship");
    // if (!emergencyContactPhoneNumber) {
    //   validations.push("Emergency Contact Phone Number");
    // } else {
    //   if (!REGEXP_NUM.test(emergencyContactPhoneNumber)) {
    //     toast["warning"](`Emergency Phone Number has invalid characters.`);
    //     return;
    //   }
    // }
    if (emergencyContactPhoneNumber) {
      if (!REGEXP_NUM.test(emergencyContactPhoneNumber)) {
        toast["warning"](`Emergency Phone Number has invalid characters.`);
        return;
      }
    }

    if (!password) {
      validations.push("Password");
    } else {
      if (password.length < 8) {
        toast["warning"](
          `The length of the Password should be atleast 8 characters.`
        );
        return;
      }
      if (REGEXP_USER_PASSWORD.test(password)) {
        toast["warning"](
          `Password is weak. Password should contain atleast one upper case, lower case, number and special character.`
        );
        return;
      }
    }

    if (validations.length) {
      toast["warning"](validations.join(", ") + " not provided.");
      return;
    }
    if (password !== confirmPassword) {
      toast["warning"]("Passwords do not match.");
      return;
    }
    axios
      .post("/api/v2.0/user", {
        first_name: firstName,
        last_name: lastName,
        email: email,
        personal_email: personalEmail,
        phone_no: phoneNumber,
        password: password,
        emergency_contact_name: emergencyContactName,
        emergency_contact_relationship: emergencyContactRelationship,
        emergency_contact_phone: emergencyContactPhoneNumber,
        role_id: roleId,
        team_id: null,
      })
      .then((_) => {
        toast["success"]("User onboarded");
        history.push("/settings");
      })
      .catch((err) => {
        console.error(err);
        toast["error"]("Error adding new user");
      });
  };
  return (
    <div className="User">
      <h2>Register Account</h2>
      <br></br>
      <div className="user-container">
        <form className="user-form" onSubmit={(e) => onAddUser(e)}>
          <h3> Personal Details</h3>
          <div className="form-row">
            <label className="required">First Name</label>
            <TextField
              fullWidth
              placeholder="First Name"
              size="medium"
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
            />
          </div>

          <div className="form-row">
            <label className="required">Last Name</label>
            <TextField
              fullWidth
              placeholder="Last Name"
              size="medium"
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
            />
          </div>

          <div className="form-row">
            <label className="required">Role</label>
            <Select
              fullWidth
              required
              value={role}
              onChange={(e) => {
                setRole(String(e.target.value));
              }}
            >
              <MenuItem key={2} value={"Supervisor"}>
                Supervisor
              </MenuItem>
              <MenuItem key={1} value={"Staff"}>
                Staff
              </MenuItem>
              <MenuItem key={3} value={"Admin"}>
                Admin
              </MenuItem>
            </Select>
          </div>

          <div className="form-row">
            <label className="required">Phone Number</label>
            <TextField
              fullWidth
              placeholder="Example: 9005 4281"
              size="medium"
              type="tel"
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
            />
          </div>

          <div className="form-row">
            <label>Personal Email</label>
            <TextField
              fullWidth
              placeholder="Personal Email"
              size="medium"
              value={personalEmail}
              onChange={(e) => {
                setPersonalEmail(e.target.value);
              }}
            />
          </div>

          <div className="form-row">
            <label className="required">Work Email</label>
            <TextField
              fullWidth
              placeholder="Work Email"
              size="medium"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>

          <div className="form-row">
            <label className="required">Password</label>
            <TextField
              fullWidth
              placeholder="Password"
              size="medium"
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>
          <div className="form-row">
            <label className="required">Confirm Password</label>
            <TextField
              fullWidth
              placeholder="Confirm Password"
              size="medium"
              type="password"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />
          </div>

          <h3> Emergency Contact Details</h3>

          <div className="form-row">
            <label>Name</label>
            <TextField
              fullWidth
              placeholder="Name"
              size="medium"
              value={emergencyContactName}
              onChange={(e) => {
                setEmergencyContactName(e.target.value);
              }}
            />
          </div>

          <div className="form-row">
            <label>Relationship</label>
            <TextField
              fullWidth
              placeholder="Relationship"
              size="medium"
              value={emergencyContactRelationship}
              onChange={(e) => {
                setEmergencyContactRelationship(e.target.value);
              }}
            />
          </div>

          <div className="form-row">
            <label>Phone Number</label>
            <TextField
              fullWidth
              placeholder="Phone number"
              size="medium"
              type="tel"
              value={emergencyContactPhoneNumber}
              onChange={(e) => {
                setEmergencyContactPhoneNumber(e.target.value);
              }}
            />
          </div>

          <button type="submit">Add</button>
          <button
            onClick={(e) => {
              e.preventDefault();
              window.history.back();
            }}
          >
            Cancel
          </button>
        </form>
      </div>
    </div>
  );
};
export default withRouter(User);
