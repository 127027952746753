import React, { useState, useEffect } from "react";
import axios from "axios";
import "./SupervisingTeams.scss";
import SupervisingTeamItem from "../../components/SupervisingTeamItem/SupervisingTeamItem";
import { UserModel } from "../../models/UserModel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import TeamForm from "../../components/TeamForm/TeamForm";
import { TeamModel } from "../../models/TeamModel";
import { toast } from "react-toastify";
import { BOX_COLOR, TEXT_COLOR } from "../../constants/constants";

export default function SupervisingTeams() {
  const [users, setUsers] = useState<UserModel[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [teams, setTeams] = useState<TeamModel[]>([]);
  const [teamOnDisplay, setTeamOnDisplay] = useState<TeamModel>({
    team_id: 0,
    team_name: "",
    first_responders: [],
    escalation_points: [],
  });

  useEffect(() => {
    axios
      .get("/api/v2.0/user/list")
      .then((res) => setUsers(res && res.data))
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get("/api/v2.0/team")
      .then((res) => {
        setTeams(res && res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [open]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleEdit = (team: TeamModel) => {
    setTeamOnDisplay(team);
    setEditMode(true);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditMode(false);
    setTeamOnDisplay({
      team_id: 0,
      team_name: "",
      first_responders: [],
      escalation_points: [],
    });
  };

  const handleSubmit = () => {
    if (teamOnDisplay.team_name.length < 1) {
      toast && toast["warning"]("Enter Team Name");
      return;
    } else if (teamOnDisplay.first_responders.length < 1) {
      toast && toast["warning"]("Enter at least 1 First Responder");
      return;
    } else if (teamOnDisplay.escalation_points.length < 1) {
      toast && toast["warning"]("Enter at least 1 Escalation Point");
      return;
    }
    if (!editMode) {
      axios
        .post("/api/v2.0/team", {
          team_type: 1,
          team_name: teamOnDisplay.team_name,
          first_responders: teamOnDisplay.first_responders.map(
            (user) => user.user_id
          ),
          escalation_points: teamOnDisplay.escalation_points.map(
            (user) => user.user_id
          ),
        })
        .then((_) => {
          toast["success"]("Team Added");
          handleClose();
        })
        .catch((err) => {
          console.error(err);
          toast["error"]("Error adding new team " + err?.response?.data);
        });
    } else if (editMode) {
      axios
        .put("/api/v2.0/team", {
          team_id: teamOnDisplay.team_id,
          team_name: teamOnDisplay.team_name,
          first_responders: teamOnDisplay.first_responders.map(
            (user) => user.user_id
          ),
          escalation_points: teamOnDisplay.escalation_points.map(
            (user) => user.user_id
          ),
        })
        .then((_) => {
          toast["success"]("Team Updated");
          handleClose();
        })
        .catch((err) => {
          console.error(err);
          toast["error"]("Error updating team " + err?.response?.data);
        });
    }
  };

  return (
    <div className="SupervisingTeams">
      <div className="firstrow">
        <h3>Set Up Supervising Teams</h3>
        <FontAwesomeIcon
          title="Add Team"
          icon={faPlus}
          size={"lg"}
          cursor="pointer"
          onClick={handleOpen}
        ></FontAwesomeIcon>
      </div>
      <div className="teams">
        {teams.map((team) => (
          <SupervisingTeamItem
            key={team.team_id}
            users={users}
            team={team}
            handleEdit={handleEdit}
          />
        ))}
      </div>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogTitle style={{ color: TEXT_COLOR, backgroundColor: BOX_COLOR }}>
          {editMode === true
            ? "Edit Team " + teamOnDisplay.team_name
            : "Add new Team"}
        </DialogTitle>
        <DialogContent
          style={{ color: TEXT_COLOR, backgroundColor: BOX_COLOR }}
          dividers
        >
          <DialogContentText style={{ color: TEXT_COLOR }}>
            {editMode === true
              ? "Edit Name, First Responders or Escalation Points of your Team"
              : " Add a Supervising Team with First Responders and Escalation Points for the safety of your employees."}
          </DialogContentText>
          <TeamForm
            users={users}
            team={teamOnDisplay}
            setTeam={setTeamOnDisplay}
          ></TeamForm>
        </DialogContent>
        <DialogActions
          style={{ color: TEXT_COLOR, backgroundColor: BOX_COLOR }}
        >
          <Button style={{ color: TEXT_COLOR }} onClick={handleClose}>
            Cancel
          </Button>
          <Button style={{ color: TEXT_COLOR }} onClick={handleSubmit}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
