import React, { useEffect, useState } from "react";
import axios from "axios";

import Loading from "../../components/Loading/Loading";
import { BuildingModelWithLocation } from "../../models/BuildingModel";
import SiteMapView from "../../components/SiteMapView/SiteMapView";

export default function LiveView() {
  const [selectedSiteId, setSelectedSiteId] = useState<number>(-1);
  const [sites, setSites] = useState<BuildingModelWithLocation[]>([]);
  const [loading, setChartLoading] = useState(false);

  useEffect(() => {
    const search = window.location.search,
      params = new URLSearchParams(search),
      siteIdFromUrl = params.get("location");
    if (siteIdFromUrl) setSelectedSiteId(parseInt(siteIdFromUrl));
    axios
      .get("/api/v2.0/building/location")
      .then((result) => {
        setSites(result.data);
        if (selectedSiteId && selectedSiteId <= 0)
          setSelectedSiteId(result.data[0]?.building_id ?? -1);
      })
      .finally(() => setChartLoading(false));
  }, []);
  return loading ? (
    <Loading></Loading>
  ) : (
    <SiteMapView type="live" sites={sites}></SiteMapView>
  );
}
