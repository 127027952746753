import React from "react";

interface CellProps {
  dataItem: any;
  field: string;
}

export class AvgIntensityCell extends React.Component<CellProps> {
  render() {
    const value = this.props.dataItem[this.props.field];
    let displayValue = "Light";
    if (
      value.count_intensity_type_id_1 +
      value.count_intensity_type_id_2 +
      value.count_intensity_type_id_3
    ) {
      if (
        value.count_intensity_type_id_1 > value.count_intensity_type_id_2 &&
        value.count_intensity_type_id_1 > value.count_intensity_type_id_3
      ) {
        displayValue = "Light";
      } else {
        if (value.count_intensity_type_id_2 > value.count_intensity_type_id_3) {
          displayValue = "Moderate";
        } else {
          displayValue = "High";
        }
      }
    } else {
      displayValue = "NA";
    }
    return <td className={`AvgIntensityCell`}>{displayValue}</td>;
  }
}
