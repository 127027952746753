import React, { useRef } from "react";
import {
  Map as LeafletMap,
  TileLayer,
  Marker,
  LayerGroup,
} from "react-leaflet";
import { divIcon, LatLngTuple } from "leaflet";
import { useHistory } from "react-router-dom";

import "./SiteMapView.scss";
import { BuildingModelWithLocation } from "../../models/BuildingModel";
import { defaultLat, defaultLong } from "../../helpers/LocationHelper";
import { FormControl, Select, MenuItem } from "@material-ui/core";
import { useEffect } from "react";

export default function SiteMapView({
  sites,
  type,
}: {
  sites: BuildingModelWithLocation[];
  type: string;
}) {
  const history = useHistory();
  const mapRef = useRef<LeafletMap>(null);
  useEffect(() => {
    if (sites) {
      let minLat = 0,
        minLong = 0,
        maxLat = 0,
        maxLong = 0;
      sites.forEach((site) => {
        if (minLat === 0 || site.lat < minLat) {
          minLat = site.lat;
        }
        if (minLong === 0 || site.long < minLong) {
          minLong = site.long;
        }
        if (maxLat === 0 || site.lat > maxLat) {
          maxLat = site.lat;
        }
        if (maxLong === 0 || site.long > maxLong) {
          maxLong = site.long;
        }
      });
      const from: LatLngTuple = [minLat, minLong];
      const to: LatLngTuple = [maxLat, maxLong];
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
       mapRef.current?.leafletElement.fitBounds([from, to], {
        padding: [50, 50],
        maxZoom: 13,
        animate: true,
      });
    }
  }, [sites]);

  const getLablePosition = (site: BuildingModelWithLocation) => {
    ///logic to determine if labels of the points overlap
    const otherPointsNearby: string[] = [];
    const availableDirections = [
      "top-left",
      "top-right",
      "bottom-right",
      "bottom-left",
    ];
    sites.forEach((s) => {
      if (s.building_id !== site.building_id) {
        const latDiff = Math.abs(s.lat - site.lat);
        const longDiff = Math.abs(s.long - site.long);
        if (latDiff <= 0.005 || longDiff <= 0.005) {
          if (s.lat >= site.lat && s.long >= site.long) {
            //site -> bottom left
            otherPointsNearby.push("top-right");
          } else if (s.lat >= site.lat && s.long <= site.long) {
            //site -> bottom right
            otherPointsNearby.push("top-left");
          } else if (s.lat <= site.lat && s.long >= site.long) {
            //site -> top left
            otherPointsNearby.push("bottom-right");
          } else if (s.lat <= site.lat && s.long <= site.long) {
            //site -> top right
            otherPointsNearby.push("bottom-left");
          }
        }
      }
    });
    if (otherPointsNearby.length > 0) {
      const blockedAreas = availableDirections.intersect(otherPointsNearby);
      if (blockedAreas.length === 1) {
        const blockedIndex = availableDirections.indexOf(blockedAreas[0]);
        return availableDirections[(blockedIndex + 2) % 4];
      } else if (blockedAreas.length > 4) {
        return availableDirections.except(otherPointsNearby)[0];
      }
    }
    return "";
  };

  return (
    <div className="SiteMapView">
      <LeafletMap
        center={[defaultLat, defaultLong]}
        // zoom={12}
        zoomControl={true}
        ref={mapRef}
        style={{ width: "100%", height: "80vh" }}
      >
        <TileLayer
          maxNativeZoom={12}
          maxZoom={19}
          attribution='New OneMap | Map data &copy; contributors, <a href="http://SLA.gov.sg">Singapore Land Authority</a>'
          url="https://maps-{s}.onemap.sg/v3/Default/{z}/{x}/{y}.png"
        />
        <LayerGroup>
          {sites.map((x) => {
            return (
              <Marker
                icon={divIcon({
                  className:
                    (x.incedents_today === 0
                      ? "map-marker-pin "
                      : "map-marker-pin-incedents ") + getLablePosition(x),
                  html: "<p class='text'>" + x.name + "</p>",
                })}
                position={[x.lat ?? defaultLat, x.long ?? defaultLong]}
                onclick={() =>
                  history.replace(
                    `/${
                      type === "ops" ? "daily-ops/overview" : "live/" + x.name
                    }?location=${x.building_id}`
                  )
                }
              ></Marker>
            );
          })}
        </LayerGroup>
        <div className="goto-site">
          <FormControl
            style={{
              width: "200px",
              float: "right",
              marginRight: 10,
              marginTop: 10,
              backgroundColor: "white",
              color: "black",
            }}
          >
            <Select
              variant="filled"
              value={-1}
              style={{ height: 40 }}
              onChange={(_e, obj: any) => {
                if (obj && obj.props?.value && obj.props.value >= 0) {
                  history.replace(
                    `/${
                      type === "ops"
                        ? "daily-ops/overview"
                        : "live/" +
                          sites.find(
                            (x) => x.building_id === (obj.props.value as number)
                          )?.name
                    }?location=${obj.props.value}`
                  );
                }
              }}
            >
              <MenuItem disabled value={-1}>
                Go to Site
              </MenuItem>
              {sites.map((x) => {
                return <MenuItem value={x.building_id}>{x.name}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </div>
      </LeafletMap>
    </div>
  );
}
