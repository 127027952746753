import React, { useState, useEffect } from "react";
import axios from "axios";
import "./IncidentReport.scss";
import { Incident } from "../../models/Incident";
import { HazardType } from "../../models/HazardType";
import { Status } from "../../models/Status";
import { IncidentType } from "../../models/IncidentType";
import { AuthService } from "../../services/authService";
import { toast } from "react-toastify";
import { dateTimeToString } from "../../services/commonServices";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import {
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import IncidentHistoryList from "../../subpages/IncidentHistoryList/IncidentHistoryList";
import { ReportModalState } from "../../pages/DailyDashboard/DailyDashboard";
import { MenuItem, Select, TextField } from "@material-ui/core";
import { UserModel } from "../../models/UserModel";

interface IncidentReportProps {
  id: number;
  mode: ReportModalState;
  setMode?: (mode: ReportModalState) => void;
  hazardTypes: HazardType[];
  statusTypes: Status[];
  incidentTypes: IncidentType[];
  users: UserModel[];
}

const IncidentReport: React.FC<IncidentReportProps> = ({
  id,
  mode,
  setMode,
  hazardTypes,
  statusTypes,
  incidentTypes,
  users,
}) => {
  const [incidentDetails, setIncidentDetails] = useState<Incident>();
  const [hazardDescription, setHazardDescription] = useState("");
  const [hazardType, setHazardType] = useState(-1);
  const [location, setLocation] = useState("");
  const [userId, setUserId] = useState("");
  const [victimName, setVictimName] = useState("");
  const [victimNric, setVictimNric] = useState("");
  const [assetDetails, setAssetDetails] = useState("");
  const [iReportRef, setIReportRef] = useState("");
  const [riskAssessment, setRiskAssessment] = useState("");
  const [incidentType, setIncidentType] = useState(-1);
  const [remarks, setRemarks] = useState("");
  const [status, setStatus] = useState(5); // use 5 (Resolved: Reported) as default
  const [mediaPath, setMediaPath] = useState("");
  const [displayHistory, setDisplayHistory] = useState(false);
  const [dateTime, setDateTime] = useState<MaterialUiPickersDate>(new Date());
  // @ts-ignore
  const [incidentHistory, setIncidentHistory] = useState<Incident[]>([]);

  useEffect(() => {
    if (mode === "view") {
      (async () => {
        try {
          let incidentResult = await axios.get(
            `/api/v2.0/incident?incident_id=${id}`
          );
          setIncidentDetails(incidentResult && incidentResult.data);
        } catch (error) {
          console.error(console.error);
        }
      })();
    }
  }, [id, mode]);

  useEffect(() => {
    if (mode === "view") {
      axios.get(`/api/v2.0/incident/log?incident_id=${id}`).then((res) => {
        if (res.data) setIncidentHistory(res.data);
      });
    }
  }, [id, mode]);

  useEffect(() => {
    if (incidentDetails) {
      setHazardDescription(incidentDetails.hazard_description);
      setHazardType(incidentDetails.hazard_id);
      setIncidentType(incidentDetails.incident_type_id);
      setLocation(incidentDetails.location);
      setVictimName(incidentDetails.victim_name);
      setStatus(incidentDetails.status_id);
      setRemarks(incidentDetails.remarks);
      setVictimNric(incidentDetails.victim_nric);
      setAssetDetails(incidentDetails.asset_details);
      setIReportRef(incidentDetails.ireport_ref);
      setRiskAssessment(incidentDetails.risk_assessment);
      setDateTime(new Date(incidentDetails.incident_dt.replace("Z", "")));

      (async () => {
        if (
          incidentDetails.incident_type_id &&
          (incidentDetails.detected_by_device_id === 2 ||
            incidentDetails.detected_by_device_id === 3)
        ) {
          try {
            let results = await axios.get(
              `/api/v2.0/incident/media?incident_id=${incidentDetails.incident_id}&media_type=${incidentDetails.media_type}`
            );

            const b64 = Buffer.from(results.data.buffer).toString("base64");

            let mimeType = "image/png"; // e.g., image/png
            // incident_type_id = 6 sound anomaly
            if (incidentDetails.incident_type_id === 6) {
              mimeType = "audio/x-wav";
            } else if (incidentDetails.incident_type_id !== 3)
              mimeType = "video/mp4";
            setMediaPath(`data:${mimeType};base64,${b64}`);
            //setMediaPath(`${results.data.path}`);
          } catch (error) {
            console.log(error);
            toast["warning"]("Error loading the incident media.");
          }
        }
      })();
    }
  }, [incidentDetails]);

  useEffect(() => {
    let filteredUsers = users.filter((user) => user.user_id === userId);
    if (filteredUsers && filteredUsers.length) {
      setVictimName(filteredUsers[0].first_name);
    }
  }, [users, userId]);

  const onSubmitIncident = (e: any) => {
    e.preventDefault();
    const successToast =
      "Incident report " + (mode === "add" ? "added." : "updated.");
    const errorToast =
      "Error" + (mode === "add" ? "adding" : "updating") + "incident.";
    let method: "put" | "post" = mode === "view" ? "put" : "post";
    let data: object;
    if (mode === "view") {
      data = {
        incident_id: id,
        status_id: status,
        updated_by: AuthService.getLoggedInUserId(),
        updated_dt: dateTimeToString(new Date()),
        victim_name: victimName,
        incident_type_id: incidentType,
        hazard_id: incidentType === 3 ? hazardType : null,
        remarks,
        hazard_description: hazardDescription,
        location,
        created_dt: incidentDetails?.created_dt,
        inital_status: incidentDetails?.status_id,
        victim_nric: victimNric,
        asset_details: assetDetails,
        ireport_ref: iReportRef,
        risk_assessment: riskAssessment,
      };
    } else {
      if (userId === "") {
        toast["warning"](`Select Name`);
        return;
      }
      if (incidentType === -1) {
        toast["warning"](`Select Incident Type`);
        return;
      }
      if (incidentType === 3 && hazardType === -1) {
        toast["warning"](`Select Hazard Type`);
        return;
      }
      let dateTimeString = [
        dateTime!.getFullYear(),
        "-",
        dateTime!.getMonth() + 1,
        "-",
        dateTime!.getDate(),
        " ",
        dateTime!.getHours(),
        ":",
        dateTime!.getMinutes(),
        ":",
        dateTime!.getSeconds(),
        ".000",
      ].join("");
      data = {
        incident_type_id: incidentType,
        hazard_id: incidentType === 3 ? hazardType : null,
        incident_dt: dateTimeString,
        detected_by_device_id: 4,
        device_id: null,
        camera_id: null,
        lat: null,
        lng: null,
        victim_name: victimName,
        victim_nric: victimNric,
        asset_details: assetDetails,
        ireport_ref: iReportRef,
        risk_assessment: riskAssessment,
        location,
        remarks,
        hazard_description: hazardDescription,
        status_id: status,
        updated_by: AuthService.getLoggedInUserId(),
        updated_dt: dateTimeToString(new Date()),
        user_id: userId,
      };
    }
    axios("/api/v2.0/incident", { method, data })
      .then(() => {
        toast["success"](successToast);
        if (setMode) setMode("none");
      })
      .catch((err) => {
        toast["error"](
          err && err.response && err.response.data
            ? err.response.data
            : errorToast
        );
      });
  };
  return (
    <div className="IncidentReport">
      {setMode ? (
        <button className="close float-right" onClick={() => setMode("none")}>
          Close
        </button>
      ) : null}

      <span className="header">
        {mode === "add" ? "New " : ""}Incident Report
        {mode === "view" ? ` (ID: ${id})` : ""}
      </span>
      {mode === "view" ? (
        displayHistory ? (
          <button
            className="hide-history"
            onClick={() => setDisplayHistory(false)}
          >
            Back to Incident Report
          </button>
        ) : (
          <button
            className="view-history"
            onClick={() => setDisplayHistory(true)}
          >
            View History
          </button>
        )
      ) : null}
      {displayHistory ? (
        <div className="content">
          <IncidentHistoryList
            incidentHistory={incidentHistory}
          ></IncidentHistoryList>
        </div>
      ) : (
        <div className="content">
          {incidentDetails?.detected_by_device_id === 2 ||
          incidentDetails?.detected_by_device_id === 3 ? (
            <div className="img-container">
              {incidentDetails && incidentDetails.incident_type_id === 3 ? (
                <img src={mediaPath} alt="Incident" className="incident-img" />
              ) : (
                <>
                  {mediaPath ? (
                    <>
                      {incidentDetails &&
                      incidentDetails.incident_type_id === 6 ? (
                        <div className="audio-container">
                          <audio
                            data-setup="{}"
                            controls
                            loop={true}
                            autoPlay={true}
                            preload="auto"
                            plays-inline
                          >
                            <source src={mediaPath} />
                            Your browser does not support the video tag.
                          </audio>
                        </div>
                      ) : (
                        <video
                          className="incident-img video-js vjs-default-skin"
                          data-setup="{}"
                          controls
                          loop={true}
                          autoPlay={true}
                          preload="auto"
                          plays-inline
                        >
                          <source
                            src={mediaPath}
                            type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
                          />
                          Your browser does not support the video tag.
                        </video>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
          ) : null}
          <form
            className={`incident-form ${
              incidentDetails?.detected_by_device_id === 1 ? "w-100" : ""
            }`}
            onSubmit={(e) => onSubmitIncident(e)}
          >
            <div className="form-row">
              <label>
                Incident date &amp; time <br />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    views={["year", "month", "date"]}
                    openTo="date"
                    format="dd MMM yyyy"
                    value={dateTime}
                    onChange={setDateTime}
                    disabled={mode !== "add"}
                  />
                  &nbsp;
                  <TimePicker
                    views={["hours", "minutes"]}
                    value={dateTime}
                    format="HH:mm"
                    onChange={setDateTime}
                    disabled={mode !== "add"}
                  />
                </MuiPickersUtilsProvider>
              </label>
            </div>
            <div className="form-row">
              <label>
                Description / Cause
                <TextField
                  fullWidth
                  placeholder="Description"
                  size="medium"
                  value={hazardDescription || ""}
                  onChange={(e) => {
                    setHazardDescription(e.target.value);
                  }}
                />
              </label>
            </div>
            <div>
              <label className="required">Incident </label>
              <div className="form-row">
                <Select
                  fullWidth
                  required
                  value={incidentType}
                  onChange={(e) => {
                    setIncidentType(Number(e.target.value));
                  }}
                >
                  {incidentTypes &&
                    incidentTypes.map((x) => (
                      <MenuItem
                        key={x.incident_type_id}
                        value={x.incident_type_id}
                      >
                        {x.incident}
                      </MenuItem>
                    ))}
                </Select>
              </div>
            </div>
            {incidentType === 3 ? (
              <div>
                <label className="required">Type of Hazard </label>
                <div className="form-row">
                  <Select
                    fullWidth
                    required
                    value={hazardType}
                    onChange={(e) => {
                      setHazardType(Number(e.target.value));
                    }}
                  >
                    {hazardTypes &&
                      hazardTypes.map((x) => (
                        <MenuItem key={x.hazard_id} value={x.hazard_id}>
                          {x.hazard}
                        </MenuItem>
                      ))}
                  </Select>
                </div>
              </div>
            ) : null}

            {mode === "add" ? (
              <div>
                <label className="required">Name </label>
                <div className="form-row">
                  <Select
                    fullWidth
                    value={userId}
                    onChange={(event: { target: { value: any } }) => {
                      setUserId(event.target.value);
                    }}
                  >
                    {users &&
                      users.map((x) => (
                        <MenuItem key={x.user_id} value={x.user_id}>
                          {x.email}
                        </MenuItem>
                      ))}
                  </Select>
                </div>
              </div>
            ) : (
              <div className="form-row">
                <label>
                  Name{" "}
                  <TextField
                    fullWidth
                    placeholder="Name"
                    size="medium"
                    value={victimName || ""}
                    onChange={(e) => {
                      setVictimName(e.target.value);
                    }}
                  />
                </label>
              </div>
            )}

            <div className="form-row">
              <label>
                NRIC{" "}
                <TextField
                  fullWidth
                  placeholder="NRIC"
                  size="medium"
                  value={victimNric || ""}
                  onChange={(e) => {
                    setVictimNric(e.target.value);
                  }}
                />
              </label>
            </div>
            <div className="form-row">
              <label>
                Asset details{" "}
                <TextField
                  fullWidth
                  placeholder="Asset Details"
                  size="medium"
                  value={assetDetails || ""}
                  onChange={(e) => {
                    setAssetDetails(e.target.value);
                  }}
                />
              </label>
            </div>
            <div className="form-row">
              <label>
                Location{" "}
                <TextField
                  fullWidth
                  placeholder="Location"
                  size="medium"
                  value={location || ""}
                  onChange={(e) => {
                    setLocation(e.target.value);
                  }}
                />
              </label>
            </div>
            <div className="form-row">
              <label>
                iReport reference number{" "}
                <TextField
                  fullWidth
                  placeholder="I-Report Reference"
                  size="medium"
                  value={iReportRef || ""}
                  onChange={(e) => {
                    setIReportRef(e.target.value);
                  }}
                />
              </label>
            </div>
            <div className="form-row">
              <label>
                Risk assessment{" "}
                <TextField
                  fullWidth
                  placeholder="Risk Assessment"
                  size="medium"
                  value={riskAssessment || ""}
                  onChange={(e) => {
                    setRiskAssessment(e.target.value);
                  }}
                />
              </label>
            </div>
            <div className="form-row">
              <label>
                Rectification Action{" "}
                <TextField
                  fullWidth
                  placeholder="Rectification Action"
                  size="medium"
                  value={remarks || ""}
                  onChange={(e) => {
                    setRemarks(e.target.value);
                  }}
                />
              </label>
            </div>

            <div className="form-row">
              <label>
                Status{" "}
                <Select
                  fullWidth
                  required
                  value={status}
                  onChange={(e) => {
                    setStatus(Number(e.target.value));
                  }}
                >
                  {statusTypes &&
                    statusTypes.map((x) => (
                      <MenuItem key={x.status_id} value={x.status_id}>
                        {x.status}
                      </MenuItem>
                    ))}
                </Select>
              </label>
            </div>

            <button type="submit" className="float-right form-submit-btn">
              {mode === "view" ? "Update" : "Add"}
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default IncidentReport;
