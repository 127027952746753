import {
  createStyles,
  TableCell,
  TableRow,
  withStyles,
} from "@material-ui/core";

export const defaultPageSize = 20;

export const StyledTableCell = withStyles(() =>
  createStyles({
    head: {
      backgroundColor: "var(--table-header-bg)",
      color: "var(--text-color)",
      border: "none",
      padding: "10px",
    },
    body: {
      color: "var(--text-color)",
      border: "none",
      padding: "7.5px",
    },
  })
)(TableCell);

export const StyledTableHeaderRow = withStyles(() =>
  createStyles({
    root: {
      borderRadius: "var(--border-radius)",
    },
  })
)(TableRow);

export const StyledTableRow = withStyles(() =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: "var(--table-row-bg-even)",
      },
      "&:nth-of-type(even)": {
        backgroundColor: "var(--table-row-bg-odd)",
      },
      color: "var(--text-color)",
    },
  })
)(TableRow);
