import React from "react";
import "./DateTimeCell.scss";
interface DateTimeCellProps {
  dataItem: any;
  field: string;
}

export class DateTimeCell extends React.Component<DateTimeCellProps> {
  render() {
    const value = this.props.dataItem[this.props.field];
    return (
      <td className={`DateTimeCell`}>
        {value &&
          new Date(
            value.includes("Z") ? value.replace("Z", "") : value
          ).toLocaleTimeString()}
      </td>
    );
  }
}
