import React from "react";
import "./DailyOpsHoursChart.scss";
import {
  PieChart,
  Pie,
  ResponsiveContainer,
  Legend,
  Label,
  Tooltip,
} from "recharts";
import NoData from "../NoData/NoData";
import { APP_SETTINGS } from "../../helpers/Utils";

export default function DailyOpsHoursChart({
  centerText,
  centerSubText,
  chartData = [],
}: {
  centerText: string | number;
  centerSubText: string | number;
  chartData: any;
}) {
  const RenderLegend = (props: any) => {
    const { payload } = props;
    return (
      <ul className="ChartLegend">
        {payload.map((entry: any, index: number) => (
          <li
            className="legend-item"
            style={{
              borderBottom: `${
                chartData.length - 1 !== index &&
                chartData[index + 1] &&
                chartData[index + 1].name !== ""
                  ? "solid 1px"
                  : ""
              }`,
              verticalAlign: "middle",
            }}
            key={`item-${index}`}
          >
            <div
              className="dot"
              style={{ backgroundColor: `${chartData[index].fill ?? "#000"}` }}
            ></div>
            {entry.value}
          </li>
        ))}
      </ul>
    );
  };
  return chartData.length === 0 ? (
    <NoData></NoData>
  ) : (
  
    <ResponsiveContainer aspect={1} width="90%" className={"responsive-container-pie-chart"}>
      <PieChart margin={{ top: 5, bottom: 5 }}>
      <Legend
          verticalAlign="bottom"
          layout="vertical"
          iconType="circle"
          content={RenderLegend}
        />
        <Pie
          data={chartData}
          dataKey="value"
          cx="50%"
          cy="50%"
          innerRadius={"60%"}
          outerRadius={"90%"}
          maxRadius={100}
          stroke="var(--card-bg)"
        
        >
          <>
            <Label
              position={"center"}
              dy={-10}
              style={{
                textAnchor: "middle",
                fill: "var(--text-color)",
                fontSize: "var(--font-XL)",
              }}
            >
              {centerText ?? ""}
            </Label>

            <Label
              position="center"
              dy={10}
              style={{
                textAnchor: "middle",
                fill: "var(--text-color)",
              }}
            >
              {centerSubText ?? ""}
            </Label>
          </>
        </Pie>
       
        <Tooltip
          formatter={function (value: string) {
            return `${value} ${APP_SETTINGS.displayHourly ? " hr" : " min"}`;
          }}
        />
      </PieChart>
    </ResponsiveContainer>
  );
}
