import React, { useEffect, useState } from "react";
import { process } from "@progress/kendo-data-query";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { NameCell } from "../NameCell/NameCell";
import { IncidentCell } from "../IncidentCell/IncidentCell";
import { DateCell } from "../DateCell/DateCell";
import { DateTimeCell } from "../DateTimeCell/DateTimeCell";
import { Incident } from "../../models/Incident";
import { StatusCell } from "../StatusCell/StatusCell";
import { BACKGROUND_COLOR } from "../../constants/constants";
import { ReportModalState } from "../../pages/DailyDashboard/DailyDashboard";
import Modal from "react-modal";
import IncidentReport from "../IncidentReport/IncidentReport";
import axios from "axios";
import { HazardType } from "../../models/HazardType";
import { Status } from "../../models/Status";
import { IncidentType } from "../../models/IncidentType";
import { UserModel } from "../../models/UserModel";

interface Props {
  incidents: Incident[];
  showDateColumn: boolean;
  onRowClick?: (incident_id: number) => void;
}
const IncidentsGrid: React.FC<Props> = ({
  incidents,
  showDateColumn,
  onRowClick,
}) => {
  const [gridDataState, setGridDataState] = useState<any>({
    sort: [],
    skip: 0,
    take: 10,
  });
  const [reportModalMode, setReportModalMode] =
    useState<ReportModalState>("none");
  const [selectedId, setSelectedId] = useState(0);
  const [hazardTypes, setHazardTypes] = useState<HazardType[]>([]);
  const [incidentTypes, setIncidentTypes] = useState<IncidentType[]>([]);
  const [statusTypes, setStatusTypes] = useState<Status[]>([]);
  const [users, setUsers] = useState<UserModel[]>([]);
  //const [buildingId, setBuildingId] = useState<number>(0);
  //const [buildings, setBuildings] = useState<BuildingModel[]>([]);
  useEffect(() => {
    (async () => {
      let results = await Promise.all([
        axios.get("api/v2.0/data/hazard-types"),
        axios.get("api/v2.0/data/status"),
        axios.get("api/v2.0/data/incident-types"),
        axios.get("api/v2.0/user"),
      ]);

      setHazardTypes(results && results[0] && results[0].data);
      setStatusTypes(results && results[1] && results[1].data);
      setIncidentTypes(results && results[2] && results[2].data);
      setUsers(results && results[3] && results[3].data);
    })();
  }, []);

  useEffect(() => {
    if (selectedId) {
      setReportModalMode("view");
      return;
    }
  }, [selectedId]);

  useEffect(() => {
    if (reportModalMode === "none") setSelectedId(0);
  }, [reportModalMode]);
  return (
    <>
      <Grid
        data={process(incidents, gridDataState)}
        pageable={{ buttonCount: 5, pageSizes: [5, 10] }}
        pageSize={10}
        sortable={true}
        {...gridDataState}
        onDataStateChange={(e) => setGridDataState(e.data)}
        onRowClick={(e) => {
          if (onRowClick) onRowClick(e.dataItem && e.dataItem.incident_id);
        }}
        style={{ height: "90%" }}
      >
        <GridColumn field="incident_id" title="Id" />
        <GridColumn
          field="incident_type"
          title="Incident Type"
          cell={(props: any) => <IncidentCell {...props}></IncidentCell>}
        />

        <GridColumn
          field="team_name"
          title="Team"
          cell={(props: any) => <NameCell {...props}></NameCell>}
        />
        <GridColumn
          field="victim_name"
          title="Name"
          cell={(props: any) => <NameCell {...props}></NameCell>}
        />

        <GridColumn
          field="detected_by"
          title="Detection"
          cell={(props: any) => <NameCell {...props}></NameCell>}
        />
        {showDateColumn ? (
          <GridColumn
            field="incident_dt"
            title="Date"
            format="{0:d}"
            cell={(props: any) => <DateCell {...props}></DateCell>}
          />
        ) : null}
        <GridColumn
          field="incident_dt"
          title="Time"
          format="{0:d}"
          cell={(props: any) => <DateTimeCell {...props}></DateTimeCell>}
        />
        <GridColumn
          field="status"
          title="Status"
          cell={(props: any) => <StatusCell {...props}></StatusCell>}
        />
      </Grid>
      <Modal
        isOpen={reportModalMode !== "none"}
        onRequestClose={() => setReportModalMode("none")}
        ariaHideApp={false}
        style={{
          content: {
            borderRadius: "20px",
            padding: "15px",
            left: "20px",
            right: "20px",
            top: "20px",
            bottom: "20px",
            backgroundColor: BACKGROUND_COLOR,
          },
          overlay: { zIndex: 2 },
        }}
      >
        <IncidentReport
          id={selectedId}
          mode={reportModalMode}
          setMode={setReportModalMode}
          hazardTypes={hazardTypes}
          statusTypes={statusTypes}
          incidentTypes={incidentTypes}
          users={users}
        ></IncidentReport>
      </Modal>
    </>
  );
};

export default IncidentsGrid;
