import axios from "axios";
import React, { useEffect, useState } from "react";
import { formatDate, formatUnix } from "../../helpers/DateHelper";

import { BuildingModel } from "../../models/BuildingModel";
import { TeamModel } from "../../models/TeamModel";
import "./DailyOpsSiteSelection.scss";

export default function DailyOpsSiteSelection({
  selectedSiteId,
  date,
  selectedTeamId,
  setSelectedSiteId,
  setSelectedTeamId,
  setDate,
  hour,
  hours,
  sethour,
  employeeSearchText = undefined,
  setEmployeeSearchText,
  setSelectedSiteName,
}: {
  selectedSiteId: string | number;
  date?: number;
  selectedTeamId: string | number;
  setSelectedSiteId: (data: number) => void;
  setSelectedTeamId: (data: number) => void;
  setDate?: (data: number) => void;
  hour?: number;
  hours?: number[];
  sethour?: (data: number) => void;
  employeeSearchText?: string;
  setEmployeeSearchText?: (serchText: string) => void;
  setSelectedSiteName?: (data: string) => void;
}) {
  const [sites, setSites] = useState<BuildingModel[]>([]);
  const [teams, setTeams] = useState<TeamModel[]>([]);

  useEffect(() => {
    if (selectedSiteId > 0) {
      Promise.all([
        axios.get("/api/v2.0/building"),
        axios.get("/api/v2.0/team/dashboard-data"),
      ]).then((result) => {
        setSites(result[0].data);
        setSelectedSiteName &&
          setSelectedSiteName(
            result[0].data.filter(
              (x: any) => x.building_id === selectedSiteId
            )[0]?.name ?? ""
          );
        const teams = result[1].data.filter(
          (x: any) => x.building_id === selectedSiteId
        );
        //if (teams.length > 0) {
        setTeams(teams);
        //setSelectedTeamId(teams[0].team_id);
        // }
      });
    }
  }, [selectedSiteId, selectedTeamId]);

  useEffect(() => {
    if (teams.length > 0) {
      setSelectedTeamId(teams[0].team_id);
    }
  }, [teams]);

  return (
    <>
      <select
        className="form-element"
        onChange={(e) => setSelectedSiteId(Number.parseInt(e.target.value))}
        value={selectedSiteId}
      >
        <option key="-1" value="-1" disabled>
          Select a site
        </option>
        {sites.map((site) => (
          <option key={site.building_id} value={site.building_id}>
            {site.name}
          </option>
        ))}
      </select>

      <select
        className="form-element short-element"
        onChange={(e) => setSelectedTeamId(Number.parseInt(e.target.value))}
        value={selectedTeamId}
      >
        <option key="-1" value="-1" disabled>
          Select a team
        </option>
        {teams.map((team) => (
          <option key={team.team_id} value={team.team_id}>
            {team.team_name}
          </option>
        ))}
      </select>

      {date && (
        <input
          type="date"
          value={formatDate(date)}
          onChange={(e) => setDate && setDate(formatUnix(e.target.value))}
          className="form-element"
        />
      )}

      {hours && (
        <select
          className="form-element short-element"
          onChange={(e) => sethour && sethour(Number.parseInt(e.target.value))}
          value={hour}
        >
          <option key="-1" value="-1" disabled>
            Select a hour
          </option>
          {hours.map((optionHour) => (
            <option
              key={optionHour}
              value={optionHour}
              selected={hour === optionHour}
            >
              {optionHour >= 12
                ? `${optionHour - 12 === 0 ? "12" : optionHour - 12} PM`
                : `${
                    optionHour === 0
                      ? "12"
                      : optionHour.toString().padStart(2, "0")
                  } AM`}
            </option>
          ))}
        </select>
      )}

      {employeeSearchText !== undefined && (
        <input
          type="string"
          placeholder="Search Employee"
          className="form-element"
          onChange={(e) =>
            setEmployeeSearchText && setEmployeeSearchText(e.target.value ?? "")
          }
          value={employeeSearchText}
        />
      )}
    </>
  );
}
