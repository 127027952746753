import React, { useState, useEffect } from "react";
import axios from "axios";
import { NavLink, RouteComponentProps, withRouter } from "react-router-dom";
import { process } from "@progress/kendo-data-query";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faUser } from "@fortawesome/free-solid-svg-icons";
import "./UserList.scss";
import { UserModel } from "../../models/UserModel";
import QRCode from "../../components/QRCode/QRCode";
// import { AuthService } from '../../services/authService';
interface Props {
  toast: any;
}
export const UserList: React.FC<Props & RouteComponentProps> = ({ toast }) => {
  const [users, setUsers] = useState<UserModel[]>([]);
  const [gridDataState, setGridDataState] = useState<any>({
    sort: [{ field: "first_name", dir: "asc" }],
  });
  useEffect(() => {
    axios
      .get("/api/v2.0/user/list")
      .then((res) => {
        console.log(res.data);
        setUsers(res && res.data);
      })
      .catch((err) => {
        console.error(err);
        toast["error"]("Error fetching user list");
      });
  }, [toast]);

  const CommandCell = (props: { dataItem: UserModel }) => (
    <td className="k-command-cell">
      <QRCode user_id={props.dataItem.user_id}></QRCode>
    </td>
  );
  const ProfileCell = (props: { dataItem: UserModel }) => (
    <td className="k-command-cell">
      <a
        href={`/user/${props.dataItem.first_name} ${props.dataItem.last_name}?userid=${props.dataItem.user_id}`}
      >
        <FontAwesomeIcon icon={faUser} />
      </a>
    </td>
  );

  // const deleteUser = (userId: string) => {
  //   if (AuthService.getLoggedInUserId() === userId) {
  //     alert('Cannot delete the current logged in user.');
  //     return;
  //   }
  //   if (window.confirm(`Are you sure you want to delete user ${userId}?`)) {
  //     axios
  //       .delete('/auth/user/', {
  //         data: {
  //           userId,
  //         },
  //       })
  //       .then((_) => {
  //         axios
  //           .get('/auth/user/')
  //           .then((res) => setUsers(res && res.data && res.data.rows))
  //           .catch((err) => {
  //             console.error(err);
  //             toast['error']('Error fetching camera list');
  //           });
  //       })
  //       .catch((err) => {
  //         console.error(err);
  //         toast['error']('Error deleting user');
  //       });
  //   }
  // };
  return (
    <div className="UserList">
      <div className="first_row">
        <h3 className="page-header">Users</h3>
        <NavLink to="/register-user" data-testid="add-user">
          <FontAwesomeIcon
            title="Add User"
            icon={faPlus}
            size={"lg"}
          ></FontAwesomeIcon>
        </NavLink>
      </div>
      {users ? (
        <Grid
          data={process(users, gridDataState)}
          sortable={true}
          filterable={true}
          {...gridDataState}
          onDataStateChange={(e) => setGridDataState(e.data)}
        >
          <GridColumn className="fixed" field="first_name" title="First Name" />
          <GridColumn className="fixed" field="last_name" title="Last Name" />
          <GridColumn className="fixed" field="email" title="Email" />
          <GridColumn className="fixed" field="phone_no" title="Phone Number" />
          <GridColumn className="fixed" field="role" title="Role" />
          <GridColumn className="fixed" field="team_name" title="Team" />
          <GridColumn cell={CommandCell} filterable={false} width="70" />
          <GridColumn cell={ProfileCell} filterable={false} width="70" />
          {/* <GridColumn
            filterable={false}
            cell={(props) => (
              <td className='k-command-cell'>
                <button
                  className='btn-delete'
                  onClick={(_) => deleteUser(props.dataItem['user_id'])}
                >
                  <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                </button>
              </td>
            )}
          ></GridColumn> */}
        </Grid>
      ) : null}
    </div>
  );
};
export default withRouter(UserList);
