import React, { useState } from "react";
import { Marker, Popup, Tooltip } from "react-leaflet";
import * as L from "leaflet";
import ReactDOMServer from "react-dom/server";
import "./UserMarker.scss";
import { UserActivityModel } from "../../models/UserActivityModel";
import { OutOfAreaAlertModel } from "../../models/OutOfAreaAlertModel";
import BatteryIndicator from "../BatteryIndicator/BatteryIndicator";
import { Button, TextField } from "@material-ui/core";
import { toast } from "react-toastify";
import axios from "axios";

const getIntensityDetailsById = (type: number) => {
  switch (type) {
    case 0:
      return {
        fill: "#FFF",
        name: "Resting",
      };
    case 1:
      return {
        fill: "#63F84A",
        name: "Light",
      };
    case 2:
      return {
        fill: "#FECB46",
        name: "Moderate",
      };
    case 3:
      return {
        fill: "#FA2C2C",
        name: "High",
      };
    default:
      return {
        fill: "#FFF",
        name: "Resting",
      };
  }
};
const UserMarker = ({
  userActivityDetails,
  userOutOfAreaAlertDetails,
  userName,
  userActivityTypesDict,
  isHidden = true,
  isAlert = false,
  mapRef = null,
}: {
  userActivityDetails?: UserActivityModel;
  userOutOfAreaAlertDetails?: OutOfAreaAlertModel;
  userName: string;
  userActivityTypesDict: any;
  isHidden: boolean;
  isAlert?: boolean;
  mapRef?: any;
}) => {
  const [showMsgPopup, setShowMsgPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const msg_character_length = 140;
  const sendMessage = () => {
    if (userActivityDetails?.user_id && message) {
      axios
        .get(
          `/api/v2.0/daily-ops/push-message?userIds=${userActivityDetails?.user_id}&message=${message}`
        )
        .then((result) => {
          if (result.status !== 200) {
            toast["error"]("Error occured while sending message");
          } else {
            toast["success"]("Message has been sent.");
            setShowMsgPopup(false);
            mapRef.current.leafletElement.closePopup();
          }
        })
        .catch(() => {
          toast["error"]("Error occured while sending message");
        })
        .finally(() => {});
    }
  };

  return !isHidden ? (
    <>
      <Marker
        className={`UserMarker `}
        position={
          !isAlert
            ? [userActivityDetails?.lat ?? 0, userActivityDetails?.long ?? 0]
            : [
                userOutOfAreaAlertDetails?.lat ?? 0,
                userOutOfAreaAlertDetails?.long ?? 0,
              ]
        }
        autoPan={true}
        icon={L.divIcon({
          className: "custom-icon",
          html: ReactDOMServer.renderToString(
            <svg
              className={`UserSvg ${
                !userActivityDetails?.in_zone ? "alert" : ""
              }`}
              xmlns="http://www.w3.org/2000/svg"
              width="23.494"
              height="48.376"
              viewBox="0 0 23.494 48.376"
              style={{ zIndex: 1001 }}
            >
              <g transform="translate(-9.484 -0.625)">
                <path
                  className="a"
                  d="M19.276,10.067h-.02A4.471,4.471,0,1,1,23.747,5.6a4.482,4.482,0,0,1-4.471,4.471Z"
                  transform="translate(1.93)"
                />
                <path
                  className="a"
                  d="M17.8,45.691a2.7,2.7,0,0,1-2.665-3l.1-27.4h-.987V25.655a2.394,2.394,0,0,1-.632,1.806,2.1,2.1,0,0,1-3,0,2.378,2.378,0,0,1-.632-1.806v-12a5.7,5.7,0,0,1,1.362-3.79A5.041,5.041,0,0,1,15.2,8.086H27.267a5.017,5.017,0,0,1,3.849,1.786,5.676,5.676,0,0,1,1.362,3.78v12a2.34,2.34,0,0,1-.651,1.806,2.218,2.218,0,0,1-3.06,0,2.34,2.34,0,0,1-.651-1.806V15.291h-.888v27.4a2.764,2.764,0,0,1-2.744,3c-1.323,0-2.6-.918-2.7-2.941V28.813H20.595V42.72A2.808,2.808,0,0,1,17.8,45.691Z"
                  transform="translate(0 2.81)"
                />
              </g>
            </svg>
          ),
        })}
        onClick={() => {
          setShowMsgPopup(!showMsgPopup);
        }}
      >
        <Popup onClose={() => setShowMsgPopup(false)}>
          <div className="activity-row-popup">
            <u>
              {" "}
              <strong>{userName}</strong>{" "}
            </u>
          </div>
          <TextField
            label={`Message`}
            multiline
            rows={3}
            value={message}
            onChange={(e) => {
              if (message.length < msg_character_length)
                setMessage(e.target.value);
            }}
            helperText={`Maximum ${
              msg_character_length - message.length ?? 0
            } characters`}
          />
          <div style={{ textAlign: "right" }}>
            <Button
              className="popup-button-dashboard"
              variant="contained"
              disabled={message.length === 0}
              onClick={sendMessage}
            >
              Send
            </Button>
          </div>
        </Popup>
        {!showMsgPopup && (
          <Tooltip permanent={false} offset={[-40, -70]}>
            <div className={`user-marker-tooltip ${isAlert ? "alert" : ""}`}>
              <div className="activity-row">
                <u>
                  {" "}
                  <strong>{userName}</strong>
                </u>
              </div>
              <div className="activity-row">
                Activity :{" "}
                {userActivityDetails &&
                userActivityTypesDict[userActivityDetails.activity_type_id]
                  ? userActivityTypesDict[userActivityDetails.activity_type_id]
                  : "..."}
              </div>
              <div className="activity-row">
                Phys. Exertion :{" "}
                {userActivityDetails &&
                userActivityDetails.intensity_type_id ? (
                  <span
                    style={{
                      width: "100%",
                      textAlign: "left",
                      marginLeft: "3px",
                      color: getIntensityDetailsById(
                        userActivityDetails.intensity_type_id
                      ).fill,
                    }}
                  >
                    {
                      getIntensityDetailsById(
                        userActivityDetails.intensity_type_id
                      ).name
                    }
                  </span>
                ) : (
                  "..."
                )}
              </div>
              <div className="activity-row">
                Last Seen :{" "}
                {userActivityDetails && userActivityDetails?.timestamp
                  ? new Date(
                      userActivityDetails?.timestamp!
                    ).toLocaleTimeString(undefined, {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true,
                    })
                  : new Date(
                      (userOutOfAreaAlertDetails?.timestamp ??
                        userOutOfAreaAlertDetails?.datetime)!
                    ).toLocaleTimeString(undefined, {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true,
                    })}
              </div>
              <div className="activity-row">
                Battery Level :{" "}
                {userActivityDetails
                  ? userActivityDetails.battery_level
                    ? userActivityDetails.battery_level + "%"
                    : ""
                  : "..."}
                {userActivityDetails && (
                  <BatteryIndicator
                    percentage={userActivityDetails.battery_level ?? 0}
                  ></BatteryIndicator>
                )}
              </div>
              {!userActivityDetails?.in_zone &&
                userOutOfAreaAlertDetails?.out_of_area_duration && (
                  <div className="activity-row">
                    Out Zone :{" "}
                    {(
                      userOutOfAreaAlertDetails?.out_of_area_duration! / 60
                    ).toFixed(0) ?? 0}{" "}
                    {" mins from "}
                    {new Date(
                      (userOutOfAreaAlertDetails?.timestamp ??
                        userOutOfAreaAlertDetails?.datetime)!
                    ).toLocaleTimeString(undefined, {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true,
                    })}
                  </div>
                )}
              {!userActivityDetails?.ble_zone && (
                <div className="activity-row">
                  Zone Name : {userActivityDetails?.ble_zone}
                </div>
              )}
            </div>
          </Tooltip>
        )}
      </Marker>
    </>
  ) : (
    <></>
  );
};

export default UserMarker;
